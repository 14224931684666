@media (max-width: 320px) {
    .profile-bio {
        width: 90%;
        height: 70%;
        font-size: 70%;
        line-height: 17px;
        background-position: left;
    }   
   
    .profile-bio .a {
        margin-top: 0;
    }
    .profile-bio .container-left {
        margin: 280px 10px 0 10px;
        display: grid;
        grid-template-columns:  1fr;
        grid-gap: 5px;
        grid-auto-rows: auto;
        grid-auto-flow: column;
        grid-template-areas: "h1"
     "h2"
     "a";
    }
    .profile-bio .container-right {
        margin: 280px 10px 0 10px;
        display: grid;
        grid-template-columns:  1fr;
        grid-gap: 5px;
        grid-auto-rows: auto;
        grid-auto-flow: column;
        grid-template-areas: "h1"
     "h2"
     "a";
    }
}
@media (max-width: 480px) and (min-width: 321px) {
    .profile-bio {
        width: 90%;
        height: 90%;
        font-size: 75%;
        line-height: 17px;
        background-position: 0 0 !important;
    }

    .profile-bio .a {
        margin-top: 0;
    }
    .profile-bio .container-left {
        margin: 280px 10px 0 10px;
        display: grid;
        grid-template-columns:  1fr;
        grid-gap: 5px;
        grid-auto-rows: auto;
        grid-auto-flow: column;
        grid-template-areas: "h1"
     "h2"
     "a";
    }
    .profile-bio .container-right {
        margin: 280px 10px 0 10px;
        display: grid;
        grid-template-columns:  1fr;
        grid-gap: 5px;
        grid-auto-rows: auto;
        grid-auto-flow: column;
        grid-template-areas: "h1"
     "h2"
     "a";
    }
}

@media (max-width: 960px) and (min-width: 721px) {
    .profile-bio {
        width: 90%;
        height: 55%;
        font-size: 62%;
        line-height: 17px;
        background-position: left;
    }

    .profile-bio .a {
        margin-top: 0;
    }
}
@media (max-width: 1130px) and (min-width: 961px) {
    .profile-bio {
        width: 90%;
        height: 77%;
        font-size: 83%;
        line-height: 25px;
        background-position: 0 0;
    }

}

@media only screen 
and (min-device-width : 961px) 
and (max-device-width : 1130px) 
and (orientation : landscape) { 
    #menu {
        overflow-y: auto;
    }
    .profile-bio {
        width: 90%;
        height: 90%;
        font-size: 80%;
        line-height: 25px;
        background-position: 0 0 !important;
    }

}

@media (max-width: 1280px) and (min-width: 1131px) {
    .profile-bio {
        width: 90%;
        height: 90%;
        font-size: 76%;
        line-height: 19px;
        background-position: 0 0 !important;
    } 

}

@media (max-width: 1440px) and (min-width: 1281px) {
    .profile-bio {
        width: 90%;
        height: 72%;
        max-width: 1090px;
        min-height: 720px;
        font-size: 87%;
        line-height: 21px;
        background-position: 0 0 !important;
    } 

}

@media (max-width: 1500px) and (min-width: 1441px) {
    .profile-bio {
        width: 90%;
        height: 90%;
        max-width: 1400px;
        font-size:90%;
        background-position: 0 0 !important;
        line-height: 24px;
    } 

}

@media (max-width: 1860px) and (min-width: 1501px) {
    .profile-bio {
        width: 90%;
        height: 90%;
        max-width: 1400px;
        font-size: 100%;
        /* background-position: 0 0 !important; */
        line-height: 25px;
    }     
}

@media (max-width: 1920px) and (min-width: 1861px) {
    .profile-bio {
        width: 90%;
        height: 90%;
        max-width: 1400px;
        background-position: 0 0 !important;
        line-height: 28px;
        font-size: 112%;
    }

}

@media (max-width: 2560px) and (min-width: 1921px) {
    .profile-bio {
        width: 90%;
        height: 90%;
        max-width: 1400px;
        background-position: 0 0 !important;
        line-height: 28px;
        font-size: 112%;
    }
}