.modal-drawer {
	outline: none;
	background: white;	
	width: 76rem;
	max-width: 40%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	z-index: 10000;
	/* min-height: 47rem; */
	margin-top: 56px;
  
	will-change: transform;
	transform: translate3d(0, 0, 0);
  }

  @media (max-height: 920px) {
	  .modal-drawer {
		  position: absolute;
		  top: 5px;
	  }
  }
  
  @media (max-width: 768px) {
	.modal-drawer {
		position: relative;
		top: 50px;
		margin-top: 280px;
		overflow: scroll;
		width: 100%;
		max-width: 100%;
		margin-bottom: 0;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
  }