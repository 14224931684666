@media (max-width: 320px) {
  html,
  body {
    font-size: 16px;

    overflow-x: hidden !important;

    width: fit-content !important;
  }
  footer {
    width: fit-content !important;
  }
  .section-green-energy-how-it-works .title-3 {
    display: none;
  }
  .bright-future-title {
    display: none;
  }
  #testimonial_animation {
    width: 100%;
    height: 320px;
    margin: 0 auto;
  }
  #contact-us-form {
    margin-top: 95px !important;
  }
  .section-green-energy-banner .super-girl {
    height: 133px;
  }
  .section-testimonials .view-customers-stories {
    margin-bottom: 0;
  }
  .logo {
    height: 50px;
  }
  #menu {
    overflow-y: auto;
  }
  .calculator {
    overflow-y: auto;

    margin-top: 65px;
  }
  .calculator .payment-title {
    font-size: 130%;

    margin-bottom: 10px;
  }
  .calculator h3 {
    line-height: 25px;

    width: 70%;
    margin: 0 auto;
    padding-top: 10px;
  }
  .social-links {
    width: 280px;
    margin: 0 auto;
  }
  .section-refinance-one li {
    font-size: 160%;
    line-height: 25px;

    margin-bottom: 20px;
  }
  .section-why-loanpal .pomp img {
    width: 290px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .section-mortgage-two img {
    width: 70%;
    margin: 0 auto;
  }
  .relative-top-home {
    height: 115px !important;
    min-height: 115px !important;
  }
  .relative-top {
    height: 65px;
    min-height: 65px;
  }
  .relative-top {
    position: fixed;
  }
  .contactus-header {
    margin-top: 65px;
  }
  .contactus .container {
    grid-template-areas: '. h1 h1 h1 h1'
    '. a a b b'
    '. h2 h2 h2 h2'
    '. a1 a1 b1 b1'
    '. c1 c1 d1 d1'
    '. a2 a2 b2 b2'
    '. c2 c2 d2 d2'
    '. a3 a3 . .';
    grid-template-columns: 0.1fr 1fr 1fr 1fr 1fr;
  }
  .home-section-two {
    max-height: 320px;
  }
  .home-section-two .title {
    line-height: 40px;

    max-width: 60%;
    margin: 0 auto;
  }
  .benefits-section-two .title {
    line-height: 40px;

    max-width: 80%;
    margin: 0 auto;
    margin-top: 0 !important;
  }
  .benefits-section-two .title-2 {
    padding: 25px 10px;
  }
  .section-why-loanpal .title {
    line-height: 1em;

    width: 70%;
    margin: 0 auto;
  }
  .section-why-loanpal .second-paragraph {
    max-width: 65%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .img-container-hero-green-energy {
    max-height: 240px;

    background-image: url(../images/supergreengirl_center.jpg);
  }
  .hero-company {
    margin-top: 65px;
  }
  .img-container-hero-company {
    min-height: 216px;
    margin-top: 65px;

    background-image: url(../images/06-ParamountTeamPhoto_treated_cropped-mobile-min.jpg);
  }
  .hero-company .video-thumbnail:before {
    font-size: 440%;

    top: 136px;
  }
  .logo-white-720 {
    display: block;
    float: left;

    width: auto;
    height: 14px;
    margin: 11px 15px;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
  }
  .main-nav {
    display: none;
    visibility: hidden;
  }
  .top-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }
  .articles {
    height: 480px !important;
  }
  .slideshow-movie {
    display: block;

    margin-top: 0;
  }
  .slideshow-movie video#home-video {
    max-width: 320px;
    margin-top: 00px !important;
  }
  .slideshow {
    display: none;
  }
  .slideshow-nav {
    top: 285px;

    display: none;
  }
  .mobile-nav,
  .mobile-nav-icon {
    display: block;
  }
  .phone-nav-mobile {
    display: block;
  }
  .mobile-nav-sticky-icon {
    display: block;
  }
  .relative-top-home {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .relative-top {
    padding-right: 3%;
    padding-left: 3%;
  }
  .sticky {
    display: none;
  }
  .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .border-right {
    border-right: 1px solid transparent !important;
  }
  /* #main-nav-div {
    display: none;
  } */

  section {
    font-size: 90%;
  }
  section .hide-mobile {
    display: none;
  }

  section .show-mobile {
    display: block;
  }

  .section-business-journal {
    padding: 0 !important;

    background-color: #ebebeb;
  }

  section .show-mobile-720 {
    display: none;
  }

  section .show-mobile-480 {
    display: block;
  }

  .main-nav {
    display: none;
    visibility: hidden;
  }
  .top-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }
  .mobile-nav,
  .mobile-nav-icon {
    display: block;
  }
  .phone-nav-mobile {
    font-size: 95%;

    left: -105px;

    display: none;
  }
  .mobile-nav-sticky-icon {
    display: block;
  }
  .slideshow-nav {
    display: none;
    visibility: hidden;
  }
  .relative-top-home {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .relative-top {
    padding-right: 3%;
    padding-left: 3%;
  }
  .slide-menu li {
    width: 100%;
    margin: 7px auto 7px auto;
  }
  section .title {
    font-size: 200%;
    line-height: 30px;

    letter-spacing: -1px;
    word-spacing: 0;
  }

  section .title-4 {
    font-size: 160%;
  }
  .top-orange-bar {
    width: 280px;
  }
  .see-rates {
    font-size: 79%;

    float: right;

    width: 65px;
    height: 50px;
    margin-top: -65%;
    padding-top: 2%;

    cursor: pointer;
    text-transform: uppercase;

    color: #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #ff7f00;
  }
  .hero-green-energy {
    top: 0;
    left: -48%;

    width: 100%;
    max-width: 53%;
    margin-left: auto;
  }
  .hero-green-energy h1 {
    font-size: 77%;
    line-height: 0;
  }
  .hero-green-energy h4 {
    font-size: 75%;
  }
  .hero-green-energy .in-parenthesis {
    font-size: 70%;
  }

  .leadership .container {
    grid-template-areas: 'a'
    'b'
    'c'
    'd';
    grid-template-columns: 1fr;
  }
  .leadership .grid-item-text {
    margin-bottom: 50px;
  }

  .section-why-loanpal .title-4 {
    margin-bottom: 25px;
  }

  .section-why-loanpal .smileys {
    width: 25%;
  }

  .section-why-loanpal p {
    width: 90%;
    margin: 0 auto;
  }

  .section-experience {
    display: none;
  }

  .section-experience p {
    font-size: 173%;
    line-height: 0;
  }

  .social-links li a:link,
  .social-links li a:visited {
    font-size: 330%;
  }

  .credits-images,
  .social-links {
    white-space: normal;
  }

  .credits-images li .aicpa-soc {
    float: none;
  }

  .credits-images .aicpa-soc {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;

    text-align: center;
  }

  .credits-images img {
    max-width: 100%;
  }

  .credits-images li .aicpa-soc {
    width: 110px;
    margin: 0 auto;
  }

  .section-no-secrets {
    display: none;
  }
  .section-why-loanpal .get-started {
    padding: 20px 0;
  }

  blockquote {
    font-size: 135%;

    width: 80%;
    margin: 0 auto;
  }

  .social-links li a:link,
  .social-links li a:visited {
    font-size: 390%;
  }
  .credits-images img {
    max-width: 110%;
  }
  footer {
    padding: 5px;
  }
  .footer-nav {
    font-size: 90%;
  }
  .footer-nav .btn-lg:link,
  .footer-nav .btn-lg:visited {
    font-size: 135%;

    margin-bottom: 5px;
  }
  #footer-nav-container {
    display: none;
    visibility: hidden;
  }

  #footer-nav-mobile {
    font-size: 125%;

    display: block;
    visibility: visible;
  }
  .footer-nav-mobile-col {
    width: 100%;
  }
  .footer-nav-mobile-col {
    width: 100%;
  }
  .footer-nav-mobile-table {
    width: 100%;

    text-align: center;
    text-align: left; /* text-transform: uppercase; */

    border: none;
  }

  .footer-nav-mobile-table td {
    width: 49%;
    padding: 5px;

    cursor: pointer;

    background-color: #333333;
  }
  .footer-nav-mobile-table td:hover {
    background-color: #333333;
  }
  .footer-nav-mobile-table a {
    text-decoration: none;

    color: #c3c3c3 !important;
  }
  .footer-nav-mobile-table a:hover {
    text-decoration: none;

    color: #dddddd !important;
  }
  footer p {
    font-size: 125%;
  }

  /* mortgage page */
  .img-container-hero-mortgage {
    max-height: 220px;
    margin-top: 41px;
  }
  .hero-mortgage h1 {
    font-size: 115%;
    line-height: 27px;

    margin-top: 0;
    margin-bottom: 0;

    letter-spacing: 0;
    word-spacing: 0;
  }
  .cs-phone {
    width: 111%;
  }
  .section-mortgage-one .ml-55 {
    margin-left: 45px;
  }

  .section-mortgage-three .term ul {
    top: 43px;
    left: -20px;
  }

  .section-paperwork h1 {
    font-size: 240%;
    line-height: 48px;

    position: absolute;
    left: 10%;

    margin-top: 79px;

    text-align: center;
    text-transform: none;
  }
  .hero-company {
    margin-top: 65px;
  }
  .img-container-hero-mortgage {
    margin-top: 65px;
  }
  .btn-full:link,
  .btn-full:visited {
    margin-right: 0;
  }
  .img-container-hero-footer-pages-2 {
    line-height: 25px;

    height: 125px;
    margin-top: 65px;
  }

  .img-container-hero-footer-pages {
    line-height: 25px;

    height: 125px;
    margin-top: 65px;
  }
  /* .img-container-hero-direct-pay {
    height: 125px;
    margin-top: 65px;
    line-height: 25px;
  } */
  .section-resources-header {
    margin-top: 65px;
  }
  /* refinance page */
  .img-container-hero-refinance {
    min-height: 232px;
    margin-top: 41px;
  }
  .hero-refinance {
    font-size: 48%;
  }
  .hero-refinance h2 {
    letter-spacing: -1px;
  }
  .hero-refinance h1 {
    letter-spacing: -1px;
  }
  .section-refinance-one .pl-45 {
    padding-left: 45px;
  }

  .section-refinance-one ul {
    margin-top: 0;

    vertical-align: middle;
  }

  .section-refinance-three .claims {
    width: 100%;
  }
  .section-refinance-four {
    min-height: 22vh;
  }
  .section-refinance-four .history {
    z-index: 60;

    height: 470px;
    margin-top: -12px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
  }
  .section-refinance-five img {
    width: 20%;
  }
  .section-refinance-one .title {
    font-size: 192%;
    line-height: 30px;

    width: 78%;
  }
  .section-refiance-two {
    font-size: 123%;
  }
  .section-refinance-two .img-container-hero-mortgage {
    max-height: 219px;
    margin-top: 0;
  }
  .section-refinance-two .mb-40 {
    margin-bottom: 15px;
  }
  .section-refinance-four {
    display: none;
  }

  /* home-improvement page */
  .img-container-hero-home-improvement {
    min-height: 367px;
    margin-top: 0;
  }
  .section-home-improvement-form .paragraph {
    line-height: 30px;

    text-align: justify;
  }
  .hero-home-improvement h1 {
    font-size: 220%;

    display: block;

    margin-bottom: 5px;
  }
  .hero-home-improvement .mobile-view {
    display: block;
  }
  .hero-home-improvement h3 {
    display: none;
  }

  .section-home-improvement-form .title-2 {
    width: 85%;
  }
  .section-home-improvement-form .title-3 {
    width: 75%;
  }
  .section-home-improvement-form form {
    max-width: 100%;
  }

  .testimonials .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }

  .section-resources-header {
    min-height: 255px;

    background-image: url(../images/piggyresource-no-text.jpg);
  }

  .financier-paragraph {
    width: 95%;
  }

  .section-boxes img {
    float: none;

    width: 100%;
  }

  /* footer pages */
  .go-home-button {
    height: 80px;
    margin: 0 auto;
    padding-bottom: 10px;
  }
  .go-home-button .logo {
    height: 30px !important;
  }
  .img-container-hero-footer-pages-2 {
    max-height: 110px;
    margin-top: 65px;
  }
  .img-container-hero-footer-pages {
    height: 125px;
    margin-top: 65px;
  }
  /* .img-container-hero-direct-pay {
    height: 125px;
    margin-top: 65px;
    line-height: 25px;
  } */
  .hero-footer {
    left: -15%;

    width: 100%;
    max-width: 100% !important;
  }
  .spacer-160 {
    padding: 40px 0 !important;
  }
  .hero-footer h1 {
    font-size: 188%;
    line-height: 30px;

    margin-left: 56px;

    letter-spacing: 0;
    word-spacing: 0;
  }
  .testimonials h1 {
    font-size: 200%;
  }
  .testimonials h2 {
    font-size: 190%;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: 'a'
    'b';
    grid-template-columns: 1fr;
  }

  .testimonials .friend-text-bottom {
    display: block;
  }
}
@media (max-width: 480px) and (min-width: 321px) {
  html,
  body {
    font-size: 16px;

    overflow-x: hidden !important;

    width: fit-content !important;
  }
  footer {
    width: fit-content !important;
  }
  .section-green-energy-how-it-works .title-3 {
    display: none;
  }
  #contact-us-form {
    margin-top: 95px !important;
  }
  .bright-future-title {
    display: none;
  }
  #testimonial_animation {
    width: 95%;
    height: 290px;
    margin: 0 auto;
  }
  .section-green-energy-banner .super-girl {
    height: 115px;
  }
  .section-testimonials .view-customers-stories {
    margin-bottom: 0;
  }
  #menu {
    overflow-y: auto;
  }
  .calculator {
    overflow-y: auto;

    margin-top: 65px;
  }
  .calculator .payment-title {
    font-size: 130%;

    margin-bottom: 10px;
  }
  .calculator h3 {
    line-height: 25px;

    width: 70%;
    margin: 0 auto;
    padding-top: 10px;
  }
  .social-links {
    width: 300px;
    margin: 0 auto;
  }
  .calculator .payment-title {
    font-size: 130%;

    margin-bottom: 10px;
  }
  .calculator .payment {
    font-size: 290%;
  }
  .calculator .input-box {
    margin-top: 0;
  }
  .financier-paragraph {
    width: 95%;
  }
  .rates-modal {
    overflow: hidden !important;

    width: 300px;
    max-width: 300px;

    transition: all 0.7s;
    transform: translateY(-50px);

    border-radius: 4px;
  }
  .rates-modal .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .section-mortgage-three .border-right {
    min-height: 110px;

    border-right: none;
  }
  .section-mortgage-three .long-width {
    max-width: 150px;
    height: 45px;
  }
  .section-mortgage-three .short-width {
    height: 45px;
  }
  .section-mortgage-two img {
    width: 70%;
    margin: 0 auto;
  }
  .section-refinance-one li {
    font-size: 160%;
    line-height: 25px;

    margin-bottom: 20px;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
  }
  .relative-top-home {
    height: 115px !important;
    min-height: 115px !important;
  }
  .relative-top {
    height: 65px;
    min-height: 65px;
  }
  .logo-white-720 {
    display: block;
    float: left;

    width: auto;
    height: 20px;
    margin: 10px 41px;
  }
  .main-nav {
    display: none;
    visibility: hidden;
  }
  .top-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }
  .contactus-header {
    margin-top: 65px;
  }
  .contactus .container {
    grid-template-areas: '. h1 h1 h1 h1'
    '. a a b b'
    '. h2 h2 h2 h2'
    '. a1 a1 b1 b1'
    '. c1 c1 d1 d1'
    '. a2 a2 b2 b2'
    '. c2 c2 d2 d2'
    '. a3 a3 . .';
    grid-template-columns: 0.1fr 1fr 1fr 1fr 1fr;
  }
  .articles {
    height: 480px !important;
  }
  .section-why-loanpal .pomp img {
    width: 290px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .slideshow-movie {
    display: block;

    margin-top: 0;
    margin-bottom: -11px;
  }
  .slideshow-movie video#home-video {
    max-width: 480px;
    margin-top: 0 !important;
  }
  .slideshow {
    display: none;
  }
  .slideshow-nav {
    top: 285px;

    display: none;
  }
  .mobile-nav,
  .mobile-nav-icon {
    display: block;
  }
  .phone-nav-mobile {
    font-size: 95%;

    left: -105px;

    display: block;
  }
  .mobile-nav-sticky-icon {
    display: block;
  }
  .relative-top-home {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .relative-top {
    padding-right: 3%;
    padding-left: 3%;
  }
  .sticky {
    display: none;
  }
  .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .border-right {
    border-right: 1px solid transparent !important;
  }
  /* .section-home-improvement-form {
    margin-top: -90px;
  } */
  section {
    font-size: 95%;
  }
  section .hide-mobile {
    display: none;
  }

  section .show-mobile {
    display: block;
  }

  .section-business-journal {
    padding: 0 !important;

    background-color: #ebebeb;
  }

  section .show-mobile-720 {
    display: none;
  }

  section .show-mobile-480 {
    display: block;
  }
  .main-nav {
    display: none;
    visibility: hidden;
  }
  .top-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }
  .mobile-nav {
    display: block;
  }
  .mobile-nav-icon {
    display: block;

    margin-top: 20px;
  }
  .phone-nav-mobile {
    display: block;
  }
  .mobile-nav-sticky-icon {
    display: block;
  }
  .slideshow-nav {
    display: none;
    visibility: hidden;
  }
  .relative-top-home {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .relative-top {
    padding-right: 3%;
    padding-left: 3%;
  }
  .hero-company {
    margin-top: 65px;
  }
  .img-container-hero-company {
    min-height: 216px;
    margin-top: 65px;

    background-image: url(../images/06-ParamountTeamPhoto_treated_cropped-mobile-min.jpg);
  }
  .hero-company .video-thumbnail:before {
    font-size: 440%;

    top: 136px;
  }
  .slide-menu li {
    /* margin: 7px auto 7px auto; */
    width: 100%;
  }
  section .title {
    font-size: 220%;
    line-height: 35px;

    letter-spacing: -1px;
    word-spacing: 0;
  }
  .section-why-loanpal .title {
    line-height: 1em;

    width: 70%;
    margin: 0 auto;
  }
  .benefits-section-two .title {
    line-height: 40px;

    max-width: 80%;
    margin: 0 auto;
    margin-top: 0 !important;
  }
  .home-section-two {
    max-height: 320px;
  }
  .home-section-two .title {
    line-height: 40px;

    max-width: 60%;
    margin: 0 auto;
  }
  .section-why-loanpal .second-paragraph {
    max-width: 65%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .section-why-loanpal .title-4 {
    line-height: 15px;

    width: 90%;
    margin: 0 auto;

    white-space: nowrap;
  }

  .benefits-section-two .title-2 {
    padding: 25px 10px;
  }

  section .title-4 {
    font-size: 160%;
    line-height: 15px;
  }

  section .title-1 {
    width: 90%;
  }

  .top-orange-bar {
    width: 280px;
  }
  .top-orange-bar-company {
    font-size: 104%;
  }
  .see-rates {
    font-size: 100%;

    float: right;

    width: 85px;
    height: 60px;
    margin-top: -47%;
    padding-top: 2%;

    cursor: pointer;
    text-transform: uppercase;

    color: #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #ff7f00;
  }

  .leadership .container {
    grid-template-areas: 'a'
    'b'
    'c'
    'd';
    grid-template-columns: 1fr;
  }
  .leadership .grid-item-text {
    margin-bottom: 50px;
  }

  .section-why-loanpal .title-4 {
    /* white-space: nowrap; */
    font-size: 150%;

    margin-bottom: 25px;
  }

  .section-why-loanpal .mt-40n {
    margin-top: -65px;
  }

  .section-why-loanpal .smileys {
    position: relative;
    top: -10;

    width: 60%;
  }

  .section-why-loanpal .box {
    position: relative;
  }

  .section-why-loanpal p {
    width: 90%;
    margin: 0 auto;
  }

  .section-experience {
    min-height: 225px;
  }

  .section-experience p {
    font-size: 173%;
    line-height: 0;
  }

  .section-paperwork h1 {
    font-size: 247%;
    line-height: 47px;

    position: absolute;
    left: 9%;

    margin-top: 80px;

    text-align: center;
    text-transform: none;
  }

  .section-resources-header {
    min-height: 255px;

    background-image: url(../images/piggyresource-no-text.jpg);
  }
  .section-boxes img {
    float: none;

    width: 100%;
  }

  blockquote {
    font-size: 150%;

    width: 60%;
    margin: 0 auto;
  }

  .social-links li a:link,
  .social-links li a:visited {
    font-size: 330%;
  }
  .credits-images,
  .social-links {
    white-space: normal;
  }

  .credits-images img {
    max-width: 110%;
  }

  .credits-images li .aicpa-soc {
    width: 110px;
    margin: 0 auto;
  }

  .credits-images li .aicpa-soc {
    float: none;
  }

  .credits-images .aicpa-soc {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;

    text-align: center;
  }

  .section-no-secrets {
    display: none;
  }

  .section-why-loanpal .get-started {
    padding: 20px 0;
  }

  blockquote {
    width: 90%;
    margin: 0 auto;
  }

  .social-links li a:link,
  .social-links li a:visited {
    font-size: 390%;
  }
  .credits-images img {
    max-width: 110%;
  }
  .footer-nav {
    font-size: 100%;
  }
  #footer-nav-container {
    display: none;
    visibility: hidden;
  }
  #footer-nav-mobile {
    font-size: 125%;

    display: block;
    visibility: visible;
  }
  .footer-nav-mobile-col {
    width: 100%;
  }
  .footer-nav-mobile-col {
    width: 100%;
  }
  .footer-nav-mobile-table {
    width: 100%;

    text-align: center;
    text-align: left; /* text-transform: uppercase; */

    border: none;
  }

  .footer-nav-mobile-table td {
    width: 49%;
    padding: 5px;

    cursor: pointer;

    background-color: #333333;
  }
  .footer-nav-mobile-table td:hover {
    background-color: #333333;
  }
  .footer-nav-mobile-table a {
    text-decoration: none;

    color: #c3c3c3 !important;
  }
  .footer-nav-mobile-table a:hover {
    text-decoration: none;

    color: #dddddd !important;
  }
  footer p {
    font-size: 125%;
  }

  .img-container-hero-green-energy {
    max-height: 240px;

    background-image: url(../images/supergreengirl_center.jpg);
  }
  .hero-green-energy {
    top: 0;
    left: -48%;

    display: none;

    width: 100%;
    max-width: 53%;
    margin-left: auto;
  }
  .hero-green-energy h1 {
    font-size: 77%;
    line-height: 0;
  }
  .hero-green-energy h4 {
    font-size: 75%;
  }
  .hero-green-energy .in-parenthesis {
    font-size: 70%;
  }
  .zero-down {
    width: 80%;
  }
  .section-process .title-approval {
    font-size: 150%;
  }
  .section-resources-header {
    margin-top: 65px;
  }
  .img-container-hero-green-energy {
    margin-top: 65px;
  }
  .img-container-hero-refinance {
    margin-top: 65px;
  }
  .img-container-hero-footer-pages-2 {
    max-height: 110px;
    margin-top: 65px;
  }
  .img-container-hero-footer-pages {
    height: 125px;
    margin-top: 65px;
  }
  /* .img-container-hero-direct-pay {
    height: 125px;
    margin-top: 65px;
    line-height: 25px;
    font-size: 80%;
    text-align: center;
  } */
  .go-home-button {
    height: 90px;
    margin: 0 auto;
    padding-bottom: 10px;
  }
  .go-home-button .logo {
    height: 40px !important;
  }
  /* mortgage page */
  .img-container-hero-mortgage {
    max-height: 310px;
    margin-top: 65px;
  }
  .btn-full:link,
  .btn-full:visited {
    margin-right: 0;
  }
  .hero-mortgage h1 {
    font-size: 125%;
    line-height: 0;
  }

  .cs-phone {
    width: 111%;
  }
  .section-mortgage-one .ml-55 {
    margin-left: 45px;
  }

  .rates-modal .term ul {
    top: 0;
    left: 10px !important;
  }

  .section-mortgage-three .term ul {
    top: 0;
    left: -10px;
  }

  .section-resources-header {
    margin-top: 65px;
  }
  /* refinance page */
  .img-container-hero-refinance {
    min-height: 319px;
  }
  .hero-refinance {
    font-size: 50%;
  }
  .hero-refinance h2 {
    letter-spacing: -1px;
  }
  .hero-refinance h1 {
    letter-spacing: -1px;
  }
  .section-refinance-one .pl-45 {
    padding-left: 45px;
  }

  .section-refinance-one ul {
    margin-top: 0;

    vertical-align: middle;
  }

  .section-refinance-three .claims {
    width: 85%;
    margin: 0 auto;
  }
  .section-refinance-four {
    min-height: 38vh;

    border-bottom: 2px solid #ff7f00;
    background-image: none;
  }
  .section-refinance-four .history {
    z-index: 60;

    height: 470px;
    margin-top: -12px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
  }
  .section-refinance-five img {
    width: 20%;
  }
  .section-refinance-one .title {
    font-size: 200%;
    line-height: 36px;

    width: 90%;
  }

  /* home-improvement page */
  .img-container-hero-home-improvement {
    min-height: 188px;
    margin-top: 65px;

    background-size: 695px;
  }
  .hero-home-improvement .mt-100 {
    margin-top: 10px;
  }
  .section-home-improvement-form .paragraph {
    line-height: 30px;

    text-align: justify;
  }
  .hero-home-improvement h1 {
    font-size: 220%;

    display: block;

    margin-bottom: 5px;
  }
  .hero-home-improvement .mobile-view {
    display: block;
  }
  .hero-home-improvement h3 {
    display: none;
  }

  .section-home-improvement-form .title-2 {
    width: 85%;
  }
  .section-home-improvement-form .title-3 {
    width: 75%;
  }
  .section-home-improvement-form form {
    max-width: 100%;
  }

  .testimonials .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }

  .section-experience {
    background-image: none;
  }
  .section-experience .history {
    z-index: 60;

    height: 470px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
    /* background-size: 1440px 314px; */
  }

  /* footer pages */
  .img-container-hero-footer-pages-2,
  .img-container-hero-footer-pages {
    height: 125px;
  }
  .hero-footer {
    left: 10%;

    width: 100%;
    max-width: 100% !important;
  }
  .spacer-160 {
    padding: 40px 0 !important;
  }
  .hero-footer h1 {
    font-size: 188%;
    line-height: 30px;

    max-width: 216px;
    margin-top: 40px;
    margin-left: 20px;
  }
  .testimonials h1 {
    font-size: 200%;
  }
  .testimonials h2 {
    font-size: 190%;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: 'a'
    'b';
    grid-template-columns: 1fr;
  }

  .testimonials .friend-text-bottom {
    display: block;
  }
}
@media (max-width: 528px) {
  html,
  body {
    font-size: 16px;

    overflow-x: hidden !important;

    width: fit-content !important;
  }
  footer {
    width: fit-content !important;
  }
  #contact-us-form {
    margin-top: 95px !important;
  }
  footer {
    width: fit-content !important;
  }
  .noscroll {
    overflow-y: hidden !important;
  }
  .slider-row {
    width: 95%;
  }
  .assumptions {
    width: 95% !important;
  }
  .financier-paragraph {
    width: 100%;
  }
}
@media (max-width: 720px) and (min-width: 481px) {
  html,
  body {
    font-size: 16px;

    overflow-x: hidden !important;

    width: fit-content !important;
  }
  #viewsRoot {
    width: 100% !important;
  }
  .section-green-energy-how-it-works .title-3 {
    display: none;
  }
  #U356592_animation {
    width: 720px;
    height: 315px;
  }
  #contact-us-form {
    margin-top: 95px !important;
  }
  #menu {
    overflow-y: auto;
  }
  .bright-future-title {
    display: none;
  }
  #testimonial_animation {
    width: 471px;
    height: 361px;
    margin: 0 auto;
  }
  .social-links {
    width: 315px;
    margin: 0 auto;
  }
  .financier-paragraph {
    width: 87%;
  }
  .calculator {
    overflow-y: auto;

    margin-top: 65px;
  }
  .calculator .payment-title {
    font-size: 130%;

    margin-bottom: 10px;
  }
  .calculator h3 {
    line-height: 25px;

    width: 70%;
    margin: 0 auto;
    padding-top: 10px;
  }
  .calculator .payment {
    font-size: 290%;
  }
  .financier-paragraph {
    width: 87%;
  }
  .rates-modal {
    overflow: hidden !important;

    width: 320px;
    max-width: 320px;

    transition: all 0.7s;
    transform: translateY(-50px);

    border-radius: 4px;
  }
  .rates-modal .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .section-mortgage-three .border-right {
    height: 130px;

    border-right: none;
  }
  .section-mortgage-three .long-width {
    max-width: 200px;
    height: 58px;
  }
  .section-mortgage-three .short-width {
    max-width: 190px;
  }
  .section-mortgage-three .term {
    font-size: 375%;
  }
  .section-mortgage-two img {
    width: 35%;
    margin: 0 auto;
  }
  .slider-row {
    width: 75%;
  }
  .assumptions {
    width: 95%;
  }
  .calculator .payment-title {
    font-size: 130%;

    margin-bottom: 10px;
  }
  .calculator .payment {
    font-size: 290%;
  }
  .contactus-header {
    margin-top: 65px;
  }
  .contactus .container {
    grid-template-areas: '. h1 h1 h1 h1'
    '. a a b b'
    '. h2 h2 h2 h2'
    '. a1 a1 b1 b1'
    '. c1 c1 d1 d1'
    '. a2 a2 b2 b2'
    '. c2 c2 d2 d2'
    '. a3 a3 . .';
    grid-template-columns: 0.1fr 1fr 1fr 1fr 1fr;
  }
  .home-section-two {
    max-height: 320px;
  }
  .home-section-two {
    max-height: 320px;
  }
  .benefits-section-two {
    margin-top: 15px;
  }
  .benefits-section-two .title-2 {
    padding: 25px 10px;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
  }
  .relative-top-home {
    height: 115px !important;
    min-height: 115px !important;
  }
  .relative-top {
    height: 65px;
    min-height: 65px;
  }
  .hero-company {
    margin-top: 65px;
  }
  .img-container-hero-company {
    margin-top: 65px;
  }
  .slideshow {
    margin-top: 65px;
  }
  .main-nav {
    display: none;
    visibility: hidden;
  }
  .top-orange-bar-company {
    font-size: 100%;
  }
  .top-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }
  .articles {
    height: 480px !important;
  }
  .slideshow-movie {
    display: block;

    margin-top: 0;
  }
  .slideshow-movie video#home-video {
    max-width: 720px;
    margin-top: 0 !important;
  }
  .slideshow {
    display: none;
  }
  .slideshow-nav {
    top: 285px;

    display: none;
  }
  .mobile-nav {
    display: block;
  }
  .mobile-nav-icon {
    display: block;

    margin-top: 20px;
  }
  .phone-nav-mobile {
    display: block;
  }
  .mobile-nav-sticky-icon {
    display: block;
  }
  .relative-top-home {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .relative-top {
    padding-right: 3%;
    padding-left: 3%;
  }
  .sticky {
    display: none;
  }

  .logo-white-720 {
    display: block;
    float: left;

    width: auto;
    height: 25px;
    margin: 8px 41px;
  }
  .section-home-improvement-form {
    margin-top: 70px;
  }
  section {
    font-size: 90%;
  }

  section .hide-mobile {
    display: none;
  }

  section .show-mobile {
    display: block;
  }

  section .show-mobile-720 {
    display: block;
  }

  section .show-mobile-480 {
    display: none;
  }

  .benefits-section-two .title {
    width: auto !important;
  }

  .section-two .title {
    line-height: 1em;

    width: 285px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  section .title {
    font-size: 220%;
    line-height: 50px;

    letter-spacing: -1px;
    word-spacing: 0;
  }

  section .title-4 {
    font-size: 160%;
  }

  .section-refinance-one .title {
    line-height: 45px;

    width: 95%;

    letter-spacing: -1;
  }

  .see-rates {
    font-size: 100%;

    float: right;

    width: 105px;
    height: 76px;
    margin-top: -28%;
    padding-top: 2%;

    cursor: pointer;
    text-transform: uppercase;

    color: #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #ff7f00;
  }

  .img-container-hero-company {
    min-height: 375px;

    background-image: url(../images/06-ParamountTeamPhoto_treated_cropped_960-v3-min.jpg);
  }
  .hero-company .video-thumbnail:before {
    font-size: 480%;

    top: 220px;
  }
  .leadership .container {
    grid-template-areas: '. a .'
    '. b .'
    '. c .'
    '. d .';
    grid-template-columns: 0.2fr 1fr 0.2fr;
  }
  .leadership .grid-item-text {
    margin-bottom: 50px;
  }

  .section-why-loanpal .title {
    line-height: 1em;

    width: 300px;
    margin: 0 auto;
  }
  .section-why-loanpal .title-4 {
    width: 90%;
    margin-bottom: 25px;
  }

  .section-why-loanpal .box {
    position: relative;
  }

  .section-why-loanpal .smileys {
    width: 52%;
  }

  .section-why-loanpal p {
    font-size: 125%;

    width: 90%;
    margin: 0 auto;
  }

  .section-experience {
    min-height: 300px;
  }

  .section-experience p {
    font-size: 185%;
    line-height: 20px;
  }

  .section-paperwork h1 {
    font-size: 244%;
    line-height: 53px;

    position: absolute;
    left: 10%;

    margin-top: 75px;

    text-align: center;
    text-transform: none;
  }

  .section-resources-header {
    min-height: 255px;

    background-image: url(../images/piggyresource-no-text.jpg);
  }

  blockquote {
    width: 60%;
    margin: 0 auto;
  }

  .header-banner-mobile {
    left: -150px;
  }

  .social-links li a:link,
  .social-links li a:visited {
    font-size: 330%;
  }

  .credits-images,
  .social-links {
    white-space: normal;
  }

  .credits-images img {
    max-width: 100%;
  }

  .credits-images li .aicpa-soc {
    width: 110px;
    margin: 0 auto;
  }

  .credits-images li .aicpa-soc {
    float: none;
  }

  .credits-images .aicpa-soc {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;

    text-align: center;
  }

  .footer-nav {
    font-size: 90%;
  }
  #footer-nav-container {
    display: none;
    visibility: hidden;
  }

  #footer-nav-mobile {
    font-size: 125%;

    display: block;
    visibility: visible;
  }
  .footer-nav-mobile-col {
    width: 100%;
  }
  .footer-nav-mobile-col {
    width: 100%;
  }
  .footer-nav-mobile-table {
    width: 100%;

    text-align: center;
    text-align: left; /* text-transform: uppercase; */

    border: none;
  }

  .footer-nav-mobile-table td {
    width: 49%;
    padding: 5px;

    cursor: pointer;

    background-color: #333333;
  }
  .footer-nav-mobile-table td:hover {
    background-color: #333333;
  }
  .footer-nav-mobile-table a {
    text-decoration: none;

    color: #c3c3c3 !important;
  }
  .footer-nav-mobile-table a:hover {
    text-decoration: none;

    color: #dddddd !important;
  }
  .footer-nav .btn-lg:link,
  .footer-nav .btn-lg:visited {
    font-size: 110%;
  }
  .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .border-right {
    border-right: 1px solid transparent !important;
  }
  .section-resources-header {
    margin-top: 65px;
  }
  .img-container-hero-refinance {
    margin-top: 65px;
  }
  .img-container-hero-green-energy {
    margin-top: 65px;
  }
  .img-container-hero-footer-pages-2,
  .img-container-hero-footer-pages {
    margin-top: 65px;
  }
  /* mortgage page */
  .img-container-hero-mortgage {
    max-height: 314px;
    margin-top: 0;

    background-position: 50% 62px;
  }
  .hero-mortgage h1 {
    font-size: 112%;
    line-height: 3px;
  }

  .section-mortgage-two img {
    width: 56%;
    margin: 0 auto;
  }

  .section-mortgage-three .term ul {
    top: 0;
    left: -20px;
  }

  .rates-modal .term ul {
    top: 0;
    left: 10px !important;
  }

  /* refinance page */
  /* .img-container-hero-refinance {
        margin-top: 0;
    }  */
  .hero-refinance {
    font-size: 60%;
  }
  .hero-refinance h1 {
    font-size: 357%;
  }
  .hero-refinance h2 {
    font-size: 232%;
  }
  .section-refinance-one .pl-45 {
    padding-left: 45px;
  }

  .section-refinance-one ul {
    margin-top: 0;

    vertical-align: middle;
  }

  .section-refinance-three .claims {
    width: 100%;
  }
  .section-refinance-four {
    min-height: 38vh;

    border-bottom: 2px solid #ff7f00;
    background-image: none;
  }
  .section-refinance-four .history {
    z-index: 60;

    height: 560px;
    margin-top: -12px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
  }
  .section-refinance-five img {
    width: 20%;
  }

  /* green-energy page */
  .img-container-hero-green-energy {
    max-height: 247px;

    background-image: url(../images/supergreengirl_center.jpg);
  }
  .hero-green-energy {
    top: 0;
    left: -48%;

    display: none;

    width: 100%;
    max-width: 53%;
    margin-left: auto;
  }
  .hero-green-energy h1 {
    font-size: 77%;
    line-height: 0;
  }
  .hero-green-energy h4 {
    font-size: 75%;
  }
  .hero-green-energy .in-parenthesis {
    font-size: 70%;
  }
  .zero-down {
    width: 80%;
  }
  .section-process .phone-outlines-img,
  .section-process .phone-outlines-text {
    text-align: center;
  }
  .section-process .title-2 {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
  .section-process .title-approval {
    font-size: 146%;

    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
  }
  .section-customers .row {
    width: 90%;
    margin: 0 auto;
  }
  .section-customers .title-customers {
    font-size: 140%;

    padding: 0;
  }

  /* home-improvement page */
  .img-container-hero-home-improvement {
    min-height: 367px;
    margin-top: -5px;

    background-size: 845px;
  }
  .hero-home-improvement .mt-100 {
    margin-top: 10px;
  }
  .hero-home-improvement h1 {
    font-size: 220%;

    display: block;

    margin-bottom: 5px;
  }
  .hero-home-improvement .mobile-view {
    display: block;
  }
  .hero-home-improvement h3 {
    display: none;
  }

  .section-home-improvement-form .title-2 {
    width: 85%;
  }
  .section-home-improvement-form .title-3 {
    width: 75%;
  }
  .section-home-improvement-form form {
    max-width: 100%;
  }

  .section-experience {
    background-image: none;
  }
  .section-experience .history {
    z-index: 60;

    height: 470px;

/* background-size: 1440px 314px; */
    margin: 60px 0;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
  }

  /* footer pages */
  .img-container-hero-footer-pages-2 {
    height: 95px;
  }

  .img-container-hero-footer-pages {
    height: 65px;
  }
  .hero-footer h1 {
    font-size: 185%;
    line-height: 40px;

    max-width: 216px;
    margin-top: 40px;
    margin-left: 20px;
  }
  .spacer-160 {
    padding: 40px 0 !important;
  }
  .testimonials .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }

  .testimonials h1 {
    font-size: 260%;
  }
  .testimonials h2 {
    font-size: 220%;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: 'a'
    'b';
    grid-template-columns: 1fr;
  }

  .testimonials .friend-text-bottom {
    display: block;
  }
}

@media (max-width: 812px) {
  #viewsRoot {
    width: 100% !important;
  }
  .section-testimonials .profile-pic {
    display: none;
  }
}

@media (max-width: 790px) {
  #viewsRoot {
    width: 100% !important;
  }
  .noscroll {
    overflow-y: visible;
  }
  .assumptions {
    width: 95%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #menu {
    overflow-y: auto;
  }
  .calculator {
    overflow-y: auto;

    margin-top: 65px;
  }
}

@media (max-width: 960px) and (min-width: 721px) {
  #viewsRoot {
    width: 100% !important;
  }
  html,
  body {
    font-size: 16px;

    overflow-x: hidden !important;

    width: fit-content !important;
  }
  footer {
    width: fit-content !important;
  }
  #contact-us-form {
    margin-top: 95px !important;
  }
  #U356592_animation {
    width: 960px;
    height: 312px;
  }
  #u474839_animation {
    width: 960px;
    height: 280px;
  }
  #testimonial_animation {
    width: 716px;
    height: 529px;
    margin: 0 auto;
  }
  .calculator .payment-title {
    font-size: 130%;

    margin-bottom: 10px;
  }
  .calculator .payment {
    font-size: 290%;
  }
  .section-testimonials .quotes-row .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .section-testimonials .quotes-row {
    width: 560px;
    margin-top: 20px;
  }
  .financier-paragraph {
    width: 100%;
  }
  .rates-modal {
    overflow: hidden !important;

    width: 450px;
    max-width: 450px;

    transition: all 0.7s;
    transform: translateY(-50px);

    border-radius: 4px;
  }
  .rates-modal .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .rates-modal .border-right {
    height: 145px;

    border-right: none;
  }
  .rates-modal .long-width {
    max-width: 165px;
    height: 55px;
  }
  .rates-modal .short-width {
    max-width: 120px;
    height: 55px;
  }
  .section-mortgage-three .mortgage-rates {
    max-width: 100%;
  }
  .section-mortgage-two img {
    width: 35%;
    margin: 0 auto;
  }
  .slider-row {
    width: 100%;
  }
  .calculator .row {
    padding: 0 2%;
  }
  .calculator {
    overflow-y: auto;

    margin-top: 65px;
  }
  .calculator .slider-container {
    float: left;

    min-height: 133px;

    vertical-align: top;
  }
  .calculator .input-box {
    margin-top: 0;
  }
  .calculator .payment {
    font-size: 210%;
  }
  .contactus-header {
    margin-top: 65px;
  }
  .img-container-hero-home-improvement {
    min-height: 382px;
  }
  .hero-home-improvement h1 {
    margin-top: 3%;
  }
  .contactus .container {
    grid-template-areas: '. h1 h1 h1 h1 .'
    '. a a b b .'
    '. h2 h2 h2 h2 .'
    '. a1 a1 b1 b1 .'
    '. c1 c1 d1 d1 .'
    '. a2 a2 b2 b2 .'
    '. c2 c2 d2 d2 .'
    '. a3 a3 . . .';
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
  }
  .logo-white-720 {
    display: block;
    float: left;

    width: auto;
    height: 25px;
    margin: 8px 41px;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
  }
  .relative-top-home {
    height: 115px !important;
    min-height: 115px !important;
  }
  .relative-top {
    height: 65px;
    min-height: 65px;
  }
  section .hide-mobile {
    display: none;
  }

  section .show-mobile {
    display: block;
  }

  section .show-mobile-720 {
    display: block;
  }

  section .show-mobile-480 {
    display: none;
  }
  .hero-company {
    margin-top: 65px;
  }
  .hero-mortgage h1 {
    font-size: 171%;
    line-height: 19px;
  }
  .section-resources-header {
    margin-top: 65px;
  }
  .img-container-hero-green-energy {
    margin-top: 65px;
  }
  .img-container-hero-footer-pages {
    margin-top: 65px;
  }
  .img-container-hero-mortgage {
    margin-top: 65px;
  }
  .img-container-hero-refinance {
    margin-top: 65px;
  }
  .slideshow {
    margin-top: 65px;
  }
  .hero-refinance h1 {
    font-size: 330%;
  }
  .hero-refinance h2 {
    font-size: 183%;
  }
  .top-nav,
  .main-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }
  .mobile-nav,
  .mobile-nav-icon {
    display: block;
  }
  .phone-nav-mobile {
    display: block;
  }
  .mobile-nav-sticky-icon {
    display: block;
  }

  .header-banner-mobile {
    left: -145px;
  }

  .social-links li a:link,
  .social-links li a:visited {
    font-size: 330%;
  }

  .credits-images,
  .social-links {
    white-space: normal;
  }

  .credits-images img {
    max-width: 100%;
  }

  .credits-images li .aicpa-soc {
    width: 80px;
    margin: 0 auto;
  }
  .relative-top-home {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .relative-top {
    padding-right: 3%;
    padding-left: 3%;
  }
  .img-container-hero-company {
    min-height: 430px;
    margin-top: 65px;

    background-image: url(../images/06-ParamountTeamPhoto_treated_cropped_960-v3-min.jpg);
  }
  .hero-company .video-thumbnail:before {
    font-size: 490%;

    top: 255px;
  }
  .section-business-journal .hide-mobile {
    display: none;
  }

  .section-business-journal .show-mobile {
    display: block;
  }

  .section-business-journal {
    padding: 0 !important;

    background-color: #ffffff !important;
  }

  .section-business-journal img {
    width: 80%;
  }

  .section-paperwork h1 {
    font-size: 196%;
    line-height: 45px;

    position: absolute;
    left: 5%;

    margin-top: 90px;

    text-align: center;
    text-transform: none;
  }

  .section-why-loanpal .smileys {
    width: 100%;
  }
  .section-why-loanpal .benefits-smileys .box {
    position: relative;
    top: -45px;
  }
  .see-rates {
    margin-top: 90%;
  }
  .footer-nav .btn-lg:link,
  .footer-nav .btn-lg:visited {
    font-size: 110%;
  }
  #footer-nav-container {
    display: none;
    visibility: hidden;
  }
  #footer-nav-mobile {
    font-size: 125%;

    display: block;
    visibility: visible;
  }
  .footer-nav-mobile-col {
    width: 100%;
  }
  .footer-nav-mobile-col {
    width: 100%;
  }
  .footer-nav-mobile-table {
    width: 100%;

    text-align: center;
    text-align: left; /* text-transform: uppercase; */

    border: none;
  }

  .footer-nav-mobile-table td {
    width: 49%;
    padding: 5px;

    cursor: pointer;

    background-color: #333333;
  }
  .footer-nav-mobile-table td:hover {
    background-color: #333333;
  }
  .footer-nav-mobile-table a {
    text-decoration: none;

    color: #c3c3c3 !important;
  }
  .footer-nav-mobile-table a:hover {
    text-decoration: none;

    color: #dddddd !important;
  }

  section {
    font-size: 125%;
  }

  section .title {
    font-size: 260%;
    line-height: 55px;

    letter-spacing: -1px;
    word-spacing: 0;
  }

  section .title-4 {
    font-size: 180%;
  }

  /* green energy */
  .img-container-hero-green-energy {
    max-height: 290px;
  }
  .hero-green-energy h1 {
    font-size: 130%;
    line-height: 11px;
  }
  .hero-green-energy h4 {
    font-size: 103%;
  }
  .hero-green-energy .in-parenthesis {
    font-size: 95%;
  }
  .zero-down {
    width: 56%;
  }
  .section-process .title-2 {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
  .section-process .title-approval {
    font-size: 155%;
    line-height: 33px;

    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
  }
  .section-customers .row {
    width: 90%;
    margin: 0 auto;
  }
  .section-process .phone-outlines-img,
  .section-process .phone-outlines-text {
    text-align: center;
  }
  .section-process .col,
  .section-customers .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }

  .section-experience {
    background-image: none;
  }
  .section-experience .history {
    z-index: 60;

    height: 470px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
    /* background-size: 1440px 314px; */
  }

  .section-resources-header {
    min-height: 255px;
  }

  .leadership .container {
    grid-template-areas: 'a b'
    'c d';
    grid-template-columns: 1fr 1fr;
  }
  .leadership .grid-item {
    margin-bottom: 20px;
  }

  /* footer pages */
  .img-container-hero-footer-pages-2 {
    max-height: 130px;
    margin-top: 65px;
  }
  .img-container-hero-footer-pages {
    height: 195px;
  }
  .hero-footer h1 {
    font-size: 250%;
    line-height: 50px;

    margin-top: 35px;
    margin-left: 20px;
  }
  .footer-nav {
    font-size: 90%;
  }

  .testimonials .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .testimonials h1 {
    font-size: 280%;
  }
  .testimonials h2 {
    font-size: 240%;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: 'a'
    'b';
    grid-template-columns: 1fr;
  }

  .testimonials .friend-text-bottom {
    display: block;
  }
  .section-refinance-four {
    min-height: 38vh;

    border-bottom: 2px solid #ff7f00;
    background-image: none;
  }
  .section-refinance-four .history {
    z-index: 60;

    height: 470px;
    margin-top: -12px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
  }
}

@media only screen and (min-device-width: 721px) and (max-device-width: 960px) {
  #viewsRoot {
    width: 100% !important;
  }
  html,
  body {
    font-size: 16px;

    overflow-x: hidden !important;

    width: fit-content !important;
  }
  footer {
    width: fit-content !important;
  }
  #menu {
    overflow-y: auto;
  }
  .calculator {
    overflow-y: auto;

    margin-top: 65px;
  }
  .section-refinance-four .history {
    display: none;
  }
}

@media (max-width: 1130px) and (min-width: 961px) {
  #viewsRoot {
    width: 100% !important;
  }
  html,
  body {
    font-size: 16px;

    overflow-x: hidden !important;

    width: fit-content !important;
  }
  footer {
    width: fit-content !important;
  }
  #U356592_animation {
    width: 1130px;
    height: 366px;
  }
  #u474839_animation {
    width: 1130px;
    height: 330px;
  }
  .section-testimonials .quotes-row .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .section-testimonials .quotes-row {
    width: 590px;
    margin-top: 20px;
  }
  /* .rates-modal {
        max-width: 450px;
        width: 450px;
        border-radius: 4px;
        overflow: hidden !important;
        transform: translateY(-50px);
        transition: all .7s;
    } */
  /* .rates-modal .col {
        width: 100%;
        margin: 0 2% 4% 2%;
    }
    */
  .section-mortgage-three .mortgage-rates {
    max-width: 100%;
  }
  .slider-row {
    width: 90%;
  }
  .calculator {
    overflow-y: auto;

    margin-top: 65px;
  }
  .calculator .payment {
    font-size: 230%;
  }
  .financier-paragraph {
    width: 100%;
  }
  .section-refinance-four {
    min-height: 38vh;

    border-bottom: 2px solid #ff7f00;
    background-image: none;
  }
  .section-refinance-four .history {
    z-index: 60;

    height: 470px;
    margin-top: -12px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
  }
  .section-experience .history {
    background-size: 1013px 308px;
  }
  .contactus-header {
    margin-top: 65px;
  }
  .contactus .container {
    grid-template-areas: '. h1 h1 h1 h1 .'
    '. a a b b .'
    '. h2 h2 h2 h2 .'
    '. a1 a1 b1 b1 .'
    '. c1 c1 d1 d1 .'
    '. a2 a2 b2 b2 .'
    '. c2 c2 d2 d2 .'
    '. a3 a3 . . .';
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
  }
  .relative-top-home {
    height: 115px !important;
    min-height: 115px !important;
  }
  .relative-top {
    height: 65px;
    min-height: 65px;
  }
  .hero-mortgage h1 {
    font-size: 250%;
  }
  .section-resources-header {
    margin-top: 65px;
  }
  .hero-company {
    margin-top: 65px;
  }
  .img-container-hero-footer-pages-2 {
    max-height: 150px;
    margin-top: 65px;
  }
  .img-container-hero-footer-pages {
    margin-top: 65px;
  }
  .img-container-hero-company {
    margin-top: 65px;
  }
  .img-container-hero-mortgage {
    margin-top: 65px;
  }
  .img-container-hero-refinance {
    margin-top: 65px;
  }
  .img-container-hero-green-energy {
    margin-top: 65px;
  }
  .slideshow {
    margin-top: 65px;
  }
  .top-nav,
  .main-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }
  .mobile-nav,
  .mobile-nav-icon {
    display: block;
  }
  .phone-nav-mobile {
    display: block;
  }
  .header-banner-mobile {
    left: -145px;
  }
  .relative-top-home {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .relative-top {
    padding-right: 3%;
    padding-left: 3%;
  }

  #footer-banners .col {
    clear: both;

    width: 100%;
  }

  .credits-images,
  .social-links {
    width: 600px;
    margin: 0 auto;

    white-space: nowrap;
  }

  .mobile-nav-sticky-icon {
    display: block;
  }

  .sticky > .main-nav {
    display: none;
    visibility: hidden;
  }

  .section-business-journal .hide-mobile {
    display: none;
  }

  .section-business-journal .show-mobile {
    display: block;
  }

  .section-business-journal {
    padding: 0 !important;

    background-color: #ffffff !important;
  }

  .section-business-journal img {
    width: 80%;
  }

  .img-container-hero-green-energy {
    max-height: 460px;
  }
  .footer-nav {
    font-size: 90%;
  }

  .join-our-team h1,
  .join-our-team h3 {
    display: none;
  }
  .img-container-hero-company {
    min-height: 580px;
  }
  .hero-company .video-thumbnail:before {
    font-size: 530%;

    top: 325px;
  }
  .section-experience {
    background-image: none;
  }
  .section-experience .history {
    z-index: 60;

    height: 470px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
    /* background-size: 1440px 314px; */
  }

  .section-resources-header {
    min-height: 330px;
  }

  .section-paperwork h1 {
    font-size: 185%;
    line-height: 44px;

    position: absolute;
    left: 3%;

    margin-top: 84px;

    text-align: center;
    text-transform: none;
  }

  .section-why-loanpal .benefits-smileys .box {
    position: relative;
    top: -40px;
  }

  .testimonials .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: '. a .'
    '. b .';
    grid-template-columns: 0.15fr 1fr 0.15fr;
  }

  .testimonials .friend-text-bottom {
    display: block;
  }
  .leadership .container {
    grid-template-areas: '. a b .'
    '. c d .';
    grid-template-columns: 0.25fr 1fr 1fr 0.25fr;
  }
  .img-container-hero-green-energy {
    max-height: 360px;
  }
  .hero-green-energy h1 {
    font-size: 169%;
    line-height: 20px;
  }
  .hero-green-energy h4 {
    font-size: 126%;
  }
  .zero-down {
    width: 60%;
  }
  .section-process .title-approval {
    font-size: 144%;
    line-height: 32px;
  }
}

@media only screen and (min-device-width: 961px) and (max-device-width: 1130px) and (orientation: landscape) {
  #viewsRoot {
    width: 100% !important;
  }
  #menu {
    overflow-y: auto;
  }
  .calculator {
    overflow-y: auto;

    margin-top: 65px;
  }
}

@media (max-width: 1280px) and (min-width: 1131px) {
  #viewsRoot {
    width: 100% !important;
  }
  #U356592_animation {
    width: 1280px;
    height: 415px;
  }
  #u474839_animation {
    width: 1280px;
    height: 365px;
  }
  .section-testimonials .quotes-row .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }
  .section-testimonials .quotes-row {
    width: 590px;
    margin-top: 20px;
  }
  .slider-row {
    width: 92%;
  }
  .calculator {
    margin-top: 65px;
  }
  .section-refinance-four {
    min-height: 38vh;

    border-bottom: 2px solid #ff7f00;
    background-image: none;
  }
  .section-refinance-four .history {
    z-index: 60;

    height: 560px;
    margin-top: -12px;

    opacity: 1;
    background: transparent
    url(https://res.cloudinary.com/loanpal/image/upload/v1591663069/loanpal.com/mortgage/historyVerticalStackBluev4.svg)
    no-repeat center top;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        filter: alpha(opacity=100);
  }
  .contactus-header {
    margin-top: 65px;
  }
  .contactus .container {
    grid-template-areas: '. h1 h1 h1 h1 h1 h1 .'
    '. a a a b b b .'
    '. h2 h2 h2 h2 h2 h2 .'
    '. a1 a1 b1 b1 c1 d1 .'
    '. a2 a2 b2 b2 c2 d2 .'
    '. a3 a3 . . . . .';
    grid-template-columns: 0.1fr 0.5fr 0.5fr 0.75fr 0.25fr 1fr 1fr 0.1fr;
  }
  .slideshow {
    margin-top: 65px;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
  }
  .relative-top-home {
    height: 115px !important;
    min-height: 115px !important;
  }
  .relative-top {
    height: 65px;
    min-height: 65px;
  }
  .img-container-hero-company {
    min-height: 580px;
    margin-top: 65px;
  }
  .hero-company .video-thumbnail:before {
    top: 340px;
  }
  .img-container-hero-footer-pages-2 {
    margin-top: 65px;
  }
  .img-container-hero-footer-pages {
    margin-top: 65px;
  }
  .section-resources-header {
    margin-top: 65px;
  }
  .hero-company {
    margin-top: 65px;
  }
  .img-container-hero-mortgage {
    margin-top: 65px;
  }
  .img-container-hero-refinance {
    margin-top: 65px;
  }
  .img-container-hero-green-energy {
    margin-top: 65px;
  }
  .logo-white-720 {
    display: none;
    float: left;

    width: auto;
    height: 25px;
    margin: 8px 41px;
  }
  .mobile-nav,
  .mobile-nav-icon {
    display: block;
  }
  .phone-nav-mobile {
    display: block;
  }
  .header-banner-mobile {
    left: -150px;
  }
  .top-nav,
  .main-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }

  .social-links li a:link,
  .social-links li a:visited {
    font-size: 320%;
  }

  #footer-banners .col {
    clear: both;

    width: 100%;
  }

  /* nav {
    padding: 0 15px;
  } */

  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: '. a .'
    '. b .';
    grid-template-columns: 0.25fr 1fr 0.25fr;
  }

  .testimonials .friend-text-bottom {
    display: block;
  }

  .testimonials .col {
    width: 100%;
    margin: 0 2% 4% 2%;
  }

  .hero-company .join-our-team {
    max-width: 85%;
  }
  .section-paperwork h1 {
    font-size: 247%;
    line-height: 64px;

    left: 3%;

    margin-top: 52px;
  }

  .leadership .container {
    grid-template-areas: 'a b'
    'c d';
    grid-template-columns: 1fr 1fr;
  }
  .img-container-hero-green-energy {
    max-height: 455px;
  }
  .hero-green-energy h1 {
    font-size: 200%;
    line-height: 28px;
  }
  .hero-green-energy h4 {
    font-size: 145%;
  }
}
@media (max-width: 1440px) and (min-width: 1281px) {
  #viewsRoot {
    width: 100% !important;
  }
  #U356592_animation {
    width: 1440px;
    height: 467px;
  }
  #u474839_animation {
    width: 1440px;
    height: 410px;
  }
  .calculator {
    margin-top: 105px;
  }
  .calculator .payment {
    font-size: 225%;
  }
  .hero-company .join-our-team {
    max-width: 73%;
  }
  .img-container-hero-company {
    min-height: 670px;
    margin-top: 0;
  }
  .img-container-hero-footer-pages-2,
  .img-container-hero-footer-pages {
    margin-top: 65px;
  }
  .hero-company .video-thumbnail:before {
    top: 380px;
  }
  .section-paperwork h1 {
    font-size: 280%;
    line-height: 64px;

    left: 3%;

    margin-top: 52px;
  }
  .img-container-hero-green-energy {
    max-height: 455px;
  }
  .hero-green-energy h1 {
    font-size: 225%;
    line-height: 35px;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
  }
  .relative-top-home {
    height: 115px !important;
    min-height: 115px !important;
  }
  .relative-top {
    height: 65px;
    min-height: 65px;
  }
  .contactus .container {
    grid-template-areas: '. h1 h1 h1 h1 h1 h1 .'
    '. a a a b b b .'
    '. h2 h2  h2 h2 h2 h2 .'
    '. a1 a1 b1 b1 c1 d1 .'
    '. a2 a2 b2 b2 c2 d2 .'
    '. a3 a3 . . . . .';
    grid-template-columns: 0.25fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 1fr 0.25fr;
  }
  .phone-nav-mobile {
    display: block;
  }
  .header-banner-mobile {
    left: -150px;
  }
  .top-nav,
  .main-nav,
  .phone-nav {
    display: none;
    visibility: hidden;
  }
  .mobile-nav,
  .mobile-nav-icon {
    display: block;
  }

  .top-nav {
    display: none;
    visibility: hidden;
  }

  #footer-banners .col {
    clear: both;

    width: 100%;
  }
}
@media (max-width: 1500px) and (min-width: 1441px) {
  #viewsRoot {
    width: 100% !important;
  }
  #U123456_animation {
    width: 100%;
    height: 180px;
  }
  #U356592_animation {
    width: 1500px;
    height: 485px;
  }
  #u474839_animation {
    width: 1500px;
    height: 425px;
  }
  .calculator {
    margin-top: 105px;
  }
  .calculator .payment {
    font-size: 225%;
  }
  .hero-company .join-our-team {
    max-width: 70%;
  }
  .img-container-hero-company {
    min-height: 790px;
  }
  .hero-company .video-thumbnail:before {
    top: 425px;
  }
  .section-paperwork h1 {
    font-size: 280%;
    line-height: 64px;

    left: 8%;

    margin-top: 52px;
  }
  .img-container-hero-green-energy {
    max-height: 455px;
  }
  .hero-green-energy h1 {
    font-size: 240%;
    line-height: 35px;
  }
  .mobile-nav {
    display: none;
  }
}
@media (max-width: 1660px) and (min-width: 1501px) {
  #viewsRoot {
    width: 100% !important;
  }
  #U356592_animation {
    width: 1660px;
    height: 537px;
  }
  #u474839_animation {
    width: 1660px;
    height: 467px;
  }
  .mobile-nav {
    display: none;
  }
}
@media (max-width: 1860px) and (min-width: 1661px) {
  #viewsRoot {
    width: 100% !important;
  }
  #U356592_animation {
    width: 1860px;
    height: 600px;
  }
  #u474839_animation {
    width: 1860px;
    height: 545px;
  }
  .mobile-nav {
    display: none;
  }
  .calculator {
    margin-top: 105px;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: '. a b .';
    grid-template-columns: 0.15fr 0.25fr 1fr 0.15fr;
  }
  .img-container-hero-company {
    min-height: 790px;
  }
  .hero-company .video-thumbnail:before {
    top: 425px;
  }
  .hero-company .join-our-team {
    max-width: 65%;
  }
  .section-paperwork h1 {
    font-size: 280%;
    line-height: 64px;

    left: 10%;

    margin-top: 52px;
  }
  .img-container-hero-green-energy {
    max-height: 455px;
  }
  .hero-green-energy h1 {
    font-size: 240%;
    line-height: 35px;
  }
}

@media (max-width: 1920px) and (min-width: 1861px) {
  #U356592_animation {
    width: 1920px;
    height: 622px;
  }
  #u474839_animation {
    width: 1920px;
    height: 550px;
  }
  .calculator {
    margin-top: 105px;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: '. a b .';
    grid-template-columns: 0.25fr 0.25fr 1fr 0.25fr;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 1950px) and (min-width: 1921px) {
  #U356592_animation {
    width: 100%;
    height: 621px;
  }
  #u474839_animation {
    width: 100%;
    height: 540px;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 2000px) and (min-width: 1951px) {
  #U356592_animation {
    width: 100%;
    height: 650px;
  }
  #u474839_animation {
    width: 100%;
    height: 540px;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 2100px) and (min-width: 2001px) {
  #U356592_animation {
    width: 100%;
    height: 620px;
  }
  #u474839_animation {
    width: 100%;
    height: 540px;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 2200px) and (min-width: 2101px) {
  #U356592_animation {
    width: 100%;
    height: 650px;
  }
  #u474839_animation {
    width: 100%;
    height: 610px;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 2300px) and (min-width: 2201px) {
  #U356592_animation {
    width: 100%;
    height: 650px;
  }
  #u474839_animation {
    width: 100%;
    height: 640px;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 2400px) and (min-width: 2301px) {
  #U356592_animation {
    width: 100%;
    height: 650px;
  }
  #u474839_animation {
    width: 100%;
    height: 675px;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 2560px) and (min-width: 2401px) {
  #U356592_animation {
    width: 100%;
    height: 650px;
  }
  #u474839_animation {
    width: 2560px;
    height: 725px;
  }
  .calculator {
    margin-top: 105px;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: '. . a b . .';
    grid-template-columns: 0.15fr 0.15fr 0.25fr 1fr 0.15fr 0.15fr;
  }
  .section-experience {
    background-image: url(https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1595869752/loanpal.com/mortgage/shoesGrey.jpg);
  }
  .section-refinance-four {
    background-image: url(https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1595869752/loanpal.com/mortgage/shoesGrey.jpg);
  }
  .mobile-nav {
    display: none;
  }
}

@media (min-width: 2561px), print {
  #U356592_animation {
    width: 100%;
    height: 650px;
  }
  #u474839_animation {
    width: 100%;
    height: 750px;
  }
  .calculator {
    margin-top: 105px;
  }
  .testimonials .container {
    display: grid;

    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-template-areas: '. . a b . .';
    grid-template-columns: 0.25fr 0.25fr 0.25fr 1fr 0.25fr 0.25fr;
  }
  .section-experience {
    background-image: url(https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1595869752/loanpal.com/mortgage/shoesGrey.jpg);
  }
  .section-refinance-four {
    background-image: url(https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1595869752/loanpal.com/mortgage/shoesGrey.jpg);
  }
  .mobile-nav {
    display: none;
  }
}
