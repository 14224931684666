/* Defaults */
@font-face {
  font-family: 'Avenir';

  src: url('fonts/Avenir.otf') format('opentype');
}

.WL_Page,
.WL_Page * {
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Mobile */
.WL_Page {
  overflow: auto;

  min-height: 100vh;
  padding-top: 0;

  background: #f4f4f4;
}

/* Header */
.WL_Header {
  z-index: 1;

  display: flex;

  justify-content: center;
  align-items: center;

  min-height: 320px;
  padding-top: 128px;
  padding-bottom: 96px;

  background-color: #ffffff;
  background-size: cover;

  clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
  /* This 80% is the % height of the short size, wihch in desktop is 900px*/
}

.WL_Logo {
  z-index: 3;

  width: 240px;
  height: auto;
  margin: 0 auto 14rem;
}

/* Content */
.WL_Content {
  position: relative;
  z-index: 2;

  max-width: 1553px;
  min-height: 50vh;
  margin: -224px auto 105px;
  padding: 0 64px;
}

.WL_Grid {
  display: grid;

  justify-content: center;

  padding: 0;

  grid-column-gap: 4rem;
  grid-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-columns: repeat(auto-fill, minmax(288px, 434px));
}

.WL_VideoCard {
  display: flex;
  overflow: hidden;

  flex-direction: column;

  max-width: 320px;
  margin: 0 auto;

  transition: box-shadow ease-in-out 0.4s;

  border-radius: 40px;
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.1);
}

.WL_VideoCard:hover {
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.3);
}

.WL_PictureWrapper {
  position: relative;

  overflow: hidden;

  width: 100%;
  height: 180px;
}

.WL_Picture {
  width: 100%;
  max-width: 100%;
  height: 100%;

  object-fit: cover;
}

.WL_VideoLink {
  line-height: 0;

  cursor: pointer;
  text-decoration: none;
}

.WL_VideoLink:hover .WL_PlayIcon {
  opacity: 0.5;
}

.WL_PlayIcon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100px;
  margin: auto;

  cursor: pointer;
  transition: opacity ease-in-out 0.4s;
}

.WL_TextWrapper {
  padding: 8% 7% 8%;

  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  background-position: center;
  background-size: cover;
}

.WL_VideoId {
  font-family: 'Avenir';
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;

  text-align: left;
  letter-spacing: normal;

  color: #f8f7f5;
}

.WL_VideoTitle {
  font-family: 'Avenir';
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;

  text-align: left;
  text-transform: capitalize;
  text-transform: uppercase;

  color: #f8f7f5;
}

.WL_VideoDescription {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;

  height: 164px;

  text-align: left;
  letter-spacing: normal;

  color: #ffffff;
}

.WL_VideoButton {
  font-size: 16px;

  display: flex;

  justify-content: center;
  align-items: center;

  width: 175px;
  height: 35px;
  margin-top: 2rem;

  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  color: #fafdfa;
  border: none;
  border-radius: 27px;
  background-color: #f7b334;

  -webkit-appearance: none;
}

.WL_VideoButton:hover {
  background: linear-gradient(#fda90c, #ffab61);
}

/* Tablet */
@media only screen and (min-width: 768px) {
  .WL_Page {
    min-height: 100vh;

    background: #f4f4f4;
  }

  .WL_Header {
    padding-top: 128px;
    padding-bottom: 128px;

    background-color: #ffffff;

    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }

  .WL_Logo {
    width: 20rem;
  }
}

/* Laptop */
@media only screen and (min-width: 1280px) {
  /* Header */
  .WL_Header {
    min-height: 33rem;
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .WL_Grid {
    display: grid;

    max-width: 100%;
    padding: 0 12vw;

    grid-column-gap: 6rem;
    grid-row-gap: 6rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .WL_PictureWrapper {
    height: 250px;
  }

  .WL_VideoTitle,
  .WL_VideoId {
    font-size: 28px;
    line-height: 1.36;
  }

  .WL_VideoCard {
    width: 100%;
    max-width: none;
    margin: auto;
  }

  .WL_Picture {
    width: 100%;
  }

  .WL_Content {
    margin: -322px auto 175px;
  }

  .WL_Logo {
    bottom: 0;

    width: 490px;
  }

  .WL_TextWrapper {
    padding-bottom: 6%;
  }

  .WL_VideoDescription {
    font-size: 22px;

    height: 168px;
  }
}

/* Desktop */
@media only screen and (min-width: 1920px) {
  .WL_Page {
    min-height: 100vh;
  }

  .WL_VideoTitle {
    font-size: 37px;
    line-height: 1.27;

    letter-spacing: -0.74px;
  }

  .WL_VideoDescription {
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;

    text-align: left;
    letter-spacing: normal;
  }

  .WL_VideoButton {
    font-size: 20px;

    width: 250px;
    height: 45px;
  }

  .WL_Logo {
    width: 613px;
  }

  .WL_Grid {
    padding: 8px 5vw;

    grid-column-gap: 128px;
    grid-row-gap: 128px;
  }
  .WL_TextWrapper {
    padding: 10% 8% 11%;
  }
  .WL_PictureWrapper {
    height: 292px;
  }
  .WL_VideoDescription {
    height: 172px;
  }
}
