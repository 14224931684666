@media (max-width: 320px) {
  .img-container-green-energy-slideshow {
    height: 160px;
  }
  .section-green-energy-banner .super-girl {
    background-image: url(../images/banners/mobile/learnMoreButton.png), url(../images/banners/mobile/loanpal_superhero_720x360-min.jpg);
  }
  .section-green-energy-banner .super-girl {
    height: 165px !important;
  }
  .section-green-energy-banner .banner-partners-video-main {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main:hover {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .loans-that-change-lives {
    background-image: url(../images/banners/mobile/loanpal_givepower_720x360_2-min.jpg);
  }
  .section-testimonials {
    height: 352px;
    min-height: 400px;
  }
  .testimonial_slideshow {
    height: 250px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  .img-container-green-energy-slideshow {
    height: 300px !important;
  }
  .section-green-energy-banner .super-girl {
    height: 330px !important;
  }
}
@media (max-width: 480px) and (min-width: 321px) {
  .img-container-green-energy-slideshow {
    height: 189px !important;
  }
  .section-green-energy-banner .super-girl {
    background-image: url(../images/banners/mobile/learnMoreButton.png), url(../images/banners/mobile/loanpal_superhero_720x360-min.jpg);
  }
  .section-green-energy-banner .super-girl {
    height: 189px !important;
  }
  .section-green-energy-banner .banner-partners-video-main {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main:hover {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .loans-that-change-lives {
    background-image: url(../images/banners/mobile/loanpal_givepower_720x360_2-min.jpg);
  }
  .section-experience {
    min-height: 260px;
  }
}
@media (max-width: 720px) and (min-width: 481px) {
  .img-container-green-energy-slideshow {
    height: 186px;
  }
  .section-green-energy-banner .super-girl {
    background-image: url(../images/banners/mobile/learnMoreButton.png), url(../images/banners/mobile/loanpal_superhero_720x360-min.jpg);
  }
  .section-green-energy-banner .super-girl {
    height: 190px;
  }
  .section-green-energy-banner .banner-partners-video-main {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main:hover {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-experience {
    min-height: 440px;
  }
}

@media (max-width: 960px) and (min-width: 721px) {
  .img-container-green-energy-slideshow {
    height: 325px !important;
  }
  .section-green-energy-banner .super-girl {
    background-image: url(../images/banners/mobile/learnMoreButton.png), url(../images/banners/mobile/loanpal_superhero_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main:hover {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-experience {
    min-height: 440px;
  }
}

@media (max-width: 1130px) and (min-width: 961px) {
   /* none */
}
