.LoanpalPro {
  display: flex;
  overflow: hidden;

  flex-direction: column;

  max-width: 100%;
  padding: 24px 40px 0;

  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right -40px top -32px;
  background-size: 294px;
}

.LoanpalPro img {
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .LoanpalPro {
  }
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro {
    display: grid;

    min-height: 600px;
    padding: 64px 64px 0;

    background-position: right -86px;
    background-size: auto;

    gap: 0 40px;
    grid-template-areas: '. . .';
    /* grid-template-columns: 30% 39% 36%; */
    grid-template-columns: 35% 34% 38%;
    grid-template-rows: 1fr;
  }
}

@media only screen and (min-width: 1920px) {
  .LoanpalPro {
    display: grid;

    min-height: 645px;
    padding: 64px 64px 0;

    gap: 0 40px;
    grid-template-areas: '. . .';
    grid-template-columns: 34% 32.66% 33.33%;
    grid-template-rows: 1fr;
  }
}

.LoanpalPro__Content {
  position: relative;

  max-width: 540px;
  margin: 0 auto;
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__Content {
    max-width: none;
  }
}

.LoanpalPro__Stores {
  width: 314px;
  padding: 8px 8px;

  border-radius: 8px 8px 0 0;
  background: #ffffff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__Stores {
    padding: 32px 8px;
  }
}

.LoanpalPro__Stores img {
  max-width: 100%;
  padding: 0 8px;
}

.LoanpalPro__Stores_Title {
  font-size: 12px;
  font-weight: bold;

  display: block;

  width: 100%;
  margin-bottom: 8px;

  text-align: center;
}

.LoanpalPro__Stores_Links {
  display: flex;

  flex-direction: column;
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__Stores_Links {
    flex-direction: row;
  }
}

.LoanpalPro__PictureGroup {
  display: flex;
}

.LoanpalPro__PictureGroup--first {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__PictureGroup--first {
    justify-content: flex-start;
  }
}

.LoanpalPro__PictureGroup--second {
  position: relative;
  left: -150px;

  justify-content: flex-end;
  align-items: flex-end;
}

.LoanpalPro__PictureGroup--third {
  flex-direction: row;
  flex-wrap: wrap;

  max-width: 540px;
  margin: 0 auto;
}

.LoanpalPro__PictureGroup--third > div:nth-child(1) {
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 1rem;
}

.LoanpalPro__PictureGroup--third > div:nth-child(1) img {
  width: 100%;

  border-radius: 16px;
}

.LoanpalPro__PictureGroup--third > div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;

  width: 60%;
  padding-right: 1rem;
}

.LoanpalPro__PictureGroup--third > div:nth-child(2) img {
  width: 100%;
  height: auto;

  border-radius: 24px 24px 0 0;
}

.LoanpalPro__PictureGroup--third > div:nth-child(3) {
  width: 40%;
}

.LoanpalPro__Eyebrow,
.LoanpalPro__Title,
.LoanpalPro__Paragraph,
.LoanpalPro__List {
  font-family: brandon-grotesque, sans-serif;
}

@media only screen and (min-width: 1px) {
  .LoanpalPro__List {
    display: block;
  }

  .LoanpalPro__List {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__List {
    display: none;
  }

  .LoanpalPro__List {
    display: block;
  }
}

.LoanpalPro__Eyebrow {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;

  text-align: left;
  letter-spacing: -0.76px;
  text-transform: uppercase;

  color: #fd8300;
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__Eyebrow {
    font-size: 32px;
    line-height: 1.2;

    letter-spacing: -0.76px;
  }
}

@media only screen and (min-width: 1920px) {
  .LoanpalPro__Eyebrow {
    font-size: 38px;
    line-height: 1.2;

    letter-spacing: -0.76px;
  }
}

.LoanpalPro__Title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;

  margin-bottom: 0.5rem;

  text-align: left;
  letter-spacing: -0.96px;
  text-transform: none;

  color: #4e4e4e;
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__Title {
    font-size: 42px;
    line-height: 1.2;

    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 1920px) {
  .LoanpalPro__Title {
    font-size: 48px;
    line-height: 1.2;

    margin-bottom: 0.5rem;
  }
}

.LoanpalPro__Paragraph {
  font-size: 16px;
  line-height: 1.5;

  margin-bottom: 0.5rem;

  text-align: left;

  color: #4e4e4e;
}

.LoanpalPro__Paragraph--padded-right {
  margin-bottom: 2rem;
  padding-right: 0;
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__Paragraph {
    font-size: 18px;

    margin-bottom: 0.5rem;
  }

  .LoanpalPro__Paragraph--padded-right {
    margin-bottom: 0;
    padding-right: 150px;
  }
}


@media only screen and (min-width: 1920px) {
  .LoanpalPro__Paragraph {
    font-size: 20px;
  }
}

.LoanpalPro__List {
  font-size: 16px;
  font-weight: bold;
  line-height: 2;

  margin-bottom: 0.5rem;

  list-style: none;

  text-align: left;

  color: #4e4e4e;
}

.LoanpalPro__List li {
  display: flex;
}

.LoanpalPro__List li span {
  display: block;
}

.LoanpalPro__List li:before {
  display: block;

  width: 16px;
  height: 16px;
  margin-top: 8px;
  margin-right: 8px;

  content: '';

  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAADqADAAQAAAABAAAADQAAAADZmGEQAAABWUlEQVQoFWMuKCiItbCw4D1x4sRTBgaG/0BMFGDMy8tTZWZh7vvP8P8FEG9m+s+0u7+//zsh3YwgBQVFBbWMDIxmIDZQ81fG/4w7vzJ93Tyrd9YbkBg2wAwStDC3eMTIyOgFYgMNYAMSmqz/WX0trCxkLC0sXwC98R4khwzANoIECooLyoA22SJLwtn/Ga7+Y/63fmLPxFNAMXA4wDVmZWVJsHGwzQTayATXgMb4////M6C2TX///t0DVyQmJvYKqO4DmloULtA7UgxMDGFMbEx2LDCZ95/eezExMAnB+Og0MNAu//vzb4uQkNCJhoaGf2CNCQkJHEAnRqErBvJ//v/3/yDQiRsnTpz4CFkerJFfiD8EqJEXJvHv/7/XwJDdysrEuqOnr+crTByZZgGmHAGghwOBCkFxiOIcZIXobBagh8OATjnw798/DOegK0bhp6WlcaEIEMkBAITLe1PeRMvVAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: center center;
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__List {
    font-size: 18px;
    line-height: 2;

    margin-bottom: 0.5rem;
  }
  .LoanpalPro__List li:nth-child(1n + 2) {
    padding-right: 150px;
  }
}

@media only screen and (min-width: 1920px) {
  .LoanpalPro__List {
    font-size: 20px;
  }
}

.PictureContainer {
  line-height: 0;
}

.PictureContainer img {
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.PictureContainer--video-desktop {
  margin-bottom: 32px;
}

.PictureContainer--appstore {
  margin-bottom: -32px;
}

.PictureContainer--screen {
  margin-right: 32px;

  background: transparent;
}

.PictureContainer--screen img {
  position: relative;

  transform: translateY(44%);
}

@media only screen and (min-width: 1px) {
  .LoanpalPro__PictureGroup--desktop {
    display: none;
  }

  .LoanpalPro__PictureGroup--mobile {
    display: flex;
  }
}

@media only screen and (min-width: 1280px) {
  .LoanpalPro__PictureGroup--desktop {
    display: flex;
  }

  .LoanpalPro__PictureGroup--mobile {
    display: none;
  }
}
