/*
Orange color: #F58320
Grey color: #4F4F50

*/

/* ----------------------------------------------- */
/* BASIC SETUP */
/* ----------------------------------------------- */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: brandon-grotesque, sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 32px;

  max-width: 1920px;
  height: auto;
  min-height: 100%;
  margin: 0 auto;

  color: #555555;
  background-color: #f9f9f9;

  text-rendering: optimizeLegibility;
}

#app,
#root {
  overflow: hidden;

  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

#viewsRoot {
  width: 1920px;
  margin: 0 auto;

  background-color: #ffffff;
}

sup {
  font-size: 50%;

  top: -13px;
}

button:focus {
  outline: 0;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.noscroll {
  overflow-y: hidden;
}

.disclaimer {
  line-height: 20px;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  display: block;
  visibility: hidden;
  clear: both;

  height: 0;

  content: '.';
}

.fadein,
.fadeout {
  visibility: hidden;

  -webkit-transition: opacity 0.2s ease-in-out;
     -moz-transition: opacity 0.2s ease-in-out;
       -o-transition: opacity 0.2s ease-in-out;
          transition: opacity 0.2s ease-in-out;

  opacity: 0;
}
.fadein {
  visibility: visible;

  opacity: 1;
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
}

.fullscreen-bg__video {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}
.mobile-view {
  display: none;
}
.hand {
  cursor: pointer;
}

/* ----------------------------------------------- */
/* REUSABLE COMPONENTS */
/* ----------------------------------------------- */

/* fonts */

.brandon-black {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-Black'; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 900;
  font-style: normal;
}

.brandon-regular {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-Regular'; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.brandon-black-italic {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-BlackItalic';    */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 900;
  font-style: italic;
}

.brandon-regular-italic {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-RegularItalic'; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: italic;
}

.brandon-medium {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-Medium';    */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.brandon-medium-italic {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-MediumItalic'; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 500;
  font-style: italic;
}

.brandon-bold {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-Bold', sans-serif; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.brandon-bold-italic {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-BoldItalic', sans-serif; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  font-style: italic;
}

.brandon-thin {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-Thin'; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 100;
  font-style: normal;
}

.brandon-thin-italic {
  /* font-family: 'Conv_HVD Fonts - BrandonGrotesque-ThinItalic'; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 100;
  font-style: italic;
}

.close-button .fa-circle-o {
  color: #4c4c4c;
}

.close-button .fa-times {
  color: #4c4c4c;
}

.close-button .fa-stack:hover .fa {
  color: #f5821f;
}

.close-button::before {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.close-button::after {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.text-white {
  color: #ffffff !important;
}

.text-grey {
  color: #717271 !important;
}

a.no-link {
  color: #717271 !important;
}

a.no-link:hover {
  color: #ffffff;
}

.row {
  margin: 0 auto;
  padding: 0 5%;
}

section {
  width: 100%;
  padding: 20px 0;

  text-align: center;

  background-color: #ffffff;
  /* -webkit-box-shadow: 0px 2px 5px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 5px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 5px -3px rgba(0,0,0,0.75); */
}

section .show-mobile {
  display: none;
}

section .show-desktop-3k {
  display: none;
}

section .show-mobile-720 {
  display: none;
}

section .show-mobile-480 {
  display: none;
}

section .hide-mobile {
  display: block;
}

section .title {
  font-size: 300%;
  line-height: 70px;

  margin-top: 0;
  margin-bottom: 20px;

  letter-spacing: -1px;
}

section .title-250 {
  /* margin-bottom: 20px; */
  font-size: 250%;
  line-height: 70px;

  margin-top: 0;

  letter-spacing: -1px;
}

section .title-1 {
  font-size: 125%;
  line-height: 125%;

  width: 60%;
  margin: 0 auto;
  padding: 10px 0;
}

section .title-3 {
  font-size: 142%;
  line-height: 145%;

  width: 70%;
  margin: 0 auto;
  padding: 10px 0;
}

section .title-4 {
  font-size: 210%;
  line-height: 145%;

  width: 70%;
  margin: 0 auto;
  padding: 10px 0;

  color: #000000;
}

.long-copy {
  line-height: 145%;

  width: 70%;
  margin-left: 15%;
}

.text-center {
  text-align: center;
}

.box {
  padding: 1%;
}

.text-gray {
  color: #687173 !important;
}

.text-brown {
  color: #4c4c4e !important;
}

.text-orange {
  color: #f5831f !important;
}

.text-yellow {
  color: #f7b334;
}

.text-aqua {
  color: #00838f;
}

.text-green {
  color: #008996;
}

.text-teal {
  color: #02869d;
}
.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: 500;
}

.text-bolder {
  font-weight: bold;
}

.text-200 {
  font-size: 200%;
}

.text-180 {
  font-size: 180%;
}

.text-160 {
  font-size: 160%;
}

.text-150 {
  font-size: 150%;
}

.text-140 {
  font-size: 140%;
}

.text-125 {
  font-size: 125%;
}

.text-110 {
  font-size: 110%;
}

.text-120 {
  font-size: 120%;
}

.text-60 {
  font-size: 60% !important;
}

.text-80 {
  font-size: 80% !important;
}

.text-90 {
  font-size: 90% !important;
}
.text-95 {
  font-size: 95% !important;
}

/* Margins */
.p-20 {
  margin: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-40n {
  margin-top: -40px;
}
.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-180 {
  margin-left: 180px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.p-20 {
  padding: 20px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-20 {
  padding-left: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-25 {
  padding-right: 25px;
}

.p-0 {
  padding: 0 !important;
}

/* ----- HEADINGS ----- */
h1,
h2,
h3 {
  font-weight: 300;

  text-transform: uppercase;
}

h1 {
  font-size: 240%;

  margin-top: 0;
  margin-bottom: 20px;

  letter-spacing: 1px;
  word-spacing: 4px;

  color: #ffffff;
}

h2 {
  font-size: 180%;

  text-align: center;
  letter-spacing: 1px;
  word-spacing: 2px;
}

h3 {
  font-size: 110%;

  margin-bottom: 15px;
}

/* h2:after {
    display: block;
    height: 2px;
    background-color: #e67e22;
    content: " ";
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
} */

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

::-webkit-input-placeholder {
  color: rgb(206, 204, 204);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(206, 204, 204);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(206, 204, 204);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: rgb(206, 204, 204);
}

::-ms-input-placeholder {
  /* Edge */
  color: rgb(206, 204, 204);
}

:placeholder-shown {
  /* Standard one last! */
  color: rgb(206, 204, 204);
}

/* ----- PARAGRAPHS ----- */
.long-copy {
  line-height: 145%;

  width: 70%;
  margin-left: 15%;
}

.box p {
  font-size: 110%;
  line-height: 130%;
}

/* ----- ICONS ----- */
.icon-big {
  font-size: 350%;

  display: block;

  margin-bottom: 10px;

  color: #e67e22;
}

.icon-small {
  font-size: 120%;

/*secrets to align text and icons*/
  line-height: 120%;

  display: inline-block;

  width: 30px;
  margin-top: -5px;
  margin-right: 10px;

  text-align: center;
  vertical-align: middle;

  color: #e67e22;
}

/* ----- BUTTONS ----- */
.btn,
.btn:link,
.btn:visited,
input[type='submit'] {
  font-weight: 300;

  display: inline-block;

  padding: 10px 30px;

  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
          transition: background-color 0.2s, border 0.2s, color 0.2s;
  text-decoration: none;

  border-radius: 10px;
}

.btn-yellow:link,
.btn-yellow:visited,
input[type='submit'] {
  color: #ffffff;
  border: 1px solid #f7b233;
  background-color: #f7b233;
}

.btn-full,
.btn-full:link,
.btn-full:visited {
  margin-right: 10px;

  color: #ffffff;
  border: 1px solid #0f7784;
  background-color: #0f7784;
}

.btn-full-orange:link,
.btn-full-orange {
  color: #ffffff;
  border: 1px solid #e67e22;
  background-color: #e67e22;
}

.btn-full-orange:hover,
.btn-full-orange:active {
  border: 1px solid #f7b233;
  background-color: #f7b233;
}

.btn-ghost:link,
.btn-ghost:visited {
  color: #e67e22;
  border: 1px solid #e67e22;
}

.btn:hover,
.btn:active,
input[type='submit']:hover,
input[type='submit']:active {
  background-color: #f89f15;
}

.btn-full:hover,
.btn-full:active {
  border: 1px solid #f89f15;
}

.btn-ghost:hover,
.btn-ghost:active {
  color: #ffffff;
  border: 1px solid #cf6d17;
}

.btn-orange {
  background-color: #e67e22 !important;
}

.btn-orange:hover {
  background-color: #0f7784 !important;
}

/* ----------------------------------------------- */
/* HEADER */
/* ----------------------------------------------- */

.img-container-hero-company {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  min-height: 920px;
  margin-top: 105px;

  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/06-ParamountTeamPhoto_treated_cropped-v3-min.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-hero-home-improvement {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  min-height: 496px;
  margin-top: 60px;

  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/remodelmishap.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-hero-refinance {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  min-height: 440px;
  margin-top: 105px;

  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/dogwig.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}
.go-home-button {
  height: 100px;
  margin: 0 auto;
  padding-bottom: 10px;
}
.go-home-button .logo {
  height: 50px;
}
.img-container-hero-footer-pages-2 {
  line-height: 65px;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  height: 60vh;
  max-height: 180px;
  margin-top: 105px;

  text-align: center;

  background: linear-gradient(#282828, transparent),
  linear-gradient(to top left, #5f5f5f, transparent),
  linear-gradient(to top right, #949494, transparent);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}
.img-container-hero-footer-pages {
  line-height: 65px;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  height: 60vh;
  max-height: 291px;
  margin-top: 105px;

  text-align: center;

  background: url(../images/dog_background.png),
  linear-gradient(#282828, transparent),
  linear-gradient(to top left, #5f5f5f, transparent),
  linear-gradient(to top right, #949494, transparent);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-hero-no-match-pages {
  line-height: 65px;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  height: 60vh;
  max-height: 291px;
  margin-top: 65px;

  text-align: center;

  background: url(../images/surprised-girl.png),
  linear-gradient(#282828, transparent),
  linear-gradient(to top left, #5f5f5f, transparent),
  linear-gradient(to top right, #949494, transparent);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

/* .img-container-hero-direct-pay {
    background-image: url(../images/direct-pay/solar-houses.png);
    background-size: cover;
    background-color: #ffffff;
    background-repeat: no-repeat;
    height: 600px;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
} */

.green-icon {
  fill: #00838f !important;
}

#warriors-of-light-root .MuiButton-label {
  display: block;

  justify-content: inherit;
  align-items: inherit;
}

/* .img-container-hero-direct-pay  .intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

/* .img-container-hero-direct-pay .intro .border {
    width: 21%;
    border-bottom: 3px solid #ffffff;
    position: relative;
    top: -17px;
}

.img-container-hero-direct-pay .intro .right {
    right: 15px;
}

.img-container-hero-direct-pay .intro .left {
    left: 15px;
} */

/* .img-container-hero-direct-pay  > div {
    font-size: 130%;
} */

/* .img-container-hero-direct-pay  .intro-text {
    font-size: 150%;
    word-spacing: 2px;
    text-align: center;
    letter-spacing: .5px;
} */

/* .img-container-hero-direct-pay  .smiley-text {
    height: auto;
    width: 44px;
} */

/* .img-container-hero-direct-pay  > div > h1, h2, .intro-text {
    color: white;
    padding: 15px;
  } */

@media (max-width: 576px) {
  .img-container-hero-no-match-pages {
    align-items: flex-start;

    height: 400px;
  }
}

.img-container-hero-green-energy-home {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  height: 22vh;
  max-height: 500px;

  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/SuperGreenGirlMainPage3k.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-hero-green-energy {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  height: 22vh;

/* height: 35vh; */
  max-height: 500px;
  margin-top: 100px;

  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/green-energy/SuperGreenGirl3k.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;

/* background-image: url(../images/supergreengirl.jpg);  */
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.diy-slideshow .get-started {
  position: relative;

  padding-top: 15px;

/* top: 40%; */

  color: #ffffff;

  /* text-transform: uppercase; */
}

.diy-slideshow h1 {
  font-size: 155%;

  width: 100%;
}

.diy-slideshow ul {
  line-height: 27px;

  margin: 30px 0;

  text-align: left;
}

.diy-slideshow .btn-full:link,
.diy-slideshow .btn-full:visited {
  margin-right: 15px;

  color: #ffffff;
  border: 1px solid #26cad3;
  background-color: #26cad3;
}

.diy-slideshow .btn {
  position: relative;
  top: 0;
  left: 0;

  text-transform: uppercase;
}

.diy-slideshow .btn:link,
.diy-slideshow .btn:visited,
.diy-slideshow input[type='submit'] {
  font-size: 90%;
  font-weight: 300;

  display: inline-block;

  padding: 20px 20px;

  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
          transition: background-color 0.2s, border 0.2s, color 0.2s;
  text-decoration: none;

  border-radius: 5px;
}

.diy-slideshow .btn-full:hover,
.diy-slideshow .btn-full:active {
  border: 1px solid #f89f15;
  background-color: #f89f15;
}

.img-container-green-energy-slideshow {
  height: 440px;
}

.img-container-green-energy-slideshow .button-box {
  position: absolute;
  top: 12%;
  left: 9%;

  width: 39%;
  height: 75%;
  /* background-color: rgba(0,0,0, .2); */
}

.img-container-green-energy-slideshow-wp {
  height: 680px;
}

.img-container-green-energy-slideshow-wp .button-box {
  position: absolute;
  top: 12%;
  left: 9%;

  width: 39%;
  height: 75%;
  /* background-color: rgba(0,0,0, .2); */
}

#U123456_animation {
  width: 100%;
  height: 180px;
}

.img-container-solar-power-superheroes {
  position: relative;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

/* height: 345px; */

  max-height: 500px;
  margin-top: 105px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #f8b533;
  background-image: url(../images/green-energy/slideshow/get-started-button.png),
  url(../images/green-energy/slideshow/min/01_SolarGirl_SolarLending-min.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-solar-power-superheroes:hover {
  background-image: url(../images/green-energy/slideshow/get-started-button-hover.png),
  url(../images/green-energy/slideshow/min/01_SolarGirl_SolarLending-min.jpg);
}

.img-container-red-tape-underwriting {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

/* margin-top: 105px; */
  /* height: 22vh; */

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #abd7d6;
  background-image: url(../images/green-energy/slideshow/get-started-button.png),
  url(../images/green-energy/slideshow/min/03_RedTape_Underwriting-min.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-red-tape-underwriting:hover {
  background-image: url(../images/green-energy/slideshow/get-started-button-hover.png),
  url(../images/green-energy/slideshow/min/03_RedTape_Underwriting-min.jpg);
}

.img-container-lets-raise-the-roof {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

/* margin-top: 105px; */
  /* height: 22vh; */

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #e9932e;
  background-image: url(../images/green-energy/slideshow/get-started-button.png),
  url(../images/green-energy/slideshow/min/Giraffe_creditLimit-min.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-lets-raise-the-roof:hover {
  background-image: url(../images/green-energy/slideshow/get-started-button-hover.png),
  url(../images/green-energy/slideshow/min/Giraffe_creditLimit-min.jpg);
}

.img-container-integration-so-seamless {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

/* margin-top: 105px; */
  /* height: 22vh; */

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #9f9c97;
  background-image: url(../images/green-energy/slideshow/get-started-button.png),
  url(../images/green-energy/slideshow/min/Chameleon_Integration-min.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-integration-so-seamless:hover {
  background-image: url(../images/green-energy/slideshow/get-started-button-hover.png),
  url(../images/green-energy/slideshow/min/Chameleon_Integration-min.jpg);
}

.img-container-moving-super-fast {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

/* margin-top: 105px; */
  /* height: 22vh; */

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #9f9c97;
  background-image: url(../images/green-energy/slideshow/get-started-button.png),
  url(../images/green-energy/slideshow/min/Snails_customerApprovalTime-min.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-moving-super-fast:hover {
  background-image: url(../images/green-energy/slideshow/get-started-button-hover.png),
  url(../images/green-energy/slideshow/min/Snails_customerApprovalTime-min.jpg);
}

.img-container-its-time-to-fly {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

/* margin-top: 105px; */
  /* height: 22vh; */

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #a27766;
  background-image: url(../images/green-energy/slideshow/get-started-button.png),
  url(../images/green-energy/slideshow/min/02_RocketBoy_closeDeals-min.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-its-time-to-fly:hover {
  background-image: url(../images/green-energy/slideshow/get-started-button-hover.png),
  url(../images/green-energy/slideshow/min/02_RocketBoy_closeDeals-min.jpg);
}

.img-container-keep-your-sales-team-happy {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

/* margin-top: 105px; */
  /* height: 22vh; */

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #a27766;
  background-image: url(../images/green-energy/slideshow/get-started-button.png),
  url(../images/green-energy/slideshow/min/04_DogSmiling_salesTeam-min.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-keep-your-sales-team-happy:hover {
  background-image: url(../images/green-energy/slideshow/get-started-button-hover.png),
  url(../images/green-energy/slideshow/min/04_DogSmiling_salesTeam-min.jpg);
}

.img-container-transparency {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

/* margin-top: 105px; */
  /* height: 22vh; */

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #c4cabc;
  background-image: url(../images/green-energy/slideshow/get-started-button.png),
  url(../images/green-energy/slideshow/min/02_transparency_3850-min.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.img-container-transparency:hover {
  background-image: url(../images/green-energy/slideshow/get-started-button-hover.png),
  url(../images/green-energy/slideshow/min/02_transparency_3850-min.jpg);
}

@keyframes slidy {
  0% {
    left: 0;
  }
  20% {
    left: 0;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}

.green-energy-main-div .slider-keyframes {
  overflow: hidden;

  margin: 0 !important;
  padding-top: 105px;
}
.green-energy-main-div .slider-keyframes figure img {
  float: left;

  width: 20%;
}
.green-energy-main-div .slider-keyframes figure {
  font-size: 0;

  position: relative;
  left: 0;

  width: 500%;
  margin: 0;

  animation: 30s slidy infinite;
  text-align: left;
}

.green-energy-main-div .slider-keyframes figure {
  margin: 0;
}

.img-container-hero-mortgage {
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  height: 60vh;
  max-height: 400px;
  margin-top: 105px;

  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/piggybankhammer.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}
/*
.hero-border {
    border-top: 4px solid #717271;
    border-bottom: 4px solid #717271;
} */

.hero-border-top {
  border-top: 6px solid #717271;
}

header {
  clear: both;
  overflow: hidden;

/* border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc; */

  width: 1920px;
  margin: 0 auto;
}

/* Slideshow */
.slideshow-movie {
  display: none;
}

.slideshow-movie video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.slideshow-movie video#home-video {
  width: 100%;

  /*background: url('/resources/assets/mainheader_march_2019/HeaderSlideshow_720.jpg');*/
  background-repeat: no-repeat;
  background-size: cover;
}

.slideshow-movie video#benefits-video {
  /*background: url('/resources/assets/friendslideshow/benefits_mobileVideo.jpg');*/
  background-repeat: no-repeat;
  background-size: cover;
}

.slideshow-movie video#solar-video {
  width: 100%;

 /* background: url('/resources/assets/green_energy/SolarPageSlideShow720Mobile_v3.jpg');*/
  background-repeat: no-repeat;
  background-size: cover;
}

#testimonial_animation {
  width: 860px;
  height: 640px;
  margin: 0 auto;
}

.slideshow {
  position: relative;
  z-index: 0;

  overflow: hidden;

  width: 100%;
  height: auto;
  margin-top: 105px;
  margin-bottom: -41px;
}

.slideshow-home {
  margin-top: 0 !important;
}

/* .slideshow iframe {
    visibility: hidden;
    display: none;
} */

.slideshow .iframe-loader {
  z-index: 0;
  top: 105px;

  display: block;

  width: 100%;
  height: auto;
}

.slideshow-nav-mobile {
  display: none;
}

.slideshow-nav {
  z-index: 2;

  width: 800px;
  height: 112px;
  margin: 0 auto;

  white-space: nowrap;
}

.slideshow-nav li {
  display: inline-block;
}
.slideshow-nav img {
  width: 115px;
  height: 80px;
}

.home-improvement-2 {
  display: block;

  width: 185px;
  height: 103px;

  background: url(../images/homeImprovement_gray.png) top/185px;
  background-size: 185px 103px;
}

.home-improvement-2:hover {
  background-position: 0 103px;
}

.home-loans {
  display: block;

  width: 185px;
  height: 103px;

  background: url(../images/homeLoans_gray.png) top/185px;
  background-size: 185px 103px;
}

.home-loans:hover {
  background-position: 0 103px;
}

.home-refinance {
  display: block;

  width: 185px;
  height: 103px;

  background: url(../images/homeRefinance_gray.png) top/185px;
  background-size: 185px 103px;
}

.home-refinance:hover {
  background-position: 0 103px;
}

/* End of new menu of the future */

.logo {
  float: left;

  width: auto;
  height: 71px;
  margin-top: 16px;
}

.logo-white {
  float: left;

  width: auto;
  height: 30px;
  margin: 15px 20px;
}

.logo-white-720 {
  display: none;
}
.logo-warriors {
  float: left;

  width: 135px;
  margin-top: 1.5rem;
}
@media only screen and (min-width: 1131px) {
  .logo-warriors {
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1441px) {
  .logo-warriors {
    width: 235.2px;
    margin-top: 1.8rem;
  }
}
/* top nav */
.relative-top {
  position: fixed;

/* top: 0;
    right: 0;
    left: 0; */
  z-index: 99;

  width: 100%;
  max-width: 1920px;
  height: 110px;
  min-height: 110px;
  margin: 0 auto;

  color: #ffffff;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 2px -2px #cccccc;
     -moz-box-shadow: 0 2px 2px -2px #cccccc;

/* border-bottom: 1px solid #5E5F5F;
    border-right: 1px solid #c8c8c8; */
  /* border-left: 1px solid #c8c8c8; */
          box-shadow: 0 2px 2px -2px #cccccc;
}
.relative-top-home {
  height: 150px !important;
  min-height: 150px !important;
}
.top-warriors {
  position: absolute;

  background-color: #555555;
  box-shadow: none;
}
.top-warriors > #main-nav-div > .main-nav {
  background-color: #555555;
}

.top-warriors > #main-nav-div > .mobile-nav > li > div,
.top-warriors > #main-nav-div > .mobile-nav > li > div > .UsableNetAssistive,
.top-warriors > #main-nav-div > .top-nav > li > div > a,
.top-warriors > #main-nav-div > .top-nav > li > div,
.top-warriors > #main-nav-div > .main-nav > li > a,
.top-warriors > #main-nav-div > .top-nav > li > button {
  color: white;
}
.top-warriors > #main-nav-div > .top-nav > li > button.brandon-black {
  font-weight: bold;

  color: #ff8300;
  border: none;
  background-color: white;
}
.top-warriors > #main-nav-div > .top-nav > li > button.brandon-black:hover {
  color: white;
  border: none;
  background-color: #ff8300;
}
.top-warriors > #main-nav-div > .main-nav > li > a:hover,
.top-warriors > #main-nav-div > .top-nav > li > button:hover,
.top-warriors > #main-nav-div > .top-nav > li > div > a:hover,
.top-warriors > #main-nav-div > .top-nav > li > div:hover {
  color: #f58320;
}
.top-warriors > #main-nav-div > .mobile-nav > li > .mobile-nav-icon {
  background: transparent;
}
.top-warriors > #main-nav-div > .mobile-nav > li > .mobile-nav-icon > i {
  color: orange;
  background: transparent;
}

.phone {
  font-size: 78%;

  float: left;

  width: auto;
  margin-top: 10px;
}

.icon-phone {
  font-size: 190%;

/*secrets to align text and icons*/
  line-height: 10%;

  display: inline-block;

  width: 20px;
  margin-top: -5px;
  margin-right: 5px;

  text-align: center;
  vertical-align: middle;

  color: #ffffff;
}

.bg-inverse {
  background-color: #4f4f50 !important;
}
#main-nav-div {
  position: relative;
}

/* Enable Accessibility Usable Net */
.UsableNetAssistive {
  padding-right: 10px;

  transition: none;
  white-space: nowrap;
  text-decoration: none;

  color: #687173;
  border: none;
}

.UsableNetAssistive {
  padding: 0;
}

.UsableNetAssistive:focus {
  outline: none;
}

.header-banner {
  font-size: 80%;

  position: absolute;
  top: -56px;
  left: 40px;

  width: 105px;

  color: #717271;
}

.header-banner-mobile {
  font-size: 80%;

  position: absolute;
  top: -20px;
  left: -108px;

  width: 105px;

  color: #717271;
}

/* phone nav */
.phone-nav {
  font-size: 80%;

  position: absolute;
  z-index: 999999;
  top: -35px;
  left: 40px;

  width: 105px;

  color: #717271;
}
.phone-nav-mobile {
  font-size: 80%;

  position: absolute;
  z-index: 999999;
  top: 0;
  left: -120px;

  display: none;

  width: 110px;

  color: #717271;
}
/* Top navi */
.top-nav {
  position: relative;

  float: right;

  margin-top: 43px;

  list-style: none;
}

.top-nav li {
  display: inline-block;

  margin-left: 20px;
}

.top-nav li .header-banner a:link,
.top-nav li .header-banner a:visited {
  padding: 0 !important;
}

.top-nav li a:link,
.top-nav li a:visited {
  font-size: 80%;

  padding: 35px 0;

  -webkit-transition: border-bottom 0.2s;
          transition: border-bottom 0.2s;
  text-decoration: none;

  color: #717271;
  border-bottom: 2px solid transparent;
}

.top-nav li a:hover,
.top-nav li a:active {
  color: #f58320;
}

.top-nav li button {
  font-size: 80%;

  padding: 17px 0;

  -webkit-transition: border-bottom 0.2s;
          transition: border-bottom 0.2s;
  text-decoration: none;

  color: #717271;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
}

.top-nav li button:hover,
.top-nav li button:active {
  color: #f58320;
}

.top-nav .btn-sm:link,
.top-nav .btn-sm:visited {
  font-size: 70%;
  font-weight: 300;

  display: inline-block;

  margin-left: 35px;
  padding: 4px 13px;

  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
          transition: background-color 0.2s, border 0.2s, color 0.2s;
  text-decoration: none;
  text-transform: uppercase;

  border-radius: 15px;
}

.top-nav .btn-ghost-white:link,
.top-nav .btn-ghost-white:visited {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.top-nav .btn-ghost-white:hover,
.top-nav .btn-ghost-white:active {
  color: #f58320;
  background-color: #ffffff;
}

.top-nav .btn-ghost-grey:link,
.top-nav .btn-ghost-grey:visited {
  color: #717271;
  border: 2px solid #717271;
}

.top-nav .btn-ghost-grey:hover,
.top-nav .btn-ghost-grey:active {
  color: #ffffff;
  border: 2px solid #f58320;
  background-color: #f58320;
}

.top-nav .btn-sm {
  font-size: 70%;
  font-weight: 300;

  display: inline-block;

  margin-left: 35px;
  padding: 4px 13px;

  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
          transition: background-color 0.2s, border 0.2s, color 0.2s;
  text-decoration: none;
  text-transform: uppercase;

  border-radius: 15px;
}

.top-nav .btn-ghost-grey {
  color: #717271;
  border: 2px solid #717271;
}

.top-nav .btn-ghost-grey:hover {
  color: #ffffff;
  border: 2px solid #f58320;
  background-color: #f58320;
}

/* Main navi */
.main-nav {
  position: relative;

  float: left;

  margin-top: 49px;
  padding-bottom: 15px;

  list-style: none;

  background-color: #ffffff;
}

.main-nav li {
  font-weight: 700;

  display: inline-block;

  margin-left: 40px;
}

.main-nav li a:link,
.main-nav li a:visited {
  font-size: 70%;
  font-weight: 700;

  padding: 38px 0;

  vertical-align: text-top;
  text-decoration: none;
  text-transform: uppercase;

  color: #717271;
  /* border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom 0.2s;
    transition: border-bottom 0.2s; */
}

.main-nav li a:hover,
.main-nav li a:active {
  /*border-bottom: 2px solid #e67e22;*/
  color: #f58320;
}

/* Mobile navi */
.mobile-nav {
  position: relative;

  float: right;

  list-style: none;
}
.mobile-nav-icon {
  display: none;

  margin-top: 19px;

  cursor: pointer;

  color: #717271;
  border: none;
}

.mobile-nav-icon i {
  font-size: 180%;

  color: #717271;
  background-color: #ffffff;
}

.mobile-nav-sticky {
  float: right;

  margin-top: 5px;

  list-style: none;
}
.mobile-nav-sticky-icon {
  display: none;

  cursor: pointer;
}

.mobile-nav-sticky-icon i {
  font-size: 160%;

  color: #ffffff;
}

#menu {
  z-index: 999;

  overflow-y: hidden;

  max-height: 100%;

  background-color: #4f4f50;
}
#calculator {
  z-index: 999;

  background-color: #dedede;
}

#menu-sticky {
  background-color: #4f4f50;
}

.slide-menu {
  font-size: 80%;

  overflow: hidden;

  width: 266px;
  padding-top: 25px;

/* font-family: Lato; */

  list-style: none;

  text-align: center;

  border: none;
}

.slide-menu ul {
  padding: 0;
}

.slide-menu li {
  /* margin: 20px auto 20px auto; */
  width: 100%;
}

.slide-menu li a:link,
.slide-menu li a:visited {
  font-size: 65%;

  padding: 8px 0;

  text-decoration: none;
  letter-spacing: 2px;
  text-transform: uppercase;

  color: #ffffff;
  /* border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom 0.2s;
    transition: border-bottom 0.2s; */
}

.slide-menu li a:hover,
.slide-menu li a:active {
  color: #f58320;
}

.slide-menu hr {
  width: 67%;
  min-width: 100px;
  margin: 0 auto;

  border-color: rgb(80, 183, 196);
}

.left-exit {
  top: 10px;
  right: 40px;
}

.exit {
  font-size: 20px;

  position: absolute;

  cursor: pointer;

  color: #ffffff;
}

.top-exit {
  top: 10px;
  right: 40px;
}

.exit-calc {
  font-size: 20px;

  position: absolute;

  cursor: pointer;

  color: #4f4f50;
}

/* Sticky navi */
.sticky {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  min-height: 40px;
  padding: 0 8%;

  background-color: #4e4e4f;
  box-shadow: 0 2px 2px #efefef;
}

.sticky .main-nav {
  background-color: #4e4e4f;
}

.sticky .main-nav li a:link,
.sticky .main-nav li a:visited {
  color: #ffffff;
}

.sticky .logo {
  display: none;
}
.sticky .logo-white {
  display: block;
}

.sticky .btn-sm:link,
.sticky .btn-sm:visited {
  font-size: 60%;
  font-weight: 300;

  display: inline-block;

  margin-left: 35px;
  padding: 3px 10px;

  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
          transition: background-color 0.2s, border 0.2s, color 0.2s;
  text-decoration: none;
  text-transform: uppercase;

  border-radius: 15px;
}

.sticky .btn-ghost-white:link,
.sticky .btn-ghost-white:visited {
  color: #f58320;
  border: 1px solid #ffffff;
}

.sticky .btn-ghost-white:hover,
.sticky .btn-ghost-white:active {
  color: #f58320 !important;
  background-color: #ffffff;
}

/*  drop down menu */

.dropdown {
  position: relative;
}

.dropdown-content,
.dropdown-content-2,
.dropdown-content-3,
.dropdown-content-4,
.dropdown-content-3-4 {
  position: absolute;
  z-index: 1;

  display: none;

  min-width: 155px;

  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.dropdown-content .arrow-down,
.dropdown-content-2 .arrow-down,
.dropdown-content-3 .arrow-down,
.dropdown-content-4 .arrow-down {
  position: absolute;
  top: 0;
  left: 15px;

  display: inline-block;

  width: 0;
  height: 0;

  border-top: 7px solid #ff7f00;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.dropdown-content {
  top: 66px;
  left: 117px;
}

.dropdown-content-2 {
  top: 60px;
  left: 26px;
}

.dropdown-content-3 {
  top: 60px;
  left: 162px;
}

.dropdown-content-4 {
  top: 66px;
  left: 17px;
}

.dropdown-content-3-4 {
  top: 182px;
  left: 159px;
}

.dropdown-content a:nth-child(2),
.dropdown-content-2 a:nth-child(2),
.dropdown-content-3 a:nth-child(2),
.dropdown-content-4 a:nth-child(2) {
  margin-top: 30px;
}

.dropdown-content a:nth-last-child(1),
.dropdown-content-2 a:nth-last-child(1),
.dropdown-content-3 a:nth-last-child(1),
.dropdown-content-4 a:nth-last-child(1) {
  margin-bottom: 10px;
}

.dropdown-content:hover,
.dropdown-content-2:hover,
.dropdown-content-3:hover,
.dropdown-content-4:hover,
.dropdown-content-3-4:hover {
  display: block;
}

.dropdown-content a,
.dropdown-content-2 a,
.dropdown-content-3 a,
.dropdown-content-4 a {
  font-size: 80%;

  display: block;

  padding: 8px 16px;

  text-decoration: none;

  color: #4f4f50;
  background-color: #ffffff;
}

.dropdown-content a:hover,
.dropdown-content-2 a:hover,
.dropdown-content-3 a:hover,
.dropdown-content-4 a:hover {
  color: #f58320;
}

.dropdown-content-3 a:nth-child(5):hover ~ .dropdown-content-3-4 {
  display: block;
}

.dropdown1:hover ~ .dropdown-content {
  display: block;
}
.dropdown2:hover ~ .dropdown-content-2 {
  display: block;
}
.dropdown3:hover ~ .dropdown-content-3 {
  display: block;
}
.dropdown4:hover ~ .dropdown-content-4 {
  display: block;
}

/* ----------------------------------------------- */
/* SECTION CSS SHARED */
/* ----------------------------------------------- */

.section-two {
  position: relative;
}

.benefits-section-two {
  margin: 0 auto;
}

.benefits-section-two .title-2 {
  font-size: 120%;

  max-width: 570px;
  margin: 0 auto;
  padding: 25px 0;
}

.js--section-know-your-loan .title-1 {
  max-width: 675px;
}

.section-two .btn {
  margin: 20px 0;

  text-transform: uppercase;
}
.border-top-orange {
  border-top: 5px solid #f58320;
}

.orange-banner {
  /* position: fixed;
    z-index: 99;
    height: 40px;
    color: #ffffff;
    min-height: 40px;
    max-width: 1920px;
    width:100%;
    margin: 0 auto; */
  position: relative;

  height: 40px;
  margin: 0 auto;

  color: #ffffff;
  background-color: #f58320;
}

.orange-banner div {
  padding-top: 5px;
}

.orange-banner a {
  text-decoration: underline;

  color: #ffffff;
}

.orange-banner a:hover {
  text-decoration: none;

  color: #ffffff;
}

.top-orange-bar {
  line-height: 29px;

  position: relative;
  top: -21px;

  width: fit-content;
  height: 35px;
  min-height: 26px;
  margin: 0 auto;
  padding: 0 20px;

  text-align: center;
  vertical-align: middle;

  color: #ffffff;
  background-color: #f58320;
}

.border-top-grey {
  border-top: 5px solid #717271;
}

.top-grey-bar {
  line-height: 29px;

  position: relative;
  top: -21px;

  width: fit-content;
  height: 35px;
  min-height: 26px;
  margin: 0 auto;
  padding: 0 20px;

  text-align: center;
  vertical-align: middle;

  color: #ffffff;
  background-color: #717271;
}

.top-maroon-bar {
  line-height: 29px;

  position: relative;
  top: -21px;

  width: fit-content;
  height: 35px;
  min-height: 26px;
  margin: 0 auto;
  padding: 0 20px;

  text-align: center;
  vertical-align: middle;

  color: #ffffff;
  background-color: #aa483d;
}

.top-yellow-bar {
  line-height: 29px;

  position: relative;
  top: -20px;

  width: fit-content;
  height: 35px;
  min-height: 26px;
  margin: 0 auto;
  padding: 0 20px;

  text-align: center;
  vertical-align: middle;

  color: #ffffff;
  background-color: #f7b233;
}

.section-business-journal {
  padding: 0 !important;

  background-color: #e4e4e4;
}

.section-business-journal img {
  width: 85%;
}

/* ----------------------------------------------- */
/*  HOME PAGE */
/* ----------------------------------------------- */

.employees .slider {
  margin: 0 !important;
}
/* .loans-that-change-lives {
    -webkit-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
} */

/* ----------------------------------------------- */
/*  SECTION TOGETHER MORE THAN EVER */
/* ----------------------------------------------- */

@media (max-width: 800px) {
  /* mobile view */
  .section-together {
    padding: 20px 0 !important;
  }
  .section-together .wp-video {
    display: none !important;
  }

  .section-together .title-250 {
    font-size: 200% !important;

    margin: 0;
  }
}

@media (min-width: 800px) {
  /* desktop view */
  .section-together .wp-video-mobile {
    display: none !important;
  }
  .section-together .title-250 {
    margin: 30px 0;
  }
}

/* ----------------------------------------------- */
/*  SECTION WORLD POSITIVE 4 HOME PAGE */
/* ----------------------------------------------- */
@media (max-width: 1000px) {
  /* mobile view */
  main {
    display: grid;

    align-items: center;

    grid-template-columns: 1fr;
  }
  .section-4-squares .info2-mobile,
  .section-4-squares .info-mobile,
  .section-4-squares .conscious-info-mobile {
    display: block !important;
  }

  .section-4-squares .wp-video,
  .section-4-squares .info,
  .section-4-squares .info2,
  .section-4-squares .conscious-info,
  .section-testimonials .claims,
  .loans-that-change-lives .change-lives,
  .section-employees .carousel-desktop {
    display: none !important;
  }
  .section-4-squares .outer-box {
    margin: 0 20px !important;
  }
  .section-4-squares .title {
    font-size: 160%;
    line-height: 30px !important;

    text-align: center;
  }
  .section-4-squares .description {
    max-width: 80%;
    margin: 0 auto;

    text-align: center;
  }
  .section-4-squares .button {
    max-width: 100%;
    margin: 20px 0;

    text-align: center;
  }
  .section-4-squares .partner-love-title {
    display: block;

    width: 80%;
    margin: 0 auto;
    padding-bottom: 30px;

    text-align: center;
  }
  .section-givepower {
    margin: 20px 0 !important;
  }
  .section-givepower .paragraph {
    line-height: 185% !important;

    width: 70% !important;
    padding-bottom: 0;
  }
  .section-givepower .title {
    font-size: 300%;
    line-height: 50px !important;
  }
  .section-employees .title-250 {
    font-size: 177% !important;
    line-height: 35px !important;

    margin: 20px 0 !important;
    padding: 0 10px !important;
  }
  .section-testimonials .title {
    font-size: 190% !important;
    line-height: 50px !important;

    margin: 10px 0 !important;
  }

  /* Morgtage page */
  .hero-mortgage {
    margin-top: 10px !important;
  }

  .section-boxes .outside-box {
    padding: 20px 5px !important;
  }
  .section-4-squares .givepower-logo {
    width: 50% !important;
    margin-top: 5px !important;
  }
  .section-4-squares .info-mobile {
    margin: 20px 0;
  }

  .covid-content {
    line-height: 23px;

    padding: 0 3% !important;
  }
  .hero-footer-center {
    font-size: 75% !important;
  }
  .covid-vendor-guidelines {
    margin-top: 20px !important;
    padding-top: 20px !important;
  }
}
@media (min-width: 1000px) {
  /* desktop view */
  main {
    display: grid;

    align-items: center;

    grid-template-columns: 1fr 1fr;
  }
  .section-4-squares .wp-video-mobile,
  .section-together .wp-video-mobile,
  .section-4-squares .info2-mobile,
  .section-4-squares .info-mobile,
  .section-testimonials .claims-mobile,
  .loans-that-change-lives .change-lives-mobile,
  .section-employees .carousel-mobile,
  .section-4-squares .conscious-info-mobile {
    display: none !important;
  }
  .section-4-squares .title {
    font-size: 180%;
    line-height: 25px;
  }
  .section-4-squares .description {
    max-width: 85%;

    text-align: left;
  }
  .section-4-squares .partner-love-title {
    display: none;
  }
}

.section-testimonials .title {
  font-size: 300%;
  line-height: 70px;

  margin: 0 20px;

  letter-spacing: -1px;
}

.section-4-squares .givepower-logo {
  width: 35%;
  margin-top: 20px;
}

.section-4-squares .button {
  margin: 20px 0;
}

.section-4-squares .info2-mobile {
  padding: 50px 0;
}

.section-4-squares .info2-mobile .outer-box {
  width: 100%;
  max-width: 460px;
  margin: 0 auto !important;
}

.section-4-squares section {
  box-shadow: none;
}
.section-4-squares .outer-box {
  margin-right: 15%;
  margin-left: 15%;
}
.section-4-squares .parent {
  display: flex;

  flex-direction: column;
  justify-content: center;

  height: 100%;
}
.section-4-squares .title {
  line-height: 40px;

  margin-bottom: 10px;
}
.section-4-squares .description {
  font-size: 120%;
  line-height: 150%;
}

.section-4-squares {
  width: 100%;
  padding: 0;
  /* -webkit-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75); */
}

.section-4-squares-light-grey {
  background-color: #ebebeb;
}

.section-4-squares-dark-grey {
  background-color: #868686;
}

.section-4-squares-blue {
  background: radial-gradient(#224586, #1d283c);
  background-color: #1d283c;
}

.section-4-squares figure,
.loans-that-change-lives figure {
  margin: 0;
}

.section-4-squares .world-positive img,
.section-4-squares .loans-funded img,
.section-4-squares .conscious-video img,
.section-4-squares .solar-girl img,
.section-4-squares .tamir img,
.section-4-squares .water-man img,
.section-4-squares .partners-love img,
.section-4-squares .trek-video img,
.loans-that-change-lives img {
  display: block;

  width: 100%;
  height: 100%;

  opacity: 1;

  object-fit: cover;
}

.section-4-squares .world-positive img:hover,
.section-4-squares .conscious-video img:hover,
.section-4-squares .partners-love img:hover,
.section-4-squares .trek-video img:hover {
  -webkit-filter: brightness(130%);

/* opacity: 0.8; */
          filter: brightness(130%);
}

.section-together {
  display: flex;

  justify-content: center;
  align-items: center;

  padding: 50px 0 70px 0;
}
.section-together .together-video img {
  width: 100%;

  opacity: 1;

  object-fit: cover;
}

.section-together .together-video img:hover {
  -webkit-filter: brightness(130%);

/* opacity: 0.8; */
          filter: brightness(130%);
}

/* Section solar */
.section-4-squares .title-givepower {
  font-size: 235%;

  text-align: center;
}

.section-4-squares .title-conscious {
  font-size: 120%;

  position: relative;
  right: 20%;

  margin-bottom: 5px;

  text-align: center;
}

.section-4-squares .description-conscious {
  font-size: 120%;
  line-height: 150%;

  text-align: center;
}
.section-4-squares .image-conscious img {
  width: 90%;
}
.section-4-squares .image-conscious {
  margin-bottom: 40px;

  text-align: center;
}

.section-givepower {
  box-shadow: none;
}
.section-givepower .title {
  font-size: 300%;
  line-height: 70px;

  margin-top: 0;
  margin-bottom: 20px;

  letter-spacing: -1px;
}

.section-givepower .paragraph {
  font-size: 122%;
  line-height: 160%;

  width: 57%;
  max-width: 755px;
  margin: 0 auto;
  padding-bottom: 40px;
  padding-bottom: 20px;
}

/* ----------------------------------------------- */
/*  GREEN-ENERGY */
/* ----------------------------------------------- */

.section-green-energy-banner .banner-partners-video {
  position: relative;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  min-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/green-energy/slideshow/play-video-button2.png),
  url(../images/green-energy/slideshow/min/partnersVideoFrame2-min.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

@media (max-width: 375px) {
  .section-3fs {
    margin: 0;
  }
  .section-3fs .title-small-text {
    width: 100%;
  }
  .section-3fs .title-small-text {
    width: 100%;
  }
  .section-3fs .title-phone {
    font-size: 150%;
    line-height: 40px;

    margin-top: 0;
  }
  .section-3fs .title-3 {
    line-height: 115%;

    padding: 10px 0 30px 0;
  }
  .section-3fs .tablets-phones {
    width: 100%;
    margin-top: -25px;
  }
  .section-green-energy {
    padding: 30px 0;
  }
  .section-green-energy-banner .banner-partners-video {
    min-height: 290px;

    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile.jpg);
  }
}

/* section 3fs */
@media (min-width: 377px) and (max-width: 1000px) {
  /* mobile view */
  .section-3fs {
    margin: 0;
  }
  .section-3fs .title-small-text {
    width: 100%;
  }
  .section-3fs .title-small-text {
    width: 100%;
  }
  .section-3fs .title-phone {
    font-size: 150%;
    line-height: 40px;

    margin-top: 0;
  }
  .section-3fs .title-3 {
    line-height: 115%;

    padding: 10px 0 30px 0;
  }
  .section-3fs .tablets-phones {
    width: 90%;
    margin-top: -25px;
  }
  .section-green-energy {
    padding: 30px 0;
  }

  .section-green-energy-banner .banner-partners-video {
    min-height: 670px;

    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile.jpg);
  }

  .section-green-energy-banner .banner-partners-video:hover {
    min-height: 670px;

    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile.jpg) !important;
  }
}

@media (min-width: 1000px) {
  /* desktop view */
  .section-3fs {
    margin-top: 20px;
  }
  .tablets-phones {
    width: 70%;
    margin-top: -20px;
  }

  .section-3fs .title-3 {
    font-size: 240%;
    font-weight: 300;

    width: 70%;
    margin: 0 auto;
  }

  .section-3fs .title-phone {
    font-size: 165%;
    line-height: 45px;

    width: 70%;
    margin: 0 auto;
    margin-top: -150px;
    margin-bottom: 25px;
  }

  .section-3fs .title-small-text {
    font-size: 120%;

    width: 50%;
    margin: 0 auto;
  }
  .section-green-energy {
    padding: 60px 0;
  }
}

/* ----------------------------------------------- */
/*  REFINANCE */
/* ----------------------------------------------- */

.refinance-container .top-orange-bar {
  font-size: 90%;
  line-height: 25px;

  position: relative;
  top: -21px;

  width: 330px;
  height: 29px;
  min-height: 25px;
  margin: 0 auto;

  text-align: center;
  vertical-align: middle;

  color: #ffffff;
  background-color: #717271;
}

.hero-refinance {
  position: relative;
  left: -18%;

  text-align: center;

  color: #aa483d;
}

.hero-refinance h1 {
  font-size: 400%;
  line-height: 80%;

  margin-bottom: 0;

  white-space: nowrap;
  letter-spacing: -2px;
  text-transform: none;

  color: #aa483d;
}

.hero-refinance h2 {
  font-size: 240%;
  line-height: 115%;

  white-space: nowrap;
  letter-spacing: -2px;
  word-spacing: 0;
  text-transform: none;

  color: #aa483d;
}

/* section one */
.section-refinance-one .title {
  font-size: 200%;
  line-height: 45px;

  width: 60%;
  margin: 0 auto;
  margin-top: 0;
  margin-bottom: 20px;

  letter-spacing: 0;
}

.section-refinance-one img {
  width: 105%;
}

.section-refinance-one li {
  font-size: 160%;
  line-height: 25px;

  margin-bottom: 35px;
}

/* section two (piggy bank) */
.section-refinance-two {
  padding: 0;
}

.section-refinance-two .hero-mortgage {
  margin-top: 85px;
}

.section-refinance-two .img-container-hero-mortgage {
  max-height: 400px;
  margin-top: 0;
}

.section-refinance-three .claims {
  width: 60%;
  margin: 0 auto;
}

/* section four (shoes) */

.section-refinance-four {
  min-height: 35vh;

  color: #ffffff;

/* background-image: url(../images/shoes.jpg); */
  background: transparent
  url(https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1595869752/loanpal.com/mortgage/shoesGrey.jpg)
  no-repeat center top;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}

.section-refinance-four img {
  width: 100%;
  height: auto;
}

.section-refinance-four .history {
  display: block;
}

/* section five (our promise) */
.section-refinance-five img {
  width: 35%;
}

.section-refinance-five p {
  width: 80%;
  margin: 0 auto;
}

/* ----------------------------------------------- */
/*  TESTIMONIALS */
/* ----------------------------------------------- */

.testimonials {
  margin-top: 105px;
}
.testimonials h1 {
  font-size: 380%;
  line-height: 120%;

  width: 100%;
  margin: 0 auto;

  text-align: center;
  letter-spacing: 0;
  text-transform: none;

  color: #333333;
  background-color: transparent;
}

.testimonials h2 {
  font-size: 340%;
  line-height: 75%;

  width: 100%;
  margin: 0 auto;
  margin-left: 20%;

  text-align: center;
  letter-spacing: 0;
  text-transform: none;

  color: #333333;
  background-color: transparent;
}

.testimonials-title {
  min-height: 350px;

  text-align: right;

  background-color: #ffffff;
}

.testimonials .container {
  display: grid;

  grid-auto-rows: auto;
  grid-gap: 5px;
  grid-template-areas: 'a b'
  'a b'
  'a b'
  'a b'
  'a b'
  'a b';
  grid-template-columns: 0.25fr 1fr;
}

.testimonials .a {
  grid-area: a;
}

.testimonials .b {
  grid-area: b;
}

.testimonials .grid-item {
  display: flex; /* new */

  justify-content: center; /* new */
  align-items: center; /* new */
}

.testimonials .friend-text {
  margin-top: 8%;
}

.testimonials .friend-text-bottom {
  display: none;
}

.testimonials .friend-title {
  font-size: 120%;

  text-align: right;

  color: #ff8300;
}

.testimonials .friend-quote {
  font-size: 100%;

  margin-top: 20px;

  text-align: right;
}

.testimonials .friend-pic {
  position: relative;

  margin: 0;

  border-color: 5px solid #dfdfdf;
  background-color: #ffffff;
}

.testimonials .photo {
  width: 95%;
}

.testimonials-movie {
  display: none;
}

.testimonials-movie video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;

  cursor: pointer;
}

.fancybox-slide .myVideo {
  width: 90%;
  max-width: 960px;
  height: auto;
}

.fancybox-slide > * {
  margin: 0 !important;
  padding: 5px !important;

  border: 2px solid #ffffff;
}
/* ----------------------------------------------- */
/*  CONTACT US FORM */
/* ----------------------------------------------- */
#contact-us-form {
  max-width: 720px;
  margin-top: 180px !important;
  margin-bottom: 140px !important;
}
/* ----------------------------------------------- */
/*  CONTACT US */
/* ----------------------------------------------- */
.contactus-header {
  margin: 0 auto;
  margin-top: 105px;

  background-color: #eaeaea;
}
.contactus-locations {
  padding: 0;
}
.contactus h1 {
  font-size: 120%;

  margin-bottom: 10px;

  color: #717271;
}

.contactus h2 {
  font-size: 92%;

  text-align: left;

  color: #717271;
}

.contactus h3 {
  font-size: 92%;

  margin-bottom: 5px;

  color: #008996;
}

.contactus .address {
  font-size: 85%;
}

.contactus a:link,
.contactus a:visited {
  text-decoration: none;
  text-transform: none;

  color: #008996;
}

.contactus a:hover,
.contactus a:active {
  color: #f58320;
}

.contactus .container {
  line-height: 27px;

  display: grid;

  margin: 10px 0 0 0;

  grid-auto-flow: column;
  grid-auto-rows: auto;
  grid-gap: 5px;
  grid-template-areas: '. h1 h1 h1 h1 h1 h1 h1 .'
  '. a a a b b b b .'
  '. h2 h2 h2 h2 h2 h2 h2 .'
  '. a1 a1 b1 b1 c1 c1 d1 .'
  '. a2 a2 b2 b2 c2 c2 d2 .'
  '. a3 a3 . . . . . .';
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 0.5fr;
}

.contactus .top {
  padding: 20px 0;

  background-color: #eaeaea;
}

.contactus .h1 {
  text-align: left;

  grid-area: h1;
}

.contactus .h2 {
  margin-top: 30px;

  text-align: left;

  grid-area: h2;
}

.contactus .a {
  text-align: left;

  grid-area: a;
}

.contactus .b {
  text-align: left;

  grid-area: b;
}
.contactus .a1 {
  margin-top: 10px;

  text-align: left;

  grid-area: a1;
}

.contactus .b1 {
  margin-top: 10px;

  text-align: left;

  grid-area: b1;
}
.contactus .c1 {
  margin-top: 10px;

  text-align: left;

  grid-area: c1;
}
.contactus .d1 {
  margin-top: 10px;

  text-align: left;

  grid-area: d1;
}
.contactus .a2 {
  margin-top: 10px;

  text-align: left;

  grid-area: a2;
}
.contactus .b2 {
  margin-top: 10px;

  text-align: left;

  grid-area: b2;
}
.contactus .c2 {
  margin-top: 10px;

  text-align: left;

  grid-area: c2;
}
.contactus .d2 {
  margin-top: 10px;

  text-align: left;

  grid-area: d2;
}
.contactus .a3 {
  margin-top: 10px;

  text-align: left;

  grid-area: a3;
}

.contactus .grid-header {
  display: flex; /* new */

  justify-content: left;
  align-items: left; /* new */
}

.contactus .grid-item {
  display: flex; /* new */

  align-items: flex-end; /* new */
}

/* ----------------------------------------------- */
/*  COMPANY */
/* ----------------------------------------------- */
.profile-bio {
  display: none;

  width: 90%;
  max-width: 1350px;
  height: 90%;
  max-height: 1250px;

/* overflow: hidden !important; */
  /* transform: translateY(-50px);
    transform: translateX(-50px); */

  transition: all 0.7s;

  color: #ffffff;
  border: 5px solid #ffffff !important;
  background-repeat: no-repeat;
  background-position: 0 0;
  -webkit-background-size: 100%;
     -moz-background-size: 100%;
       -o-background-size: 100%;
          background-size: 100%;
}

.profile-bio .fancybox-close-small {
  top: 15px;
}

.profile-bio .content-header {
  padding: 20px;

  border-bottom: 1px solid #eeeeee;
}

.profile-bio .content-scroll {
  overflow: auto;

  max-height: calc(100vh - 200px);
  padding: 40px 20px 0 20px;
}

.profile-bio {
  line-height: 25px;
}

#modal1 {
  line-height: 25px;
}

.morecontent span {
  display: none;

  -webkit-transition: all 0.3s ease 0.15s;
     -moz-transition: all 0.3s ease 0.15s;
      -ms-transition: all 0.3s ease 0.15s;
       -o-transition: all 0.3s ease 0.15s;
          transition: all 0.3s ease 0.15s;
}
.morelink {
  display: block;

  margin-bottom: 20px;
}

.profile-bio-mobile .close-small {
  position: fixed;
  z-index: 10;
  top: 12px;
  right: 35px;

  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;

  cursor: pointer;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;

  color: #ffffff;
  border: 0;
  border-radius: 0;
  background: transparent;
}

.profile-bio-mobile .fa-circle {
  z-index: 9999;

  transition: opacity 0.25s;

  opacity: 0;
  color: #ffffff;
}

.profile-bio-mobile .fa-circle:hover {
  opacity: 0.35;
}

.profile-bio-mobile {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;

  display: none;
  overflow: auto;

  width: 100%;

  transition: all 0.7s;
  text-align: left;

  color: #ffffff;
  border: 5px solid #a9a9a9 !important;
  background-repeat: no-repeat;
  background-position: 0 0;
  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}
/* .profile-bio-mobile::-webkit-scrollbar {
    width:10!important;
/* } */
.profile-bio-mobile .container-right,
.profile-bio .container-right {
  display: grid;

  height: 100%;
  margin-top: 150px;
  margin-right: 20px;

  grid-gap: 5px;
  grid-template-areas: '. desc';
  grid-template-columns: 1fr;
}

.profile-bio-mobile .container-right {
  margin-right: 0;
}

.profile-bio-mobile .container-left,
.profile-bio .container-left {
  display: grid;

  height: 100%;
  margin-top: 150px;
  margin-left: 20px;

  grid-gap: 5px;
  grid-template-areas: 'desc .';
  grid-template-columns: 1fr;
}
.profile-bio-mobile .container-left {
  margin-left: 0;
}

/* #modal2 .container-right {
    margin-top: 15%;
}
#modal3 .container-left {
    margin-top: 18%;
}
#modal4 .container-left {
    margin-top: 18%;
}
#modal5 .container-right {
    margin-top: 23%;
}
#modal6 .container-right {
    margin-top: 23%;
}
#modal7 .container-right {
    margin-top: 19%;
}
#modal8 .container-left {
    margin-top: 23%;
} */

.profile-bio-mobile .profile-bio-img,
.profile-bio .profile-bio-img {
  width: 100%;
  height: 100%;
}

.profile-bio-mobile .profile-bio-name,
.profile-bio .profile-bio-name {
  font-size: 120%;
}

.profile-bio-mobile .profile-bio-title .profile-bio .profile-bio-title {
  font-size: 120%;
}

.profile-bio-mobile .profile-bio-desc,
.profile-bio .profile-bio-desc {
  margin-top: 20px;
}

.profile-bio-mobile .desc,
.profile-bio .desc {
  grid-area: desc;
}

.profile-bio-mobile .grid-item2,
.profile-bio .grid-item2 {
  align-self: center;

  justify-self: center;
}

.hero-company h1 {
  font-size: 340%;
  line-height: 15px;

  min-width: 470px;

  text-transform: none;

  color: #ffffff;
}

.hero-company .join-our-team {
  position: relative;
  top: 721px;

  max-width: 50%;
  margin: 0 auto;
}

.hero-company h3 {
  font-size: 145%;
  line-height: 27px;

  margin-top: 0;
  margin-right: 84px;
  margin-bottom: 20px;

  letter-spacing: 1px;
  word-spacing: 1px;
  text-transform: none;

  color: #ffffff;
}

.hero-company {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  margin-top: 105px;
}
.top-orange-bar-company {
  font-size: 125%;
  line-height: 29px;

/* position: fixed; */

  top: 111px;
  left: 38%;

  width: fit-content;
  height: 35px;
  min-height: 26px;
  margin: 0 auto;
  padding: 0 20px;

  text-align: center;
  vertical-align: middle;

  color: #ffffff;
  background-color: #ff7f00;
}

.hero-company .video-thumbnail {
  min-height: 262px;
}

.hero-company .video-thumbnail {
  position: relative;

  display: block;

  margin-right: auto;
  margin-left: auto;

  cursor: pointer;
}
.hero-company .video-thumbnail:before {
  font-family: FontAwesome;
  font-size: 610%;

  position: absolute;
  z-index: 1;
  top: 490px;
  left: 50%;

  content: '\f01d';
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  opacity: 0.7;
  color: #eeeeee;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.hero-company .video-thumbnail:hover:before {
  opacity: 1;
  color: #ffffff;
}

.values-movie {
  display: none;
}

.about-us-movie video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;

  cursor: pointer;
}

.leadership {
  /* max-width: 1680px;  */
  margin: 0 auto;
}
.leadership .leadership-intro {
  text-align: center;
}

.leadership .line {
  margin: 0 auto;
}
hr.style-eight {
  overflow: visible; /* For IE */

  padding: 0;

  text-align: center;

  color: #333333;
  border: none;
  border-top: medium double #333333;
}
hr.style-eight:after {
  font-size: 1.5em;

  position: relative;
  top: -0.7em;

  display: inline-block;

  padding: 0 0.25em;

  content: ' ';

  background: white;
}
hr.style-six {
  height: 0;

  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
hr.style-seven {
  overflow: visible; /* For IE */

  height: 30px;

  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: black;
  border-radius: 20px;
}
hr.style-seven:before {
  /* Not really supposed to work, but does */
  display: block;

  height: 30px;
  margin-top: -31px;

  content: '';

  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: black;
  border-radius: 20px;
}

.leadership h1 {
  font-size: 220%;
  line-height: 120%;

  position: relative;

  width: 100%;
  min-height: 79px;
  margin: 0 auto;

  text-align: center;
  letter-spacing: 0;
  text-transform: none;

  color: #4e4e4f;
  background-color: transparent;
}

.leadership .photo {
  width: 100%;
}

.leadership .right {
  margin-top: 8%;
}

.leadership .bio-title {
  font-size: 120%;
}

.leadership .bio-description {
  font-size: 100%;

  margin-top: 20px;
}

.leadership .container {
  display: grid;

  grid-auto-rows: auto;
  grid-gap: 15px;
  grid-template-areas: 'a b . .'
  'a b . .'
  'a b c d'
  'a b c d'
  '. . c d'
  '. . c d';
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.leadership .a {
  grid-area: a;
}

.leadership .b {
  grid-area: b;
}
.leadership .c {
  grid-area: c;
}
.leadership .d {
  grid-area: d;
}

.leadership .grid-item {
  display: flex; /* new */

  justify-content: center; /* new */
  align-items: baseline; /* new */
  /* margin: 10px; */
}

.leadership .grid-item-text {
  display: block;

  margin-left: 10px;

  text-align: left;
  /* margin-top: 10px; */
}

.leadership .grid-item-text a:link,
.leadership .grid-item-text a:visited {
  color: #555555;
}

leadership .grid-item-text a:active,
leadership .grid-item-text a:hover {
  text-decoration: none;

  color: #f58320;
}

.leadership .profile-pic {
  position: relative;

  max-width: 380px;
  margin: 0;

  border-color: transparent;

/* background-color:black;     */
  border-radius: 50%;
}
/* .leadership .profile-pic img {
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;

} */

.leadership .profile-pic:hover img {
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
          transition: opacity 0.5s, -webkit-transform 0.5s;
          transition: transform 0.5s, opacity 0.5s;
          transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;

/* opacity: 0.6;  */
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}

.leadership .no-border {
  outline: 0;
}
.leadership .profile-name {
  font-size: 100%;

  position: absolute;
  top: 50%;

  display: none;

  width: 100%;
  margin: 0 auto;

  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);

  color: #ffffff;
}

.leadership .profile-pic:hover .profile-name {
  display: block;
}

.leadership .btn-primary {
  color: #ffffff;
  border-color: #ff5268;
  border-color: var(--main-color);
  background-color: #ff5268;
  background-color: var(--main-color);
}
.leadership .btn {
  font-size: 0.8125rem;
  font-weight: bold;

  padding: 0.375rem 1rem;

  text-transform: uppercase;

  border-radius: 50px;
}

/* ----------------------------------------------- */
/*  HOME IMPROVEMENT */
/* ----------------------------------------------- */

.hero-home-improvement {
  text-align: center;

  color: #ffffff;
}

.hero-home-improvement h1 {
  font-size: 340%;

  width: auto;
  margin-top: 20%;

  white-space: nowrap;
  letter-spacing: -1px;
  text-transform: none;

  color: #ffffff;
}

.hero-home-improvement h3 {
  font-size: 140%;

  width: 440px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;

  letter-spacing: 0;
  word-spacing: 1px;
  text-transform: none;

  color: #ffffff;
}

.section-no-match-page {
  padding: 160px;
}
/* Section Form */

.section-home-improvement-form {
  margin-top: 110px;
  padding-top: 64px;

  color: #ffffff;
  background-color: #008a97;
}

.section-home-improvement-form .title-2 {
  font-size: 140%;
  line-height: 145%;

  width: 70%;
  max-width: 650px;
  margin: 0 auto;
  padding: 10px 0;
}

.section-home-improvement-form .title-3 {
  font-size: 110%;
  line-height: 145%;

  width: 55%;
  max-width: 550px;
  margin: 0 auto;
  padding: 30px 0;
}

.section-home-improvement-form form {
  max-width: 40%;
  margin: 0 auto;
}

/* ----------------------------------------------- */
/*  GREEN ENERGY */
/* ----------------------------------------------- */

.diy-slideshow figure {
  margin: 0;
}

.diy-slideshow .bss-next,
.diy-slideshow .bss-prev {
  display: none;
}

.hero-green-energy {
  position: relative;
  left: -25%;

  width: 50%;
  max-width: 50%;

  text-align: center;

  color: #ffffff;
}

.hero-green-energy h1 {
  font-size: 285%;
  line-height: 40px;

  margin-top: 0;
  margin-bottom: 20px;

  white-space: nowrap;
  letter-spacing: -1px;
  text-transform: none;

  color: #ffffff;
}

.hero-green-energy .in-parenthesis {
  line-height: 50px;

  text-align: center;
}

.hero-green-energy h4 {
  font-size: 184%;
  line-height: 40px;
}

.hero-green-energy img {
  height: 100%;
}

.phone-outlines {
  height: 20%;

  opacity: 0.5;

  filter: alpha(opacity=50); /* For IE8 and earlier */
}

/* section zero-down */

.zero-down {
  width: 36%;
  margin-top: 40px;
  margin-bottom: 20px;
}

/* section how it works */
.section-green-energy-how-it-works {
  border-top: 1px solid #dfdfdf;
}
.section-green-energy-how-it-works {
  padding: 0;
  padding-top: 40px;
  padding-bottom: 80px;

  background-color: #ffffff;
}

.fancy {
  line-height: 0.5 !important;

  text-align: center;
}
.fancy span {
  position: relative;

  display: inline-block;
}
.fancy span:before,
.fancy span:after {
  position: absolute;
  top: 0;

  width: 100%;
  height: 5px;

  content: '';

  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #6a6a6a;
}
.fancy span:before {
  right: 100%;

  margin-right: 15px;
}
.fancy span:after {
  left: 100%;

  margin-left: 15px;
}

.section-green-energy .title-3 {
  font-size: 145%;
}

.section-green-energy-how-it-works .title-3 {
  font-size: 145% !important;

  margin-top: 10px;
  margin-bottom: 30px;

  text-transform: uppercase;
}

.section-green-energy-how-it-works .container {
  display: grid;

  max-width: 1400px;
  margin: 0 auto;

  grid-auto-rows: auto;
  grid-gap: 40px;
  grid-template-areas: '. a  b  c .';
  grid-template-columns: 0.5fr 1fr 1fr 1fr 0.5fr;
}

.section-green-energy-how-it-works .icon-pic img {
  max-width: 150px;
}

.section-green-energy-how-it-works .step {
  margin-bottom: 20px;

  text-align: center;
}

.section-green-energy-how-it-works .step-label {
  color: #3e98a3;
}

.section-green-energy-how-it-works .step-description {
  line-height: 27px;

  text-align: justify;
}

.section-green-energy-how-it-works .a {
  justify-content: center;

/* text-align: left; */

  padding-right: 40px;

  border-right: 1px solid #333333;

  grid-area: a;
}

.section-green-energy-how-it-works .b {
  justify-content: center;

/* text-align: left; */

  padding-right: 40px;

  border-right: 1px solid #333333;

  grid-area: b;
}

.section-green-energy-how-it-works .c {
  justify-content: center;

/* text-align: left; */

  padding-right: 40px;

  border-right: 1px solid #333333;

  grid-area: c;
}

.section-green-energy-how-it-works .grid-item {
  align-items: center; /* new */
  /* justify-content: center;  */
}

/*section banner */
.section-green-energy-banner {
  padding: 0;

  background-color: #ffffff;
}

.section-green-energy-banner .banner-button-display {
  display: none;

  margin: 0;
}

.section-green-energy-banner .banner-button-img {
  width: 200px;

  cursor: pointer;
}

.section-green-energy-banner .banner-force-height {
  height: 550px !important;
}

.section-green-energy-banner .banner-partners-video-main:hover {
  background-image: url(../images/green-energy/slideshow/play-video-button-hover2.png),
  url(../images/green-energy/slideshow/min/partnersVideoFrameMain-min.png);
}

.section-green-energy-banner .banner-partners-video-main {
  position: relative;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/green-energy/slideshow/play-video-button2.png),
  url(../images/green-energy/slideshow/min/partnersVideoFrameMain-min.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.section-green-energy-banner .banner-partners-video:hover {
  background-image: url(../images/green-energy/slideshow/play-video-button-hover2.png),
  url(../images/green-energy/slideshow/min/partnersVideoFrame2-min.png);
}

.section-green-energy-banner .banner-snails {
  position: relative;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/green-energy/slideshow/sign-me-up-button.png),
  url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.section-green-energy-banner .banner-snails:hover {
  background-image: url(../images/green-energy/slideshow/sign-me-up-button-hover.png),
  url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
}

.section-green-energy-banner .banner-working-capital {
  position: relative;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/green-energy/slideshow/learn-more-button.png),
  url(../images/green-energy/slideshow/min/workingCapital-min.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.section-green-energy-banner .banner-working-capital:hover {
  background-image: url(../images/green-energy/slideshow/learn-more-button-hover.png),
  url(../images/green-energy/slideshow/min/workingCapital-min.png);
}

.section-green-energy-banner .banner-giraffe {
  position: relative;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/green-energy/slideshow/lets-go-button.png),
  url(../images/green-energy/slideshow/min/Giraffe_creditLimit-min.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.section-green-energy-banner .banner-giraffe:hover {
  background-image: url(../images/green-energy/slideshow/lets-go-button-hover.png),
  url(../images/green-energy/slideshow/min/Giraffe_creditLimit-min.jpg);
}

.section-green-energy-banner .banner-chameleon {
  position: relative;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/green-energy/slideshow/lets-go-button.png),
  url(../images/green-energy/slideshow/min/integrationChameleon-min.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.section-green-energy-banner .banner-chameleon:hover {
  background-image: url(../images/green-energy/slideshow/lets-go-button-hover.png),
  url(../images/green-energy/slideshow/min/integrationChameleon-min.jpg);
}

/* section feedback */
.section-green-energy-feedback {
  padding: 0;

  background-color: #ffffff;
}

.section-green-energy-feedback .title-3 {
  font-size: 200%;

  margin-top: 10px;
  margin-bottom: 30px;
}

.section-green-energy-feedback .container {
  display: grid;

  grid-auto-rows: auto;
  grid-gap: 10px;
  grid-template-areas: '. a b .';
  grid-template-columns: 0.25fr 1fr 1fr 0.25fr;
}

.section-green-energy-feedback .a {
  justify-content: center;

  text-align: right;

  grid-area: a;
}

.section-green-energy-feedback .b {
  margin-left: 20px;

  text-align: left;

  grid-area: b;
}

.section-green-energy-feedback .grid-item {
  display: flex; /* new */

  align-items: center; /* new */
  /* justify-content: center;  */
}

/* section-process */

.section-process .phone-outlines-img {
  text-align: right;
}

.section-process .phone-outlines-text {
  text-align: left;
}

.section-process .title-2 {
  font-size: 150%;

  width: 75%;
  margin: 0;
  padding: 42px 0;

  vertical-align: middle;
}

.section-process .title-approval {
  font-size: 120%;

  max-width: 565px;
  margin: 0 auto;
  padding: 10px 0;
}

.section-process .title-approval-more a {
  font-size: 120%;

  margin: 0 auto;
  padding: 10px 0;

  color: #ff7f00 !important;
}

/* Customers section */
.section-customers {
  color: #ffffff;
  border-top: 5px solid #f7b233;
  background-image: url(../images/bluetexturebg.jpg);
  background-position: center;
  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}

.section-customers .title-customers {
  font-size: 160%;

  margin: 0 auto;
  padding: 20px 0;

  text-align: left;
  vertical-align: middle;
}

.section-customers .solar-block {
  width: 80%;
}

.form-group {
  margin-bottom: 0.7rem;

  text-align: left;
}

.form-group label {
  line-height: 1.6rem;

  display: block;
}

.form-group input {
  font-size: 110%;

  position: relative;

  display: block;

  width: 100%;
  height: 2rem;

/* max-width: 90%; */
  padding: 0.7rem 0.8rem;

  color: #333333;
  border: 0.1rem solid #c5c5c5;
  border-radius: 0.3rem;
  background: #ffffff;
}

.form-group input[type='checkbox'] {
  font-size: 80%;

  position: relative;

  display: block;
  float: left;

  width: 100%;
  max-width: 40px;
  height: 1rem;
  margin-top: 5px;
  padding: 0.5rem;

  color: #333333;
  border: 0.1rem solid #c5c5c5;
  border-radius: 0.3rem;
  background: #ffffff;
}

.input-checkbox {
  margin-top: 30px;
}
.input-checkbox-label {
  text-align: left;
}

.form-group textarea {
  font-size: 100%;

  position: relative;

  display: block;

  width: 100%;
  max-width: 100%;
  height: 4rem;
  padding: 0.7rem 0.8rem;

  color: #333333;
  border: 0.1rem solid #c5c5c5;
  border-radius: 0.3rem;
  background: #ffffff;
}

/* Preferred Lenders */
.section-green-energy {
  border-top: 1px solid #dfdfdf;
}

.section-green-energy .preferred-lender {
  width: 35%;
}

.section-green-energy .partner-logos {
  width: 100%;
}

#inquiry-form-partner .title-partners {
  font-size: 110%;

  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

/* Partners section */
.section-partners {
  color: #ffffff;
  border-top: 5px solid #ff7f00;
  background-color: #f7b233;
}

.section-partners .title-partners {
  font-size: 160%;

  width: 75%;
  margin: 0 auto;
  padding: 42px 0;

  vertical-align: middle;
}

.section-green-energy .card {
  display: grid;

  grid-auto-rows: auto;
  grid-gap: 20px;
  grid-template-areas: '. a b .';
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
}

.section-green-energy .card-body {
  background-color: #ffffff;
}

.section-green-energy .a {
  grid-area: a;
}

.section-green-energy .b {
  grid-area: b;
}

.section-green-energy .card-text-description {
  width: 80%;
  min-height: 145px;
  margin: 0 auto;
}

ul.arrow {
  list-style-image: url('../images/green-energy/orangeArrow.svg');
}

.section-proven-leadership {
  background-color: #eeeeee;
}

.leadership-title {
  font-size: 225%;
  font-weight: 300;
  line-height: 70px;
}

.leadership-description {
  font-size: 125%;
  line-height: 28px;

  width: 65%;
  margin: 0 auto;
  margin-bottom: 35px;
}

.section-green-energy .bios {
  display: grid;

  grid-auto-rows: auto;
  grid-gap: 20px 0;
  grid-template-areas: '. bioa biob bioc biog . '
  '. biod bioe biof bioh .';
  grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1.3fr;
}

.section-green-energy .bioa {
  grid-area: bioa;
}

.section-green-energy .biob {
  grid-area: biob;
}
.section-green-energy .bioc {
  grid-area: bioc;
}
.section-green-energy .biod {
  grid-area: biod;
}
.section-green-energy .bioe {
  grid-area: bioe;
}
.section-green-energy .biof {
  grid-area: biof;
}
.section-green-energy .biog {
  grid-area: biog;
}
.section-green-energy .bioh {
  grid-area: bioh;
}

.section-green-energy .bios img {
  width: 100%;
}

.section-green-energy .bios ul {
  width: 85%;
  margin: 0 auto;

  text-align: left;
}

.section-green-energy .bio-text-title {
  font-size: 78%;
}
.section-green-energy .bio-text-list {
  font-size: 70%;
}

.section-green-energy-feedback .feedback-quote {
  font-size: 90%;
  line-height: 25px;
}

.section-green-energy-feedback .feedback-text {
  width: 45%;
}

.section-green-energy-feedback .feedback-signature {
  font-size: 95%;
}

.section-green-energy-feedback .video-thumbnail img {
  width: 90%;
}

/* ----------------------------------------------- */
/* DIRECT PAY */
/* ----------------------------------------------- */

.section-green-energy-banner .banner-direct-pay {
  position: relative;

  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;

          justify-content: center;
          align-items: center;

  max-height: 500px;

  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.5s ease-in-out;
  text-align: center;

  background-color: #ffffff;
  background-image: url(../images/direct-pay/panels-button-off.png),
  url(../images/direct-pay/panels-min.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
}

.section-green-energy-banner .banner-direct-pay:hover {
  background-image: url(../images/direct-pay/panels-button-on.png),
  url(../images/direct-pay/panels-min.png);
}

/* ----------------------------------------------- */
/* SECTION MORTGAGE */
/* ----------------------------------------------- */

.hero-mortgage {
  margin-top: 122px;

  color: #ffffff;
}
.hero-mortgage h1 {
  font-size: 280%;
  line-height: 32px;

  margin-top: 0;
  margin-bottom: 20px;

  letter-spacing: -1px;
  text-transform: none;

  color: #ffffff;
}

.section-mortgage-one {
  min-height: 650px;
}

.section-mortgage-one h3 {
  font-size: 130%;
}

.section-mortgage-two img {
  width: 100%;
}

.section-mortgage-three {
  min-height: 295px;

  color: #ffffff;
  border-top: 5px solid #ff7f00;
  background-image: url(../images/bluetexturebg.jpg);
  background-position: center;
  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}

.section-mortgage-three .mortgage-rates {
  max-width: 70%;
  margin-top: 40px;
}

.section-mortgage-three .disclaimer-rates {
  font-size: 75%;

  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
}

.section-mortgage-three .term {
  font-family: 'Conv_HVD Fonts - BrandonGrotesque-Bold', sans-serif;
  font-size: 400%;

  margin: 0 auto;
}

.section-mortgage-three .long-width {
  max-width: 165px;
  height: 70px;
}

.section-mortgage-three .short-width {
  max-width: 120px;
  height: 72px;
}

.section-mortgage-three .term ul {
  position: relative;

/* top: 52px; */
  left: 10px;

  float: right;

  list-style: none;
}

.section-mortgage-three .term li {
  font-family: 'Conv_HVD Fonts - BrandonGrotesque-Thin';
  font-size: 21%;
  font-weight: 500;

  margin-top: -5px;
}

.section-mortgage-three .rate-details {
  font-family: 'Conv_HVD Fonts - BrandonGrotesque-Thin';
  font-size: 88%;

/* margin-top: -15px; */
  font-weight: 500;

  white-space: nowrap;
}

.section-mortgage-three .border-right {
  border-right: 1px solid #ffffff;
}

.more-rates a {
  font-size: 110%;

  letter-spacing: 1px;
}

.more-rates a:link,
.more-rates a:visited {
  color: #ffffff;
}

.more-rates a:active,
.more-rates a:hover {
  text-decoration: none;

  color: #f58320;
}

/* ----------------------------------------------- */
/* SECTION KNOW YOUR LOAN */
/* ----------------------------------------------- */

.financier-paragraph {
  font-size: 142%;
  line-height: 130%;

  width: 57%;
  max-width: 755px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.see-rates {
  font-size: 120%;

  float: right;

  width: 140px;
  height: 100px;
  margin-top: 50%;
  padding-top: 5%;

  cursor: pointer;
  text-transform: uppercase;

  color: #ffffff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #ff7f00;
}

.see-rates:hover {
  background-color: #f7b334;
}

.see-rates .arrow {
  font-size: 155%;
}

/* ----------------------------------------------- */
/* SECTION PAPERWORK */
/* ----------------------------------------------- */

#paperwork {
  margin-top: 10px;
}
.section-hero-image {
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0;

  overflow: auto;

  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
}

.section-paperwork h1 {
  font-size: 280%;
  line-height: 64px;

  position: absolute;
  left: 15%;

  margin-top: 52px;

  text-align: center;
  text-transform: none;
}

.section-paperwork .video-thumbnail {
  min-height: 262px;
}

.video-thumbnail {
  position: relative;

  display: block;

  margin-right: auto;
  margin-left: auto;

  cursor: pointer;
}
.video-thumbnail:before {
  font-family: FontAwesome;
  font-size: 610%;

  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;

  content: '\f01d';
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  opacity: 0.7;
  color: #eeeeee;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.video-thumbnail:hover:before {
  opacity: 1;
  color: #ffffff;
}

.section-paperwork .arrow {
  position: absolute;
}

.about-us-movie {
  display: none;
}

.about-us-movie video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;

  cursor: pointer;
}
/* ----------------------------------------------- */
/* SECTION A FRIEND ALWAYS HAS YOUR BACK */
/* ----------------------------------------------- */

.gray-background {
  background-color: #f2f2f2;
}

.section-why-loanpal .title {
  font-size: 200%;
  line-height: 35px;

  margin-top: 40px;
  margin-bottom: 15px;
}

.section-why-loanpal .title-2 {
  font-size: 150%;
}

.section-why-loanpal .title-4 {
  line-height: 35px;

  width: 100%;

  white-space: nowrap;
}

.section-why-loanpal .js--wp-1 {
  max-width: 1920px;
}

.section-why-loanpal .pomp img {
  width: 42%;
  max-width: 630px;
  height: auto;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 20px;
}

.section-why-loanpal h3 {
  font-size: 120%;
  font-weight: 500;
}

.section-why-loanpal p {
  font-size: 110%;
  line-height: 28px;

  max-width: 440px;
  margin: 0 auto;

  color: #008996;
}

.section-why-loanpal .benefits-smileys .box {
  position: relative;
  top: -55px;
}

.section-why-loanpal .box {
  position: relative;
}

.section-why-loanpal .smileys {
  width: 80%;
  min-width: 180px;
  max-width: 220px;
}

.section-why-loanpal .get-started {
  padding: 10px 0 50px 0;

  text-transform: uppercase;
}

/* ----------------------------------------------- */
/* SECTION EXPERIENCE */
/* ----------------------------------------------- */

.section-experience {
  /* max-width: 1900px;
    margin: 0 auto;  */
  z-index: 0;

  min-height: 550px;

  color: #ffffff;
  border-top: 5px solid #717271;
  background-image: url(../images/shoes_00.jpg);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}

.section-experience .history {
  z-index: 60;

  height: 229px;

  opacity: 1;
  background: transparent url(../images/historyV4White.svg) no-repeat center top;
  background-size: 1440px 308px;

  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      filter: alpha(opacity=100);
}

.section-experience .stat-text {
  font-size: 42px;
  line-height: 22px;

  float: left;

  margin-top: -15px;
  margin-left: 5px;

  letter-spacing: -2px;
}

.section-experience .stat-text-2 {
  font-size: 25px;

  float: left;

  margin-left: 5px;
}

.section-experience p {
  font-size: 260%;
  line-height: 48px;

  position: relative;

  width: 100%;
  min-height: 79px;
  margin: 0 auto;

  text-align: center;
  letter-spacing: -1px;

  color: #ffffff;
  background-color: transparent;
}

.section-experience .sign {
  line-height: 14px;

  float: right;

  margin-top: -20px;
}

.section-experience .stat {
  font-size: 500%;
  line-height: 15px;

  letter-spacing: -3px;

  color: #ffffff;
}

/* ----------------------------------------------- */
/* RESOURCES */
/* ----------------------------------------------- */

.section-resources-header {
  z-index: 0;

/* border-top: 5px solid #717271; */

  min-height: 420px;
  margin-top: 105px;

  color: #ffffff;
  background-image: url(../images/piggyresource.jpg);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}
.hero-resources-div {
  position: relative;
  left: -30%;

  display: table;

  width: 40%;
  height: 200px;
}
.hero-resources {
  line-height: 55px;

  display: table-cell;

  text-align: center;
  vertical-align: middle;

  color: #ffffff;
}

.section-resources-header h1 {
  letter-spacing: -1px;
  text-transform: none;
}

.section-boxes .outside-box {
  width: 100%;
  padding: 20px;

  background-color: #f4f4f4;
}

.section-boxes article {
  font: 16px/1.5px 'Conv_HVD Fonts - BrandonGrotesque-Regular', Sans-Serif;
}

.section-boxes .box-right {
  padding: 0 10px;
}

.section-boxes img {
  float: left;

  width: 30%;
  min-width: 200px;
  max-width: 100%;
  height: auto;
  margin-right: 15px;
  margin-bottom: 10px;
}

.section-boxes .box-right h3 {
  font-size: 155%;
  line-height: 38px;

  text-transform: none !important;

  color: #000000;
}

.section-boxes .row {
  display: flex;
  /* max-width: 1200px; */
}

.section-boxes article {
  line-height: 25px;
  /* max-height: 12em; */
}

.section-boxes ul li {
  margin-left: 5%;

  list-style-type: disc;
}

.section-boxes .btn-sm:link,
.section-boxes .btn-sm:visited {
  font-size: 55%;
  font-weight: 300;

  display: inline-block !important;

  width: auto !important;
  margin-left: 35px;
  padding: 0 10px;

  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
          transition: background-color 0.2s, border 0.2s, color 0.2s;
  text-decoration: none;
  text-transform: uppercase;

  border-radius: 15px;
}

.section-boxes .btn-ghost-grey:link,
.section-boxes .btn-ghost-grey:visited {
  color: #717271;
  border: 2px solid #717271;
}

.section-boxes .btn-ghost-grey:hover,
.section-boxes .btn-ghost-grey:active {
  color: #ffffff;
  border: 2px solid #f58320;
  background-color: #f58320;
}

/* ----------------------------------------------- */
/* SECTION TESTIMONIALS */
/* ----------------------------------------------- */

.section-testimonials {
  min-height: 425px;

  color: #008996;
}
.section-testimonials .group-picture {
  display: block;

  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.section-testimonials .profile-pic {
  position: relative;

  width: 90%;
  max-width: 1334px;
  margin: 0 auto;

  border-color: transparent;
  background-color: #292929;
}

.section-testimonials .profile-pic:hover img {
  opacity: 0.3;
}

.no-border {
  outline: 0;
}
.section-testimonials .profile-name {
  font-size: 130%;

  position: absolute;
  top: 50%;

  display: none;

  width: 100%;
  margin: 0 auto;

  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);

  color: #ffffff;
}

.section-testimonials .profile-name i {
  vertical-align: middle;
}

.section-testimonials .profile-pic:hover .profile-name {
  display: block;
}

.section-testimonials blockquote {
  font-size: 110%;
  font-weight: 500;
  line-height: 145%;

  position: relative;

/* max-width: 380px;  */

  margin: 0 auto;
  margin-top: 20px;
  padding: 2%;
}

.section-testimonials .view-customers-stories {
  margin-top: 0;
}

cite {
  font-size: 95%;
  font-style: normal;

  display: block;

  margin-top: 25px;
  margin-right: 10px;

  text-align: right;
}

.section-testimonials .customer {
  height: 195px;
  margin-right: 10px;

  vertical-align: middle;

  border: 5px solid #ffffff;
  border-radius: 50%;
}

.section-testimonials .percentage {
  font-size: 325%;

  vertical-align: super;

  color: #aa483d;
}

.section-testimonials .bubble .percentage span {
  font-size: 300%;

  vertical-align: super;
}

.section-testimonials .smile {
  width: 30%;
}

.section-testimonials .quotes-row {
  width: 90%;
  max-width: 1500px;
}

.section-testimonials .claims {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  /* Mobile view */
  .section-testimonials .claims-mobile {
    display: block;

    width: 100%;
    margin: 0 auto;
  }
  .section-testimonials .claims {
    display: none !important;
  }
}
@media (min-width: 1000px) {
  /* Desktop View */
  .section-testimonials .claims-mobile {
    display: none !important;
  }
}

.view-more {
  border: 1px solid #008996 !important;
  background-color: #008996 !important;
}

.view-more i {
  vertical-align: -6px;
}

.view-more:hover {
  border: 1px solid #f3a529 !important;
  background-color: #f3a529 !important;
}

/* ----------------------------------------------- */
/* SECTION tricks */
/* ----------------------------------------------- */

.section-no-secrets {
  margin-top: -20px;
  margin-bottom: -30px;
}

/* ----------------------------------------------- */
/* SECTION business journal */
/* ----------------------------------------------- */

/* ----------------------------------------------- */
/* FOOTER PAGES */
/* ----------------------------------------------- */
.hero-footer-center {
  line-height: 35px;

  position: relative;
  left: 0 !important;

  max-width: 100% !important;

  text-align: center;

  color: #ffffff;
}
.hero-footer {
  position: relative;
  left: -30%;

  max-width: 44%;

  text-align: center;

  color: #ffffff;
}
.spacer-160 {
  padding: 160px 0;
}
.hero-footer h1 {
  font-size: 300%;
  line-height: 60px;

  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 45px;

  letter-spacing: 2px;
  word-spacing: 2px;
  text-transform: none !important;
  text-transform: none;

  color: #ffffff;
}
.green-header {
  background: url(../images/dog_background.png),
  linear-gradient(#7ba658, transparent),
  linear-gradient(to top left, #2b3b1d, transparent),
  linear-gradient(to top right, rgb(175, 240, 122), transparent);

  background-blend-mode: normal;
}

.purple-header {
  background: url(../images/dog_background.png),
  linear-gradient(#2f006d, transparent),
  linear-gradient(to top left, #240053, transparent),
  linear-gradient(to top right, #9b53fa, transparent);

  background-blend-mode: normal;
}

.orange-header-surprised-girl {
  max-height: 340px;

  background: url(../images/surprised-girl.png),
  linear-gradient(#f58320, transparent),
  linear-gradient(to top left, #f09647, transparent),
  linear-gradient(to top right, #ff7700, transparent);
  background-repeat: no-repeat;
  background-position-x: 80%;

  background-blend-mode: normal;
}

.orange-header {
  background: url(../images/dog_background.png),
  linear-gradient(#f58320, transparent),
  linear-gradient(to top left, #f09647, transparent),
  linear-gradient(to top right, #ff7700, transparent);

  background-blend-mode: normal;
}

.yellow-header {
  background: url(../images/dog_background.png),
  linear-gradient(rgb(245, 195, 32), transparent),
  linear-gradient(to top left, #f0d747, transparent),
  linear-gradient(to top right, #fff700, transparent);

  background-blend-mode: normal;
}

.grey-header {
  background: url(../images/dog_background.png),
  linear-gradient(#282828, transparent),
  linear-gradient(to top left, #5f5f5f, transparent),
  linear-gradient(to top right, #949494, transparent);
}

.creme-header {
  background-color: #f4eae1;
}

.section-footer-pages {
  font-size: 85%;

  min-height: 500px;
  padding: 10px 5px;

  text-align: left;
}

.img-social-zillow {
  display: block;

  width: 56px;
  height: 45px;

  background: url(../images/zillow.png) top/56px;
  background-size: 56px 90px;
}

.img-social-zillow:hover {
  background-position: 0 45px;
}

/* ----------------------------------------------- */
/* CALCULATOR */
/* ----------------------------------------------- */
.calculator {
  min-height: 500px;

  text-align: center;
}
.slider-row {
  width: 65%;
  margin: 0 auto;
  padding-top: 20px;
}
.slider {
  margin: 0 20px;
}
.calculator label {
  text-align: left !important;
}
.calculator .payment {
  font-size: 280%;
  line-height: 50px;

  color: #000000;
}
.calculator .chart {
  max-width: 100%;
  max-height: 100%;
  margin-top: 35px;
}
.calculator .slider-container {
  min-height: 180px;

  vertical-align: top;
}
.assumptions {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 20px;

  border: 1px solid #eeeeee;
  background-color: #ededed;
}

.assumptions .input-box {
  width: 90%;
}

.input-box {
  font-size: 135%;

  padding: 3px;

  text-align: center;

  border: 2px solid #aaaaaa;
  background-color: #ffffff;
}

.calculator .input-box {
  margin-top: 20px;
}
.assumptions .disable {
  color: #c7c7c7;
}
.calculator .form-label {
  margin-bottom: 20px;
}

input[type='range'] {
  width: 100%; /* Specific width is required for Firefox. */

  background: transparent; /* Otherwise white in Chrome */

  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-ms-track {
  width: 100%;

  cursor: pointer;

  color: transparent;
  border-color: transparent;

/* Hides the slider so custom styles can be added */
  background: transparent;
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  width: 36px;

/* border: 1px solid #818181; */
  height: 36px;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

  cursor: pointer;

  border-radius: 50%;
  background: #ff7703;
  background-image: url(../images/slider.png),
  -webkit-gradient(linear, left top, left bottom);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 38px;
  box-shadow: 1px 1px 1px #818181, 0 0 1px #818181; /* Add cool effects to your sliders! */

  -webkit-appearance: none;
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  width: 36px;
  height: 36px;

  cursor: pointer;

  border: 1px solid #000000;
  border-radius: 13px;
  background: #ffffff;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  width: 36px;
  height: 36px;

  cursor: pointer;

  border: 1px solid #000000;
  border-radius: 13px;
  background: #ffffff;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;

  cursor: pointer;

  border: 0.2px solid #010101;
  border-radius: 1.3px;
  background: #ff7703;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ff7703;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 8.4px;

  cursor: pointer;

  border: 0.2px solid #010101;
  border-radius: 1.3px;
  background: #ff7703;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 8.4px;

  cursor: pointer;

  color: transparent;
  border-width: 16px 0;
  border-color: transparent;
  background: transparent;
}
input[type='range']::-ms-fill-lower {
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  background: #fd8c2f;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type='range']:focus::-ms-fill-lower {
  background: #ff7703;
}
input[type='range']::-ms-fill-upper {
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  background: #ff7703;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type='range']:focus::-ms-fill-upper {
  background: #ff7703;
}

/* ----------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------- */

footer {
  font-size: 80%;

  max-width: 1920px;
  margin: 0 auto;
  padding: 20px;

  background-color: #333333;
}

.footer-nav {
  list-style: none;

  text-align: center;
}

.credits-images,
.social-links {
  float: right;

  list-style: none;

  white-space: nowrap;
}

.credits-images .aicpa-soc {
  width: 120px;
  margin-right: 25px;
}

.credits-images li,
.social-links li {
  display: inline-block;

  margin-right: 20px;
}

.credits-images li .aicpa-soc {
  float: left;

  width: 120px;
  margin-right: 20px;
}

.footer-nav li:last-child,
.social-links li:last-child {
  margin-right: 0;
}

.footer-nav li a:link,
.footer-nav li a:visited,
.social-links li a:link,
.social-links li a:visited {
  -webkit-transition: color 0.2s;
          transition: color 0.2s;
  white-space: nowrap;
  text-decoration: none;

  color: #c3c3c3;
  border: 0;
}

.footer-nav li a:hover,
.footer-nav li a:active {
  color: #ffffff;
}

.credits-images {
  width: 100%;
}

.credits-images img {
  max-width: 100%;
}

.social-links li a:link,
.social-links li a:visited {
  font-size: 400%;
}

.ion-social-facebook,
.ion-social-twitter,
.ion-social-googleplus,
.ion-social-linkedin,
.ion-social-instagram {
  -webkit-transition: color 0.2s;
          transition: color 0.2s;
}

.ion-social-facebook:hover {
  color: #3b5998;
}

.ion-social-twitter:hover {
  color: #00aced;
}

.ion-social-googleplus:hover {
  color: #dd4b39;
}

.ion-social-instagram:hover {
  color: #c13584;
}

.ion-social-linkedin:hover {
  color: #0077b5;
}

footer p {
  font-size: 95%;
  line-height: 18px;

  margin-top: 20px;

  text-align: left;

  color: #888888;
}

.footer-nav .btn-middle {
  text-align: center;
}

.footer-nav .btn-ghost-white:link,
.footer-nav .btn-ghost-white:visited {
  color: #ffffff;
  border: 3px solid #ffffff;
}

.footer-nav .btn-ghost-white:hover,
.footer-nav .btn-ghost-white:active {
  color: #f58320;
  background-color: #ffffff;
}

.footer-nav .btn-lg:link,
.footer-nav .btn-lg:visited {
  font-size: 160%;
  font-weight: 300;

  display: inline-block;

  margin-left: 35px;
  padding: 2px 10px;

  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
          transition: background-color 0.2s, border 0.2s, color 0.2s;
  text-decoration: none;
  text-transform: uppercase;

  border-radius: 25px;
}

#footer-nav-mobile {
  display: none;
  visibility: hidden;

  padding: 0 !important;
}

/* ----------------------------------------------- */
/* Fancybox */
/* ----------------------------------------------- */

.fancybox-custom-layout .fancybox-outer {
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}

.fancybox-slide .fancybox-image-wrap {
  border: 5px solid #ffffff !important;
}

/*
   Styles for animated modal
   =========================
*/

/* Start state */
.rates-modal {
  overflow: hidden !important;

  width: 950px;
  max-width: 950px;

  transition: all 0.7s;
  transform: translateY(-50px);

  border-radius: 4px;
}

.rates-modal .top-orange-bar {
  top: -4px;
}

.rates-modal .fancybox-close-small {
  color: #ffffff;
}

.rates-modal .rate-details {
  width: 184px;
  margin: 0 auto;
}

.rates-modal h2,
.rates-modal p {
  transition-duration: 0.4s;
  transition-property: transform, opacity;
  transform: translateY(-50px);

  opacity: 0;
}

/* Final state */
.fancybox-slide--current .rates-modal,
.fancybox-slide--current .rates-modal h2,
.fancybox-slide--current .rates-modal p {
  transform: translateY(0);

  opacity: 1;
}

/* Reveal content with different delays */
.fancybox-slide--current .rates-modal h2 {
  transition-delay: 0.1s;
}

.fancybox-slide--current .rates-modal p {
  transition-delay: 0.3s;
}

/* ----------------------------------------------- */
/* FAQs */
/* ----------------------------------------------- */
.section-steps {
  text-align: left;

  background-color: #ffffff;
}

.works-step {
  margin-bottom: 10px;
  margin-left: 5%;
}

.works-step div {
  font-size: 150%;

/* border: 2px solid #F06E1A; */

  display: inline-block;

  width: 55px;
  height: 55px;
  margin-right: 25px;
  padding: 5px;

  text-align: center;

  color: #f06e1a;
  border-radius: 50%;
  background-image: url(../images/smiley.png);
  background-repeat: no-repeat;
  background-size: 55px;
}
.works-step span.smiley {
  position: relative;
  top: 10px;

  display: inline-block;

  text-align: center;
}

.works-step span.smiley img {
  width: 40px;
}

.works-step p {
  font-size: 150%;

  padding-top: 1%;

  text-align: left;
}

.works-question {
  font-size: 100%;
  font-weight: 500;

  margin-bottom: 10px;
  margin-left: 10%;

  list-style-type: disc;

  text-transform: uppercase;
}

.works-question ul li {
  list-style-type: disc;
}

.works-answer ul li {
  list-style-type: circle;
}

.works-answer .long-copy {
  line-height: 32px;

  width: 70%;
  margin-left: 0;
}

.works-answer {
  font-size: 100%;
  font-weight: 400;

  margin-bottom: 10px;
  margin-left: 13%;
}
.works-answer ul li {
  list-style-type: circle;
}

.works-sub-answer .long-copy {
  line-height: 32px;

  width: 70%;
  margin-left: 0;
}

.works-sub-answer {
  font-size: 100%;
  font-weight: 400;

  margin-bottom: 10px;
  margin-left: 16%;
}
.works-sub-answer ul li {
  list-style-type: upper-alpha;
}

/* ----------------------------------------------- */
/* Snackbar */
/* ----------------------------------------------- */
.snackbar {
  font-size: 1rem;

  position: fixed;
  z-index: 10011;
  bottom: 30px;
  left: 50%;

  visibility: hidden;

  min-width: 250px;
  margin-left: -125px;
  padding: 16px;

  text-align: center;

  color: #ffffff;
  border-radius: 2px;
  background-color: #f58320;
}

.snackbar-success {
  background-color: #43a047;
}

.snackbar-info {
  background-color: #1876d2;
}

.snackbar-warning {
  background-color: #ffa000;
}

.snackbar-error {
  background-color: #d32f2f;
}

.snackbar.show {
  visibility: visible; /* Show the snackbar */

/* Add animation: Take 0.5 seconds to fade in and out the snackbar.
   However, delay the fade out process for 2.5 seconds */

  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
          animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;

    opacity: 0;
  }
  to {
    bottom: 30px;

    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;

    opacity: 0;
  }
  to {
    bottom: 30px;

    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;

    opacity: 1;
  }
  to {
    bottom: 0;

    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;

    opacity: 1;
  }
  to {
    bottom: 0;

    opacity: 0;
  }
}

/* ----------------------------------------------- */
/* Animations */
/* ----------------------------------------------- */

/*
.js--wp-1,
.js--wp-2,
.js--wp-3
{
    opacity: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.js--wp-4 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.js--wp-1.animated,
.js--wp-2.animated,
.js--wp-3.animated {
    opacity: 1;
}
*/

/* ----------------------------------------------- */
/* html5lightbox */
/* ----------------------------------------------- */

.lightboxcontainer {
  display: block;
  overflow: auto;

/* width: 900px;
    height: 675px; */

  max-height: 675px;

  text-align: left;

/* position: fixed; */

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}
/* .lightboxcontainer {
    display: block;
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 900px;
    height: 675px;
  } */
.lightboxleft {
  line-height: 27px;

  float: left;

  width: 50%;
  padding: 20px;

  color: #ffffff;
}
.lightboxright {
  line-height: 27px;

  float: right;

  width: 50%;
  padding: 20px;

  color: #ffffff;
}
.lightboxright iframe {
  min-height: 390px;
}
.divtext {
  margin: 36px;
}
@media (max-width: 800px) {
  .lightboxleft {
    width: 100%;
  }
  .lightboxright {
    width: 100%;
  }
  .divtext {
    margin: 12px;
  }
}

#a40AccessibilityStatement {
  margin: 10px 40px;
}

#a40AccessibilityStatement p {
  text-align: left;
}

#a40AccessibilityStatement h2 {
  margin: 10px 10px;
}

/* Loading Loanpal spinner */
.loading-smiley-container {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: visible;

  width: 2em;
  height: 2em;
  margin: auto;
}

.loading-smiley-container:before {
  position: fixed;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  content: '';

  background-color: rgba(0, 0, 0, 0.1);
}

.loading-smiley {
  z-index: 10;

  height: 60px;

  animation: load 1.3s ease infinite;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-30deg);
  }
  70% {
    transform: rotate(390deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.naked-link {
  text-decoration: none;
}

.centered {
  display: flex;

  justify-content: center;
  align-items: center;
}

/* New nav */

:root {
  --black: #1a1818;
  --blue: #0a47ba;
  --blue-green: #158996;
  --blue-two: #0047ba;
  --light-peach: #d9d5d5;
  --macaroni-and-cheese: #f7b334;
  --medium-grey: #717271;
  --pumpkin-orange: #ff8300;
  --pumpkin-orange-two: #fd8300;
  --teal-blue: #008996;
  --tealish: #26cad3;
  --white: #ffffff;
  --yellowish-orange: #fa9918;
}

.navigation {
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;

  max-width: 100%;
}
.navigation__container {
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;

  max-width: 100%;
  padding: 8px 25px;
}
@media only screen and (min-width: 48em) {
  .navigation__container {
    padding: 1.5625vw 3.6458vw;
  }
}
@media only screen and (min-width: 64em) {
  .navigation__container {
    padding: 1.5625vw 7.2917vw;
  }
}
.navigation__container--wol {
  background-color: #555555;
}
@media only screen and (min-width: 64em) {
  .navigation__container--direct-pay {
    color: #555555;
    background-color: #f8f8f8;
  }
}
@media only screen and (min-width: 64em) {
  .navigation__container--direct-pay .GroupedMenu__Link,
  .navigation__container--direct-pay .GroupedMenu__Name,
  .navigation__container--direct-pay .navigation__link {
    color: #555555;
  }
}
.navigation__logo {
  line-height: 0;

  display: flex;

  justify-content: center;
  align-items: center;

  width: 98px;
  height: auto;
}
@media only screen and (min-width: 48em) {
  .navigation__logo {
    width: 14.7396vw;
    min-width: 14.7396vw;
  }
}
.navigation__logo a {
  display: flex;

  justify-content: center;
  align-items: center;

  min-height: 48px;
}
.navigation__logo img,
.navigation__logo svg {
  max-width: 100%;
}
.navigation__menu {
  display: none;
}
@media only screen and (min-width: 64em) {
  .navigation__menu {
    display: flex;

    justify-content: center;
    align-items: center;

    margin: 0;

    list-style: none;

    white-space: nowrap;
  }
}
.navigation__menu--principal {
  justify-content: flex-start;
  flex: 1 1;

  margin-left: 2.5vw;
}
.navigation__menu--principal .GroupedMenu__Link,
.navigation__menu--principal .GroupedMenu__Name,
.navigation__menu--principal .navigation__link {
  display: block;

  padding-right: 1.5625vw;
  padding-left: 1.5625vw;
}
.navigation__menu--secondary {
  justify-content: flex-end;
  flex: 1 1;
}
.navigation__menu--secondary .GroupedMenu__Link,
.navigation__menu--secondary .GroupedMenu__Name,
.navigation__menu--secondary .navigation__link {
  display: block;

  padding-right: 1.5625vw;
  padding-left: 1.5625vw;
}
.GroupedMenu__Link,
.GroupedMenu__Name,
.navigation__link {
  font-family: brandon-grotesque, sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;

  display: flex;
  overflow: hidden;

  justify-content: center;
  align-items: center;

  padding-top: 1.6667vw;
  padding-bottom: 1.6667vw;

  cursor: pointer;
  text-align: center;
  text-decoration: none;
  letter-spacing: normal;
  text-transform: uppercase;

  color: #ffffff;
}
.navigation__cta {
  font-family: Nunito, sans-serif;
  font-size: 1.0417vw;
  font-weight: 900;
  line-height: 1.35;

  display: block;

  height: auto;
  margin-left: 1.25vw;
  padding: 16px 1.7708vw;

  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  color: var(--pumpkin-orange);
  border-radius: 32px;
  background-color: #ffffff;
}
.navigation__hamburger {
  position: relative;

  display: flex;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  width: 50px;
  height: 28px;
  margin-top: 10px;

  cursor: pointer;

  border: none;
  border-radius: 37px;
  outline: none;
  background-color: #ffffff;
}
@media only screen and (min-width: 64em) {
  .navigation__hamburger {
    display: none;
  }
}
.navigation__hamburger span {
  position: absolute;
  left: 13px;

  display: block;

  width: 25px;
  min-height: 3px;

  border-radius: 24px;
  background-color: #fb9412;
}
.navigation__hamburger--home span {
  background-color: #26cad3;
}
.navigation__hamburger--direct-pay span {
  background-color: #717271;
}
.navigation__hamburger span:first-child {
  top: 7px;
}
.navigation__hamburger span:nth-child(2) {
  top: 13px;
}
.navigation__hamburger span:nth-child(3) {
  top: 19px;
}
.navigation-mobile {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  box-sizing: border-box;
}
.navigation-mobile--closed {
  overflow: hidden;

  max-height: 0;

  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;

  opacity: 0.5;
}
.navigation-mobile--closed .navigation-mobile__overlay,
.navigation-mobile--closed .navigation-mobile__wrapper {
  transition: opacity 0s ease-in-out;

  opacity: 0;
}
.navigation-mobile--open .navigation-mobile__overlay,
.navigation-mobile--open .navigation-mobile__wrapper {
  opacity: 1;
}
.navigation-mobile__overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  transition: opacity 0.3s ease-in-out 0.3s;

  background-color: rgba(0, 0, 0, 0.16);

  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.navigation-mobile__wrapper {
  padding: 25px;

  transition: opacity 0.3s ease-in-out;
}
.navigation-mobile__container,
.navigation-mobile__wrapper {
  display: flex;

  align-content: stretch;

  box-sizing: border-box;
  height: 100%;
}
.navigation-mobile__container {
  position: relative;
  z-index: 5;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 32px;

  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.16);
}
.navigation-mobile__close {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;

  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  border: none;
  outline: none;
  background-color: transparent;
}
.navigation-mobile__menu {
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 32px 0 0;

  list-style: none;
}
@media (orientation: landscape) {
  .navigation-mobile__menu {
    padding: 0 0 32px;
  }
}
.navigation-mobile__link {
  font-family: brandon-grotesque, sans-serif;
  font-size: 24px;
  font-weight: 500;

  margin: 0;
  padding: 16px 0;

  text-align: left;
  text-decoration: none;
  letter-spacing: -0.56px;
  text-transform: uppercase;

  color: var(--macaroni-and-cheese);
}
.navigation-mobile__link--cta {
  font-family: Nunito, sans-serif;
  font-size: 15px;
  font-weight: 900;
  line-height: 1.33;

  display: flex;

  justify-content: center;
  align-items: center;

  width: 128px;
  height: 40px;
  margin: 0 auto;

  text-align: center;

  color: #ffffff;
  border-radius: 36px;
  background-color: var(--tealish);
}
.GroupedMenu {
  position: relative;
}
.GroupedMenu:hover .GroupedMenu__Options {
  pointer-events: all;

  opacity: 1;
}
.GroupedMenu__Options {
  position: absolute;
  top: 100%;
  left: 50%;

  margin: 0;
  padding: 0;

  list-style: none;

  transition: all 0.2s linear;
  transform: translateX(-50%);

  opacity: 0;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.4);

  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
.navigation__container--wol .GroupedMenu__Options {
  background: rgba(0, 0, 0, 0.9);
}
.GroupedMenu__Link {
  padding: 16px;
}
.GroupedMenu__Link:hover {
  text-decoration: underline;
}
.navigation-mobile__menu_bottom,
.navigation-mobile__menu_top {
  display: flex;

  flex-direction: column;
  flex: 1 1;
}
.navigation-mobile__menu_top {
  justify-content: flex-start;
}
.navigation-mobile__menu_top .navigation-mobile__link {
  color: #fd8300;
}
.navigation-mobile__menu_bottom {
  justify-content: flex-end;
}
.navigation-mobile__menu_bottom .navigation-mobile__link {
  color: #f7b334;
}

#viewsRoot *:nth-child(1) {
  margin-top: 0 !important;
}
