@media (max-width: 320px) {
  /* .green-energy-main-div {
        font-size: 120%;
    } */
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    background-size: cover;
    height: 110px;
    margin-top:65px;
  }
  .img-container-solar-power-superheroes {
    margin-top: 65px;
  }
  .img-container-green-energy-slideshow-wp {
    height: 240px;
  }
  .img-container-green-energy-slideshow {
    height: 170px;
  }
  .diy-slideshow {
    display: none;
  }
  .section-green-energy-banner .banner-partners-video {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main {
    background-image: url(../images/green-energy/slideshow/play-video-button-mobile.png),
      url(../images/green-energy/slideshow/min/partnersVideoFrameMobileMain-min.png);
  }
  .section-green-energy-banner .banner-snails {
    background-image: url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
  }

  .section-green-energy-banner .banner-working-capital {
    background-image: url(../images/banners/mobile/learn-more-button.png), url(../images/banners/mobile/loanpal_workingcapital_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-giraffe {
    background-image: url(../images/banners/mobile/lets-go-button-2.png), url(../images/banners/mobile/loanpal_raisetheroof_720x360-min.jpg);
  }

  .section-green-energy-banner .banner-chameleon {
    background-image: url(../images/banners/mobile/lets-go-button.png), url(../images/banners/mobile/loanpal_blendrightin_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-button-display {
    display: none;
    margin: 20px 0;
  }
  .bss-slides {
    display: none !important;
  }
  .diy-slideshow .btn {
    display: none !important;
  }
  #diy-slideshow-f2 {
    font-size: 85%;
  }
  .section-green-energy-how-it-works .step-description {
    text-align: center;
    line-height: 33px;
  }

  .section-green-energy-how-it-works .container {
    font-size: 140%;
    grid-template-columns: 0.05fr 1fr 0.05fr;
    grid-gap: 0px;
    grid-template-areas:
      '. a .'
      '. b .'
      '. c .';
  }
  .section-green-energy-how-it-works .a,
  .section-green-energy-how-it-works .b,
  .section-green-energy-how-it-works .c {
    border-right: none;
    padding-right: 0;
  }
  .section-green-energy .card {
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b';
  }
  .section-green-energy .bios ul {
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .section-proven-leadership .leadership-description {
    width: 100%;
  }
  .section-green-energy .bios {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 0px;
    grid-auto-rows: auto;
    grid-template-areas: 'bioa biob' 'bioc biog' 'biod bioe' 'biof bioh';
    text-align: left;
    line-height: 25px;
    font-size: 100%;
  }
  .section-green-energy .bios img {
    width: 95%;
  }
  .section-green-energy .bios ul {
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .section-proven-leadership .leadership-description {
    width: 100%;
  }
}
@media (max-width: 480px) and (min-width: 321px) {
  /* .green-energy-main-div {
        font-size: 120% !important;
    } */
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    background-size: cover;
    height: 110px;
    margin-top:65px;
  }
  .img-container-solar-power-superheroes {
    margin-top: 65px;
  }
  .img-container-green-energy-slideshow-wp {
    height: 285px;
  }
  .img-container-green-energy-slideshow {
    height: 195px;
  }
  .section-green-energy-banner .banner-partners-video {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video:hover {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main {
    background-image: url(../images/green-energy/slideshow/play-video-button-mobile.png),
      url(../images/green-energy/slideshow/min/partnersVideoFrameMobileMain-min.png);
  }
  .section-green-energy-banner .banner-partners-video-main:hover {
    background-image: url(../images/green-energy/slideshow/play-video-button-mobile.png),
      url(../images/green-energy/slideshow/min/partnersVideoFrameMobileMain-min.png);
  }

  .section-green-energy-banner .banner-snails {
    background-image: url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
    background-position: right 57% bottom 100%;
  }
  .section-green-energy-banner .banner-snails:hover {
    background-image: url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
    background-position: right 57% bottom 100%;
  }
  .section-green-energy-banner .banner-working-capital {
    background-image: url(../images/banners/mobile/learn-more-button.png), url(../images/banners/mobile/loanpal_workingcapital_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-working-capital:hover {
    background-image: url(../images/banners/mobile/learn-more-button.png), url(../images/banners/mobile/loanpal_workingcapital_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-giraffe {
    background-image: url(../images/banners/mobile/lets-go-button-2.png), url(../images/banners/mobile/loanpal_raisetheroof_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-giraffe:hover {
    background-image: url(../images/banners/mobile/lets-go-button-2.png), url(../images/banners/mobile/loanpal_raisetheroof_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-chameleon {
    background-image: url(../images/banners/mobile/lets-go-button.png), url(../images/banners/mobile/loanpal_blendrightin_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-chameleon:hover {
    background-image: none;
  }
  .section-green-energy-banner .banner-button-display {
    display: none;
    margin: 20px 0;
  }
  .diy-slideshow {
    display: none;
  }
  .bss-slides {
    display: none !important;
  }
  .diy-slideshow .btn {
    display: none !important;
  }
  #diy-slideshow-f2 {
    font-size: 85%;
  }
  .section-green-energy-how-it-works .container {
    font-size: 140%;
    grid-template-columns: 0.05fr 1fr 0.05fr;
    grid-gap: 0px;
    grid-template-areas:
      '. a .'
      '. b .'
      '. c .';
  }
  .section-green-energy-how-it-works .a,
  .section-green-energy-how-it-works .b,
  .section-green-energy-how-it-works .c {
    border-right: none;
    padding-right: 0;
  }
  .section-green-energy .card-title {
    font-size: 150%;
  }
  .section-green-energy .card-text-description {
    min-height: 145px;
    width: 100%;
    margin: 0 auto;
    font-size: 130%;
  }
  .section-green-energy .card {
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b';
  }
  .section-green-energy .bios {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 0px;
    grid-auto-rows: auto;
    grid-template-areas: 'bioa biob' 'bioc biog' 'biod bioe' 'biof bioh';
    text-align: left;
    line-height: 25px;
    font-size: 100%;
  }
  .section-green-energy .bios img {
    width: 95%;
  }
  .section-green-energy .bios ul {
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .section-proven-leadership .leadership-description {
    width: 100%;
  }
}
@media (max-width: 720px) and (min-width: 481px) {
  /* .green-energy-main-div {
        font-size: 120%;
    }    */
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    background-size: cover;
    height: 150px;
  }
  .img-container-solar-power-superheroes {
    margin-top: 65px;
  }
  .img-container-green-energy-slideshow {
    height: 320px;
  }
  .img-container-green-energy-slideshow-wp {
    height: 474px;
    max-width: 700px;
    margin: 0 auto;
  }
  .diy-slideshow {
    display: none;
  }
  .section-green-energy-how-it-works .step-description {
    text-align: center;
    line-height: 33px;
  }
  .section-green-energy-banner .banner-partners-video {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main {
    background-image: url(../images/green-energy/slideshow/play-video-button-mobile.png),
      url(../images/green-energy/slideshow/min/partnersVideoFrameMobileMain-min.png);
  }
  .section-green-energy-banner .banner-snails {
    background-image: url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
  }
  .section-green-energy-banner .banner-working-capital {
    background-image: url(../images/banners/mobile/learn-more-button.png), url(../images/banners/mobile/loanpal_workingcapital_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-giraffe {
    background-image: url(../images/banners/mobile/lets-go-button-2.png), url(../images/banners/mobile/loanpal_raisetheroof_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-chameleon {
    background-image: url(../images/banners/mobile/lets-go-button.png), url(../images/banners/mobile/loanpal_blendrightin_720x360-min.jpg);
  }

  .section-green-energy-banner .banner-partners-video:hover {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main:hover {
    background-image: url(../images/green-energy/slideshow/play-video-button-mobile.png),
      url(../images/green-energy/slideshow/min/partnersVideoFrameMobileMain-min.png);
  }
  .section-green-energy-banner .banner-snails:hover {
    background-image: url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
  }
  .section-green-energy-banner .banner-working-capital:hover {
    background-image: url(../images/banners/mobile/learn-more-button.png), url(../images/banners/mobile/loanpal_workingcapital_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-giraffe:hover {
    background-image: url(../images/banners/mobile/lets-go-button-2.png), url(../images/banners/mobile/loanpal_raisetheroof_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-chameleon:hover {
    background-image: url(../images/banners/mobile/lets-go-button.png), url(../images/banners/mobile/loanpal_blendrightin_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-button-display {
    display: none;
    margin: 20px 0;
  }
  .bss-slides {
    display: none !important;
  }
  .diy-slideshow .btn {
    display: none !important;
  }
  #diy-slideshow-f2 {
    font-size: 85%;
  }
  .section-green-energy-how-it-works .container {
    grid-template-columns: 0.1fr 1fr 0.1fr;
    grid-gap: 50px;
    grid-template-areas:
      '. a .'
      '. b .'
      '. c .';
  }
  .section-green-energy-how-it-works .a,
  .section-green-energy-how-it-works .b,
  .section-green-energy-how-it-works .c {
    border-right: none;
    padding-right: 0;
  }
  .section-green-energy .card {
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b';
  }
  .section-green-energy .bios {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 0px;
    grid-auto-rows: auto;
    grid-template-areas: 'bioa biob' 'bioc biog' 'biod bioe' 'biof bioh';
    text-align: left;
    line-height: 25px;
    font-size: 100%;
  }
  .section-green-energy .bios img {
    width: 95%;
  }
  .section-green-energy .bios ul {
    width: 50%;
    margin: 0 auto;
    text-align: left;
  }
}
@media (max-width: 960px) and (min-width: 721px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    background-size: cover;
    height: 200px;
  }
  .img-container-solar-power-superheroes {
    margin-top: 65px;
  }
  figure .img-container-green-energy-slideshow {
    height: 270px;
  }
  .img-container-green-energy-slideshow {
    height: 390px;
  }
  .img-container-green-energy-slideshow-wp {
    height: 470px;
  }
  .section-green-energy-banner .banner-partners-video {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main {
    background-image: url(../images/green-energy/slideshow/play-video-button-mobile.png),
      url(../images/green-energy/slideshow/min/partnersVideoFrameMobileMain-min.png);
  }
  .section-green-energy-banner .super-girl {
    background-image: url(../images/banners/mobile/learnMoreButton.png), url(../images/banners/mobile/loanpal_superhero_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-snails {
    background-image: url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
  }
  .section-green-energy-banner .banner-working-capital {
    background-image: url(../images/banners/mobile/learn-more-button.png), url(../images/banners/mobile/loanpal_workingcapital_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-giraffe {
    background-image: url(../images/banners/mobile/lets-go-button-2.png), url(../images/banners/mobile/loanpal_raisetheroof_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-chameleon {
    background-image: url(../images/banners/mobile/lets-go-button.png), url(../images/banners/mobile/loanpal_blendrightin_720x360-min.jpg);
  }

  .section-green-energy-banner .banner-partners-video:hover {
    background-image: url(../images/banners/mobile/loanpal-solar-partners-mobile-min.jpg);
  }
  .section-green-energy-banner .banner-partners-video-main:hover {
    background-image: url(../images/green-energy/slideshow/play-video-button-mobile.png),
      url(../images/green-energy/slideshow/min/partnersVideoFrameMobileMain-min.png);
  }
  .section-green-energy-banner .super-girl:hover {
    background-image: url(../images/banners/mobile/learnMoreButton.png), url(../images/banners/mobile/loanpal_superhero_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-snails:hover {
    background-image: url(../images/green-energy/slideshow/min/snailApprovals-min.jpg);
  }
  .section-green-energy-banner .banner-working-capital:hover {
    background-image: url(../images/banners/mobile/learn-more-button.png), url(../images/banners/mobile/loanpal_workingcapital_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-giraffe:hover {
    background-image: url(../images/banners/mobile/lets-go-button-2.png), url(../images/banners/mobile/loanpal_raisetheroof_720x360-min.jpg);
  }
  .section-green-energy-banner .banner-chameleon:hover {
    background-image: url(../images/banners/mobile/lets-go-button.png), url(../images/banners/mobile/loanpal_blendrightin_720x360-min.jpg);
  }
  .section-green-energy-how-it-works .icon-pic img {
    max-width: 240px;
    font-size: 105%;
  }
  #diy-slideshow-f2 {
    font-size: 85%;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 12%;
    width: 39%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 82%;
  }
  .section-green-energy-how-it-works .container {
    grid-template-columns: 0.1fr 1fr 0.1fr;
    grid-gap: 50px;
    grid-template-areas:
      '. a .'
      '. b .'
      '. c .';
  }
  .section-green-energy-how-it-works .a,
  .section-green-energy-how-it-works .b,
  .section-green-energy-how-it-works .c {
    border-right: none;
  }
  .section-green-energy .card {
    grid-template-columns: 0.25fr 1fr 0.25fr;
    grid-template-areas:
      '. a .'
      '. b .';
  }
  .section-green-energy .bios {
    display: grid;
    grid-template-columns: 0.25fr 1fr 0.25fr;
    grid-gap: 20px 0px;
    grid-auto-rows: auto;
    grid-template-areas: '. bioa .' '. biob .' '. bioc .' '. biog .' '. biod .' '. bioe .' '. biof .' '. bioh .';
    text-align: center;
  }
  .section-green-energy .bios img {
    width: 50%;
  }
  .section-green-energy .bios ul {
    width: 50%;
    margin: 0 auto;
    text-align: left;
  }
}
@media (max-width: 1130px) and (min-width: 961px) {
  .tablets-phones {
    width: 75%;
    margin-top: -10px;
  }
  .section-3fs .title-phone {
    margin-top: -100px;
  }
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    height: 240px;
  }
  .img-container-solar-power-superheroes {
    margin-top: 65px;
  }
  .img-container-green-energy-slideshow {
    height: 280px;
  }
  .img-container-green-energy-slideshow-wp {
    height: 550px;
  }
  #diy-slideshow-f2 {
    font-size: 85%;
  }
  .diy-slideshow h1 {
    font-size: 160%;
    margin-top: 20px;
    line-height: 14px;
  }
  .diy-slideshow h4 {
    line-height: 28px;
  }
  .diy-slideshow .get-started {
    padding-top: 5px;
  }
  .diy-slideshow ul {
    line-height: 20px;
    margin: 25px 0 14px 0;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 4%;
    width: 47%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 82%;
  }
  .section-green-energy-how-it-works .container {
    grid-template-columns: 0.1fr 1fr 0.1fr;
    grid-gap: 20px;
    grid-template-areas:
      '. a .'
      '. b .'
      '. c .';
  }
  .section-green-energy-how-it-works .a,
  .section-green-energy-how-it-works .b,
  .section-green-energy-how-it-works .c {
    border-right: none;
  }
  .section-green-energy .card {
    grid-template-columns: 0.25fr 1fr 0.25fr;
    grid-template-areas:
      '. a .'
      '. b .';
  }
  .section-green-energy .bios {
    grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
    grid-template-areas:
      '. bioa biob .'
      '. bioc biog .'
      '. biod bioe .'
      '. biof bioh .';
    text-align: center;
  }
  .section-green-energy .bios img {
    width: 65%;
  }
  .section-green-energy .bios ul {
    width: 65%;
    margin: 0 auto;
    text-align: left;
  }
  .section-green-energy .bio-text-title {
    font-size: 90%;
  }
  .section-green-energy .bio-text-list {
    font-size: 72%;
  }
}
@media (max-width: 1280px) and (min-width: 1131px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    height: 290px;
    margin-top: 65px;
  }
  .img-container-solar-power-superheroes {
    margin-top: 65px;
  }
  .img-container-green-energy-slideshow {
    height: 300px;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 85%;
  }
  .diy-slideshow h1 {
    font-size: 160%;
    margin-top: 20px;
    line-height: 14px;
  }
  .diy-slideshow h4 {
    line-height: 28px;
  }
  .diy-slideshow .get-started {
    padding-top: 5px;
  }
  .diy-slideshow ul {
    line-height: 20px;
    margin: 25px 0 14px 0;
  }
  .img-container-green-energy-slideshow-wp {
    height: 680px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 8%;
    width: 44%;
    height: 75%;
  }
  .section-green-energy-how-it-works .container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    grid-template-areas:
      '. a .'
      '. b .'
      '. c .';
  }
  .section-green-energy-how-it-works .a,
  .section-green-energy-how-it-works .b,
  .section-green-energy-how-it-works .c {
    border-right: none;
  }
  .section-green-energy .card {
    grid-template-columns: 0.25fr 1fr 0.25fr;
    grid-template-areas:
      '. a .'
      '. b .';
  }
  .section-green-energy .bios {
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 0.25fr;
    grid-template-areas:
      '. bioa biob bioc biog . '
      '. biod bioe biof bioh .';
    text-align: center;
  }
  .section-green-energy .bios img {
    width: 65%;
  }
  .section-green-energy .bios ul {
    width: 65%;
    margin: 0 auto;
  }
  .section-green-energy .bio-text-title {
    font-size: 90%;
  }
  .section-green-energy .bio-text-list {
    font-size: 90%;
  }
}
@media (max-width: 1440px) and (min-width: 1281px) {
  .tablets-phones {
    width: 70%;
    margin-top: -15px;
  }
  .section-3fs .title-3 {
    font-size: 300%;
  }
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    height: 333px;
  }
  .img-container-green-energy-slideshow {
    height: 333px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 3%;
    width: 48%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 90%;
  }
  .diy-slideshow ul {
    line-height: 23px;
  }
  .section-green-energy .bios {
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 0.25fr;
    grid-template-areas:
      '. bioa biob bioc biog . '
      '. biod bioe biof bioh .';
    text-align: center;
  }
  .section-green-energy .bios img {
    width: 65%;
  }
  .section-green-energy .bios ul {
    width: 65%;
    margin: 0 auto;
    text-align: left;
  }
  .section-green-energy-how-it-works .container {
    grid-gap: 60px;
  }
}
@media (max-width: 1500px) and (min-width: 1441px) {
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    height: 345px;
  }
  .img-container-lynn-sunrun {
    height: 315px;
  }
  .img-container-green-energy-slideshow {
    height: 315px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 9%;
    width: 42%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 95%;
  }
}
@media (max-width: 1660px) and (min-width: 1501px) {
  .img-container-lynn-sunrun {
    height: 345px;
  }
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    height: 365px;
  }
  .img-container-green-energy-slideshow {
    height: 365px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 13%;
    width: 38%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 100%;
  }
}
@media (max-width: 1860px) and (min-width: 1661px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us {
    height: 345px;
  }
  .img-container-green-energy-slideshow {
    height: 345px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 17%;
    width: 34%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 115%;
  }
}
@media (max-width: 1920px) and (min-width: 1861px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 390px;
  }
  .img-container-green-energy-slideshow {
    height: 390px;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    height: 680px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 17%;
    width: 34%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 115%;
  }
}
@media (max-width: 1950px) and (min-width: 1921px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 390px;
  }
  .img-container-green-energy-slideshow {
    height: 390px;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    height: 580px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 17%;
    width: 34%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 115%;
  }
}
@media (max-width: 2000px) and (min-width: 1951px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 405px;
  }
  .img-container-green-energy-slideshow {
    height: 405px;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    height: 580px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 21%;
    width: 30%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 115%;
  }
}
@media (max-width: 2100px) and (min-width: 2001px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 405px;
  }
  .img-container-green-energy-slideshow {
    height: 405px;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    height: 580px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 21%;
    width: 30%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 115%;
  }
}
@media (max-width: 2200px) and (min-width: 2101px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 420px;
  }
  .img-container-green-energy-slideshow {
    height: 420px;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    height: 580px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 21%;
    width: 30%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 115%;
  }
}

@media (max-width: 2300px) and (min-width: 2201px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 420px;
  }
  .img-container-green-energy-slideshow {
    height: 420px;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    max-height: 680px !important;
    height: 680px !important;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 24%;
    width: 27%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 115%;
  }
}

@media (max-width: 2400px) and (min-width: 2301px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 420px;
  }
  .img-container-green-energy-slideshow {
    height: 420px;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    background-size: contain !important;
    max-height: 680px !important;
    height: 680px !important;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 24%;
    width: 27%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 115%;
  }
}

@media (max-width: 2560px) and (min-width: 2401px) {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 470px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 24%;
    width: 27%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    background-size: contain !important;
    max-height: 680px !important;
    height: 680px !important;
  }
  .img-container-green-energy-slideshow {
    height: 470px;
  }
  .diy-slideshow {
    font-size: 130%;
  }
  .diy-slideshow ul {
    line-height: 35px;
  }
}

@media (min-width: 2561px), print {
  .img-container-lynn-sunrun,
  .img-container-hero-green-energy,
  .img-container-why-partners-love-us,
  .img-container-vivint-quote {
    height: 500px;
  }
  .section-green-energy-banner .world-positive{
    background-size: contain !important;
  }
  .img-container-green-energy-slideshow-wp {
    height: 680px;
  }
  .img-container-green-energy-slideshow-wp {
    background-size: contain !important;
    max-height: 680px !important;
    height: 680px !important;
  }
  .img-container-green-energy-slideshow {
    height: 500px;
  }
  .img-container-green-energy-slideshow .button-box {
    position: absolute;
    top: 11%;
    left: 24%;
    width: 27%;
    height: 75%;
  }
  .diy-slideshow .btn {
    top: 85%;
  }
  .diy-slideshow {
    font-size: 130%;
  }
  .diy-slideshow ul {
    line-height: 35px;
  }
}
