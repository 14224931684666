.bss-slides{
    position: relative;
    display: block; 
    line-height: 0;/*removes the gap if you put a border on the slideshow div*/   
  }
  figcaption {
    line-height: 1.5; /* restores line-height to the child element*/
  }
  .bss-slides:focus{
   outline: 0;
  }
  .bss-slides figure{
    position: absolute;
    top: 0;
    width: 100%;
  }
  .bss-slides figure:first-child{
    position: relative;
  }

  .bss-slides figure .slide{
    opacity: 0;
    -webkit-transition: opacity 1.2s;
    transition: opacity 1.2s;
    transition: all 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;    
  }
  .bss-slides .bss-show{
    z-index: 2;
  }
  .bss-slides .bss-show .slide{
    opacity: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative; 
  }
  
  .bss-slides figcaption{
    position: absolute;
    font-family: sans-serif;
    font-size: .8em;
    bottom: .75em;
    right: .35em;
    padding: .25em;
    color: #fff;
    background: #000;
    background: rgba(0,0,0, .25);
    border-radius: 2px;
    opacity: 0;
    -webkit-transition: opacity 1.2s;
    transition: opacity 1.2s;
  }
  .bss-slides .bss-show figcaption{
    z-index: 3;
    opacity: 1;
  }
  .bss-slides figcaption a{
    color: #fff;    
  }
  .bss-next, .bss-prev{
    color: #fff;
    position: absolute;
    background: #000;
    background: rgba(0,0,0, .6);
    top: 50%;
    z-index: 4;
    font-size: 2em;
    margin-top: -1.2em;
    opacity: .3;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .bss-next:hover, .bss-prev:hover{
    cursor: pointer;
    opacity: 1;
  }
  .bss-next{
    right: -1px;
    padding: 10px 5px 15px 10px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .bss-prev{
    left: 0;
    padding: 10px 10px 15px 5px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .bss-fullscreen{
    display: none;
    width: 32px;
    height: 32px;    
    background: rgba(0,0,0,.4) url(../img/arrows-alt_ffffff_64.png); 
    -webkit-background-size: contain; 
    background-size: contain;
    position: absolute;
    top: 5px;
    left: 5px;   
    cursor: pointer;    
    opacity: .3;
  } 
  .bss-fullscreen:hover{
    opacity: .8;   
  }
  :-webkit-full-screen .bss-fullscreen{
      background: rgba(0,0,0,.4) url(../img/compress_ffffff_64.png);
      -webkit-background-size: contain;
      background-size: contain;
  }
  :-moz-full-screen .bss-fullscreen{
      background: rgba(0,0,0,.4) url(../img/compress_ffffff_64.png);
      background-size: contain;
  }
  :-ms-fullscreen .bss-fullscreen{
      background: rgba(0,0,0,.4) url(../img/compress_ffffff_64.png);
      background-size: contain;
  }
  :full-screen .bss-fullscreen{
      background: rgba(0,0,0,.4) url(../img/compress_ffffff_64.png);
      -webkit-background-size: contain;
      background-size: contain;
  }
  :-webkit-full-screen .bss-fullscreen{
      background: rgba(0,0,0,.4) url(../img/compress_ffffff_64.png);
      -webkit-background-size: contain;
      background-size: contain;
  }
  :-moz-full-screen .bss-fullscreen{
      background: rgba(0,0,0,.4) url(../img/compress_ffffff_64.png);
      background-size: contain;
  }
  :-ms-fullscreen .bss-fullscreen{
      background: rgba(0,0,0,.4) url(../img/compress_ffffff_64.png);
      background-size: contain;
  }
  :fullscreen .bss-fullscreen{
      background: rgba(0,0,0,.4) url(../img/compress_ffffff_64.png);
      -webkit-background-size: contain;
      background-size: contain;
  }