@media (max-width: 320px) {
  .section-4-squares .outer-box {
    margin-left: 10%;
  }
  .orange-banner div {
    font-size: 13px;
  }
  .banner-desktop {
    display: none !important;
  }
  .banner-mobile {
    display: block !important;
  }
  .slideshow-home {
    margin-top: 0 !important;
  }
}
@media (max-width: 480px) and (min-width: 321px) {
  .section-4-squares .outer-box {
    margin-left: 10%;
  }
  .orange-banner div {
    font-size: 13px;
  }
  .banner-desktop {
    display: none !important;
  }
  .banner-mobile {
    display: block !important;
  }
  .slideshow-home {
    margin-top: 0 !important;
  }
}

@media (max-width: 720px) and (min-width: 481px) {
  .orange-banner div {
    margin-left: 40px;
  }
  .banner-desktop {
    display: none !important;
  }
  .banner-mobile {
    display: block !important;
  }
  .slideshow-home {
    margin-top: 0 !important;
  }
}

@media (max-width: 960px) and (min-width: 721px) {
  .section-4-squares .image-conscious img {
    width: 40%;
    margin-bottom: 0 !important;
  }
  .section-4-squares .image-conscious {
    margin-bottom: 20px;
  }
  .section-4-squares .description-conscious {
    font-size: 120%;
    line-height: 130%;

    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .section-4-squares .title-conscious {
    font-size: 130%;

    position: relative;
    right: 10%;

    margin-bottom: 5px;

    text-align: center;
  }
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
  .slideshow-home {
    margin-top: 0 !important;
  }
}
@media (max-width: 1130px) and (min-width: 961px) {
  .section-4-squares .image-conscious img {
    width: 70%;
  }
  .section-4-squares .description-conscious {
    font-size: 120%;
    line-height: 130%;
  }
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
  .slideshow-home {
    margin-top: 0 !important;
  }
}
@media (max-width: 1280px) and (min-width: 1131px) {
  .section-4-squares .image-conscious img {
    width: 80%;
  }
  .section-4-squares .description-conscious {
    font-size: 120%;
    line-height: 130%;
  }
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
  .slideshow-home {
    margin-top: 0 !important;
  }
}
@media (max-width: 1440px) and (min-width: 1281px) {
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
  .slideshow-home {
    margin-top: 0 !important;
  }
}
@media (max-width: 1500px) and (min-width: 1441px) {
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
}
@media (max-width: 1660px) and (min-width: 1501px) {
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
}
@media (max-width: 1860px) and (min-width: 1661px) {
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
}
@media (max-width: 1920px) and (min-width: 1861px) {
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
}
@media (min-width: 1921px), print {
  .banner-desktop {
    display: block !important;
  }
  .banner-mobile {
    display: none !important;
  }
}
